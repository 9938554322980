<template>
  <div>
    <div class="info-wrap">
      <div class="info-title">
        学生资料
        <a-icon type="phone" style="margin-left: 10px" @click="call" />

        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()" style="font-size: 16px; margin-left: auto">
            课节回放 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="item in studentCourseSchedules"
              @click="playbackByCourseSchedule(item.courseScheduleRespDTO.uuid)"
            >
              <a href="javascript:;">{{ item.courseScheduleRespDTO.scheduleName }}</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="info-subTitle">个人信息-基本信息</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">姓名：</div>
            <p class="info-txt">{{ studentDetail.fullName || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">英文名：</div>
            <p class="info-txt">{{ studentDetail.data.EnglishName || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">学号：</div>
            <p class="info-txt">{{ studentDetail.code || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">性别：</div>
            <p class="info-txt">
              {{ studentDetail.data.gender ? (studentDetail.data.gender === 'male' ? '男' : '女') : '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">所在时区：</div>
            <p class="info-txt">{{ studentDetail.timezone || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">生日：</div>
            <p class="info-txt">{{ studentDetail.dateOfBirth || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">常驻国家/地区：</div>
            <p class="info-txt">{{ studentDetail.country || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">城市：</div>
            <p class="info-txt">{{ studentDetail.data.city || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">当前年龄：</div>
            <p class="info-txt">{{ studentDetail.currentAge || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">学生币种：</div>
            <p class="info-txt">{{ studentDetail.currency || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">母语：</div>
            <p class="info-txt">{{ studentDetail.data.language || '-' }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-subTitle">个人信息-账号信息</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">微信OPENID：</div>
            <p class="info-txt">{{ studentDetail.data.openId || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">Classin上课账号：</div>
            <p class="info-txt">{{ studentDetail.data.class_in_account || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">StudentId：</div>
            <p class="info-txt">{{ studentDetail.uuid || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">ClassIn同步UUID：</div>
            <p class="info-txt">{{ studentDetail.classInId || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">学生状态-中文：</div>
            <p class="info-txt">{{ studentDetail.data.status || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">学生状态-数学：</div>
            <p class="info-txt">{{ studentDetail.data.mathStatus || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">学生状态-英文：</div>
            <p class="info-txt">{{ studentDetail.data.englishStatus || '-' }}</p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-subTitle">个人信息-学生可用时间</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">北京时区：</div>
            <p class="info-txt">(GMT+8:00)beijing</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">学生时区：</div>
            <p class="info-txt">{{ studentDetail.timezone || '-' }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-subTitle">个人信息-邮寄地址</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">收件人姓名：</div>
            <p class="info-txt">{{ studentDetail.data.recipientName || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">收件人电话：</div>
            <p class="info-txt">{{ studentDetail.data.recipientPhone || '-' }}</p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">发货地址：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.address">
              {{ studentDetail.data.address.street || '' }} {{ studentDetail.data.address.city || '' }}
              {{ studentDetail.data.address.state || '' }} {{ studentDetail.data.address.country || '' }}
              {{ studentDetail.data.address.postalCode || '' }}
            </p>
            <p class="info-txt" v-else>-</p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
    </div>
    <div class="info-wrap" v-for="(item, key) in cuDetail.cuTransferMap || []" :key="key">
      <div class="info-subTitle">
        个人信息-
        <span v-if="key === 'CHINESE'">中文课时信息</span>
        <span v-else-if="key === 'MATH'">数学课时信息</span>
        <span v-else-if="key === 'ENGLISH'">英文课时信息</span>
      </div>
      <div class="info-itemWrap">
        <p class="all-time">
          剩余总课时
          <span v-if="key === 'CHINESE'" style="padding-right: 5px">{{ cuDetail.chineseRemainingCu }}</span>
          <span v-else-if="key === 'MATH'" style="padding-right: 5px">{{ cuDetail.mathRemainingCu }}</span>
          <span v-else-if="key === 'ENGLISH'" style="padding-right: 5px">{{ cuDetail.englishRemainingCu }}</span>
          /
          <span v-if="key === 'CHINESE'" style="padding-left: 5px; color: #333; font-size: 16px">{{
            cuDetail.chineseCu
          }}</span>
          <span v-else-if="key === 'MATH'" style="padding-left: 5px; color: #333; font-size: 16px">{{
            cuDetail.mathCu
          }}</span>
          <span v-else-if="key === 'ENGLISH'" style="padding-left: 5px; color: #333; font-size: 16px">{{
            cuDetail.englishCu
          }}</span>
        </p>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">已扣课时数：</div>
            <p class="info-txt" v-if="key === 'CHINESE'">
              {{ cuDetail.chineseCu - cuDetail.chineseRemainingCu || '-' }}
            </p>
            <p class="info-txt" v-else-if="key === 'MATH'">{{ cuDetail.mathCu - cuDetail.mathRemainingCu || '-' }}</p>
            <p class="info-txt" v-else-if="key === 'ENGLISH'">
              {{ cuDetail.englishCu - cuDetail.englishRemainingCu || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">伴学奖赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_HOMEWORK_COMPLETION'] ? item['GIFT_HOMEWORK_COMPLETION'].remainingCu : '-' }} /
              {{ item['GIFT_HOMEWORK_COMPLETION'] ? item['GIFT_HOMEWORK_COMPLETION'].totalCu : '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">购买课时：</div>
            <p class="info-txt">
              {{ item['PURCHASED'] ? item['PURCHASED'].remainingCu : '-' }} /
              {{ item['PURCHASED'] ? item['PURCHASED'].totalCu : '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">推荐赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_RECOMMEND'] ? item['GIFT_RECOMMEND'].remainingCu : '-' }} /
              {{ item['GIFT_RECOMMEND'] ? item['GIFT_RECOMMEND'].totalCu : '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">手动变更课时：</div>
            <p class="info-txt">
              {{ item['GIFT_ADJUST'] ? item['GIFT_ADJUST'].remainingCu : '-' }} /
              {{ item['GIFT_ADJUST'] ? item['GIFT_ADJUST'].totalCu : '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">分销赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_DISTRIBUTION'] ? item['GIFT_DISTRIBUTION'].remainingCu : '-' }} /
              {{ item['GIFT_DISTRIBUTION'] ? item['GIFT_DISTRIBUTION'].totalCu : '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">购买赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_PURCHASED'] ? item['GIFT_PURCHASED'].remainingCu : '-' }} /
              {{ item['GIFT_PURCHASED'] ? item['GIFT_PURCHASED'].totalCu : '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">社交平台分享赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_SOCIAL_NETWORK_SHARE'] ? item['GIFT_SOCIAL_NETWORK_SHARE'].remainingCu : '-' }} /
              {{ item['GIFT_SOCIAL_NETWORK_SHARE'] ? item['GIFT_SOCIAL_NETWORK_SHARE'].totalCu : '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学分享赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_SIGNUP_SHARE'] ? item['GIFT_SIGNUP_SHARE'].remainingCu : '-' }} /
              {{ item['GIFT_SIGNUP_SHARE'] ? item['GIFT_SIGNUP_SHARE'].totalCu : '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">小班课专属赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_SMALL_LESSON'] ? item['GIFT_SMALL_LESSON'].remainingCu : '-' }} /
              {{ item['GIFT_SMALL_LESSON'] ? item['GIFT_SMALL_LESSON'].totalCu : '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">课时补偿/赠课：</div>
            <p class="info-txt">
              {{ item['GIFT_COMPENSATE'] ? item['GIFT_COMPENSATE'].remainingCu : '-' }} /
              {{ item['GIFT_COMPENSATE'] ? item['GIFT_COMPENSATE'].totalCu : '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">手动变更课时：</div>
            <p class="info-txt">
              {{ item['GIFT_ADJUST'] ? item['GIFT_ADJUST'].remainingCu : '-' }} /
              {{ item['GIFT_ADJUST'] ? item['GIFT_ADJUST'].totalCu : '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">
        个人信息-
        <span v-if="key === 'CHINESE'">中文分享赠课额度</span>
        <span v-else-if="key === 'MATH'">数学分享赠课额度</span>
        <span v-else-if="key === 'ENGLISH'">英文分享赠课额度</span>
      </div>
      <div class="info-itemWrap">
        <p class="all-time">
          剩余/总额度
          <span v-if="key === 'CHINESE'" style="padding-right: 5px">{{ cuDetail.chineseRemainingQuota }}</span>
          <span v-if="key === 'MATH'" style="padding-right: 5px">{{ cuDetail.mathRemainingQuota }}</span>
          <span v-if="key === 'ENGLISH'" style="padding-right: 5px">{{ cuDetail.englishRemainingQuota }}</span>

          /<span v-if="key === 'CHINESE'" style="padding: 0 5px; color: #333; font-size: 16px">{{
            cuDetail.chineseQuota
          }}</span>
          <span v-if="key === 'MATH'" style="padding: 0 5px; color: #333; font-size: 16px">{{
            cuDetail.mathQuota
          }}</span>
          <span v-if="key === 'ENGLISH'" style="padding: 0 5px; color: #333; font-size: 16px">{{
            cuDetail.englishQuota
          }}</span>

          <!-- <a-popover>
            <template slot="content">
              <div>
                <p>剩余额度 = 总额度 - 已获分享额度</p>
                <p>总额度 = 分享赠课额度（自动计算） + 分享赠课额度（调整）</p>
              </div>
            </template>
            <a-icon type="exclamation-circle" />
          </a-popover> -->
        </p>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">分享赠课额度：</div>
            <p class="info-txt">
              {{ item['GIFT_SOCIAL_NETWORK_SHARE'] ? item['GIFT_SOCIAL_NETWORK_SHARE'].remainingQuota : '-' }} /
              {{ item['GIFT_SOCIAL_NETWORK_SHARE'] ? item['GIFT_SOCIAL_NETWORK_SHARE'].quota : '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <!-- <div class="item-label">分享赠课额度（调整）：</div>
            <p class="info-txt">
              {{
                item.subject === 'CHINESE'
                  ? studentDetail.data.freeAmountAdjust || '-'
                  : studentDetail.data.mathFreeAmountAdjust || '-'
              }}
            </p>-->
          </div>
        </div>
      </div>
    </div>

    <div class="info-wrap">
      <div class="info-subTitle">服务信息-服务信息</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">中文班主任：</div>
            <p class="info-txt" v-if="studentDetail.classAdmin && studentDetail.classAdmin.CHINESE">
              {{ studentDetail.classAdmin.CHINESE.fullName || '' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">数学辅导老师：</div>
            <p class="info-txt" v-if="studentDetail.classAdmin && studentDetail.classAdmin.MATH">
              {{ studentDetail.classAdmin.MATH.fullName || '无' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">中文学习规划师：</div>
            <p
              class="info-txt"
              v-if="
                studentDetail.data && studentDetail.data.courseConsultant && studentDetail.data.courseConsultant.CHINESE
              "
            >
              {{
                studentDetail.data.courseConsultant.CHINESE.fullName ||
                studentDetail.data.courseConsultant.CHINESE.name ||
                '-'
              }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">数学学习规划师：</div>
            <p
              class="info-txt"
              v-if="
                studentDetail.data && studentDetail.data.courseConsultant && studentDetail.data.courseConsultant.MATH
              "
            >
              {{
                studentDetail.data.courseConsultant.MATH.fullName ||
                studentDetail.data.courseConsultant.MATH.name ||
                '-'
              }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">英文辅导老师：</div>
            <p class="info-txt" v-if="studentDetail.classAdmin && studentDetail.classAdmin.ENGLISH">
              {{ studentDetail.classAdmin.ENGLISH.fullName || '无' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">英文学习规划师：</div>
            <p
              class="info-txt"
              v-if="
                studentDetail.data && studentDetail.data.courseConsultant && studentDetail.data.courseConsultant.ENGLISH
              "
            >
              {{
                studentDetail.data.courseConsultant.ENGLISH.fullName ||
                studentDetail.data.courseConsultant.ENGLISH.name ||
                '-'
              }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">推荐人：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.recommend">
              {{ studentDetail.data.recommend.Name || '-' }}
            </p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-subTitle">服务信息-学生画像</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">学习风格：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile">{{ studentDetail.stuProfile.style || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">兴趣爱好课外班：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.hobby || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">其他Online课程：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.otherOnlineCourse || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">学生画像补充：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.hobbyDesc || '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-subTitle">服务信息-家长画像</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">学习负责人：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile">{{ studentDetail.stuProfile.parentsName || '-' }}</p>
          </div>
          <div class="info-subItem">
            <div class="item-label">职业状态：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.workStatus || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">互动活跃度：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.activity || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">用户影响力：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.influence || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">沟通方式偏好：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.preferCommunicateWay || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">公众号提醒：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.officialAccountReminder ? '已开启' : '未开启' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">沟通时间偏好(学生时间)：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.preferCommunicateTime || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">家长画像补充：</div>
            <p v-if="studentDetail.stuProfile">
              {{ studentDetail.stuProfile.parentsDescribe || '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-title">学情</div>
      <div class="info-subTitle">当前学情-中文课程进度</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">主修课班型：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.classTypeLabel || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">周消耗课时：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.weekCourseUnite || '-' }} 课时
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">主修课班级名称：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.className || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">上课时长：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.duration || '-' }} min
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">当前级别：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.sectionData.level1 || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">最近完成的主修课：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.scheduleName || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-中文教学期望</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">听说：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.listeningSpeaking || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">读：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.reading || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">写：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.writing || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">课程进度：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.courseProcess || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">作业难度：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.homeworkDifficulty || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">作业量：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.homeworkVolume || '-' }}
            </p>
          </div>
        </div>

        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">其他/特殊需求：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.specialNeeds || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">作业偏好：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{
                initOptionsType(
                  studentDetail.stuProfile.situationExpectData.CHINESE.homeworkPrefer,
                  homeworkPreferArr,
                ) || '-'
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-中文其他学情</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">授课语言：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.teachingLanguage || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">教学互动：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.interaction || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">作业完成度：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.homewordCompletion || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">行为习惯：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.behaviorHabit || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-中文排课信息</div>

      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学时间（北京时间）：</div>
            <p class="info-txt">
              {{
                (studentDetail.data.entranceDate &&
                  $moment(studentDetail.data.entranceDate).format('YYYY年MM月DD日 HH:mm')) ||
                '-'
              }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">首课时间（北京时间）：</div>
            <p class="info-txt">
              {{
                (studentDetail.data.firstLessonDate_CHINESE &&
                  $moment(studentDetail.data.firstLessonDate_CHINESE).format('YYYY年MM月DD日 HH:mm')) ||
                '-'
              }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">补升权益：</div>
            <p class="info-txt">
              {{ studentDetail.data.levelUpText_CHINESE || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">阅读课（北京时间）：</div>
            <p class="info-txt">
              {{ studentDetail.data.readingClass || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-数学课程进度</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">主修课班型：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.classTypeLabel || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">周消耗课时：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.weekCourseUnite || '-' }} 课时
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">主修课班级名称：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.className || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">上课时长：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.duration || '-' }} min
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">当前级别：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.sectionData.level1 || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">最近完成的主修课：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.scheduleName || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-数学教学期望</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">数与代数：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.algebra || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">几何与测量：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.geometry || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">推理与策略：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.strategy || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">应用与实战：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.practice || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">统计与概率：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.statistics || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">课程进度：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.courseProcess || '-' }}
            </p>
          </div>
        </div>

        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">作业难度：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.homeworkDifficulty || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">作业量：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.homeworkVolume || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">其他/特殊需求：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.specialNeeds || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">作业偏好：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{
                initOptionsType(studentDetail.stuProfile.situationExpectData.MATH.homeworkPrefer, homeworkPreferArr) ||
                '-'
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-数学其他学情</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">教学互动：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.CHINESE.interaction || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">作业完成度：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.homewordCompletion || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">行为习惯：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.MATH.behaviorHabit || '-' }}
            </p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-数学排课信息</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学时间（北京时间）：</div>
            <p class="info-txt">
              {{
                (studentDetail.data.mathEntranceDate &&
                  $moment(studentDetail.data.mathEntranceDate).format('YYYY年MM月DD日 HH:mm')) ||
                '-'
              }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">首课时间（北京时间）：</div>
            <p class="info-txt">
              {{
                (studentDetail.data.firstLessonDate_MATH &&
                  $moment(studentDetail.data.firstLessonDate_MATH).format('YYYY年MM月DD日 HH:mm')) ||
                '-'
              }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">补升权益：</div>
            <p class="info-txt">
              {{ studentDetail.data.levelUpText_MATH || '-' }}
            </p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-英文课程进度</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">主修课班型：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.classTypeLabel || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">周消耗课时：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.weekCourseUnite || '-' }} 课时
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">主修课班级名称：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.className || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">上课时长：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.duration || '-' }} min
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">当前级别：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.sectionData.level1 || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">最近完成的主修课：</div>
            <p class="info-txt" v-if="studentDetail.stuProfile && studentDetail.stuProfile.situationExpectData">
              {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.scheduleName || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">当前学情-英文排课信息</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学时间（北京时间）：</div>
            <p class="info-txt">
              {{
                (studentDetail.data.englishEntranceDate &&
                  $moment(studentDetail.data.englishEntranceDate).format('YYYY年MM月DD日 HH:mm')) ||
                '-'
              }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">首课时间（北京时间）：</div>
            <p class="info-txt">
              {{
                (studentDetail.data.firstLessonDate_ENGLISH &&
                  $moment(studentDetail.data.firstLessonDate_ENGLISH).format('YYYY年MM月DD日 HH:mm')) ||
                '-'
              }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">补升权益：</div>
            <p class="info-txt">
              {{ studentDetail.data.levelUpText_ENGLISH || '-' }}
            </p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
      <div class="info-subTitle">入学学情-中文体验课信息</div>
      <div class="info-itemWrap">
        <a-table
          :pagination="false"
          :columns="columns"
          :data-source="studentDetail.stuProfile.situationExpectData.CHINESE.trialSchedules"
          :scroll="{ y: 300 }"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="playback" slot-scope="href, record">
            <a @click="playback(href, record)">回放链接</a>
            <br />
          </template>
        </a-table>
      </div>
      <div class="info-subTitle">入学学情-中文学习计划</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">科目类型：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.subject || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">级别（Ⅰ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.level1 || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">级别（Ⅱ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.level2 || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">级别（Ⅲ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.level3 || '-' }}
            </p>
          </div>
        </div>

        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">班型：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.courseCategory || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">上课频率（每周）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.courseRate || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">上课时长：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.courseDuration || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">入学教学期望：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.CHINESE"
            >
              {{ studentDetail.data.eduPlanInfo.CHINESE.expectation || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">入学学情-中文入学学情</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学年龄：</div>
            <p class="info-txt" v-if="studentDetail.data">
              {{ studentDetail.data.entranceAge || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">拼音学习史：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.pinyinHistory || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">孩子中文水平：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.chineseLevel || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">家庭语言环境：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.familyLanguage || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">汉字书写预期：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.writingExpectatio || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">国内中文学习史：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.chinaStudyHistory || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">曾用教材：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.textbookUsed || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">海外中文学习史：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.overseasStudyHistory || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">汉字书写预期：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.writingExpectatio || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">曾用教材学到第几册：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.textbookProgress || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">中文程度-补充说明：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.writingExpectatio || '-' }}
            </p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>
      <div class="info-subTitle">入学学情-数学体验课信息</div>
      <div class="info-itemWrap">
        <a-table
          :pagination="false"
          :columns="columns"
          :data-source="studentDetail.stuProfile.situationExpectData.MATH.trialSchedules"
          :scroll="{ y: 300 }"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="playback" slot-scope="href, record">
            <a @click="playback(href, record)">回放链接</a>
            <br />
          </template>
        </a-table>
      </div>
      <div class="info-subTitle">入学学情-数学学习计划</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">科目类型：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.MATH"
            >
              {{ studentDetail.data.eduPlanInfo.MATH.subject || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">级别（Ⅰ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.MATH"
            >
              {{ studentDetail.data.eduPlanInfo.MATH.level1 || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">级别（Ⅱ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.MATH"
            >
              {{ studentDetail.data.eduPlanInfo.MATH.level2 || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">级别（Ⅲ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.MATH"
            >
              {{ studentDetail.data.eduPlanInfo.MATH.level3 || '-' }}
            </p>
          </div>
        </div>

        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">班型：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.MATH.courseCategory || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">上课频率（每周）：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.MATH.courseRate || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">上课时长：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.MATH.courseDuration || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">入学教学期望：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.MATH.expectation || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">入学学情-数学入学学情</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">测试级别：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.testLevel || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">分数：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.score || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学年龄：</div>
            <p class="info-txt" v-if="studentDetail.data">
              {{ studentDetail.data.mathEntranceAge || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">孩子数学水平：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{
                studentDetail.data.entryCriteria.mathLevel === 1
                  ? '优秀'
                  : studentDetail.data.entryCriteria.mathLevel === 2
                  ? '良好'
                  : '一般' || '-'
              }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">参与有数学辅导班：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.mathTutoringClass ? '有' : '无' || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">参与有其他兴趣班：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.otherinterestClass ? '有' : '无' || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学在读年级：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.grade || '-' }}
            </p>
          </div>
          <div class="info-subItem"></div>
        </div>
      </div>

      <div class="info-subTitle">入学学情-英文体验课信息</div>
      <div class="info-itemWrap">
        <a-table
          :pagination="false"
          :columns="columns"
          :data-source="studentDetail.stuProfile.situationExpectData.ENGLISH.trialSchedules"
          :scroll="{ y: 300 }"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="playback" slot-scope="href, record">
            <a @click="playback(href, record)">回放链接</a>
            <br />
          </template>
        </a-table>
      </div>
      <div class="info-subTitle">入学学情-英文学习计划</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">科目类型：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.ENGLISH"
            >
              {{ studentDetail.data.eduPlanInfo.ENGLISH.subject || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">级别（Ⅰ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.ENGLISH"
            >
              {{ studentDetail.data.eduPlanInfo.ENGLISH.level1 || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">级别（Ⅱ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.ENGLISH"
            >
              {{ studentDetail.data.eduPlanInfo.ENGLISH.level2 || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">级别（Ⅲ）：</div>
            <p
              class="info-txt"
              v-if="studentDetail.data && studentDetail.data.eduPlanInfo && studentDetail.data.eduPlanInfo.ENGLISH"
            >
              {{ studentDetail.data.eduPlanInfo.ENGLISH.level3 || '-' }}
            </p>
          </div>
        </div>

        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">班型：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.ENGLISH.courseCategory || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">上课频率（每周）：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.ENGLISH.courseRate || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">上课时长：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.ENGLISH.courseDuration || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">入学教学期望：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.eduPlanInfo">
              {{ studentDetail.data.eduPlanInfo.ENGLISH.expectation || '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-subTitle">入学学情-英文入学学情</div>
      <div class="info-itemWrap">
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">入学年龄：</div>
            <p class="info-txt" v-if="studentDetail.data">
              {{ studentDetail.data.englishEntranceAge || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">入学在读年级：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.grade || '-' }}
            </p>
          </div>
        </div>
        <div class="info-item">
          <div class="info-subItem">
            <div class="item-label">参与有英文辅导班：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.englishTutoringClass ? '有' : '无' || '-' }}
            </p>
          </div>
          <div class="info-subItem">
            <div class="item-label">参与有其他兴趣班：</div>
            <p class="info-txt" v-if="studentDetail.data && studentDetail.data.entryCriteria">
              {{ studentDetail.data.entryCriteria.englishOtherinterestClass ? '有' : '无' || '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-title">跟进记录</div>
      <div class="info-subTitle" v-if="studentInfo.classAdminFollowRecords.length > 0">班主任-跟进记录</div>

      <div v-if="studentInfo.classAdminFollowRecords.length > 0">
        <div class="info-itemWrap" v-for="(record, index) in studentInfo.classAdminFollowRecords" :key="index">
          <div class="record-topInfo">
            <span class="record-time">{{ record.whenCreated }}</span>
            <span>跟进处理人：{{ record.operatorName }} </span>
          </div>
          <div class="timeline-txt" v-if="record.recordData.taskType === 'QA'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">质检类型：{{ record.recordData.qaType }}</p>
              <p class="label-txt">质检来源：{{ record.recordData.qaOrigin }}</p>
              <p class="label-txt">质检判定：{{ record.recordData.qaDetermine }}</p>
              <p class="label-txt">奖惩分数：{{ record.recordData.rewardScore }}</p>
              <p class="label-txt">奖惩金额：{{ record.recordData.rewardMoney }}</p>
              <p class="label-txt">质检判定时间：{{ record.recordData.qaJudgeTime }}</p>
              <p class="label-txt">责任人：{{ record.recordData.personLiable }}</p>
              <p class="label-txt">质检人员：{{ record.recordData.qaPerson }}</p>
              <p class="label-txt">质检描述：{{ record.recordData.qualityDescription }}</p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【质检学科】：<span>{{ record.recordData.qualityAccount }}</span>
              </p>
              <p class="label-txt">
                【质检类型】：<span>{{ record.recordData.qualityType }}</span>
              </p>
              <p class="label-txt">
                【质检来源】：<span>{{ record.recordData.qualitySource }}</span>
              </p>
              <p class="label-txt">
                【质检判定】：<span>{{ record.recordData.qualityDetermine }}</span>
              </p>
              <p class="label-txt">
                【奖惩分数】：<span>{{ record.recordData.rewardScore }}</span>
              </p>

              <p class="label-txt">
                【奖惩金额】：<span>{{ record.recordData.rewardMoney }}</span>
              </p>
              <p class="label-txt">
                【质检判定时间】：<span>{{ record.recordData.qaJudgeTime }}</span>
              </p>

              <p class="label-txt">
                【责任人】：<span>{{ record.recordData.personLiable }}</span>
              </p>
              <p class="label-txt">
                【质检人员】：<span>{{ record.recordData.qaPerson }}</span>
              </p>
              <p class="label-txt">
                【质检描述】：<span>{{ record.recordData.qualityDescription }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>

              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'PERIOD_CHANGE'">
            <p class="label-txt">
              【转移类型】：<span>{{ record.recordData.transferType }}</span>
            </p>
            <p class="label-txt">
              【转移学员学号】：<span>{{ record.recordData.transferStudents }}</span>
            </p>
            <p class="label-txt">
              【接收学员学号】：<span>{{ record.recordData.receiveStudents }}</span>
            </p>
            <p class="label-txt">
              【折扣情况】：<span>{{ record.recordData.discountSituation }}</span>
            </p>
            <p class="label-txt">
              【课时转移记录】：<span>{{ record.recordData.classTransferRecord }}</span>
            </p>
            <p class="label-txt">
              【课时转移原因】：<span>{{ record.recordData.classTransferReason }}</span>
            </p>
            <p class="label-txt">
              【跟进描述】：<span>{{ record.recordData.describe }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              【图片】：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
            </p>
          </div>
          <!--学员跟进-->

          <div
            class="timeline-txt"
            v-else-if="
              record.recordData.taskType === 'TASK_NEXT_FOLLOW_RECORD' && record.recordData.version === 'version2'
            "
          >
            <p class="label-txt">备注：{{ record.recordData.describe }}</p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              图片：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              下次跟进时间：{{ record.recordData.nextFollowDate }}
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              下次跟进描述：{{ record.recordData.nextFollowDesc }}
            </p>
            <p class="label-txt" v-if="record.recordData.teachingFeedback">
              教学需求反馈：{{ record.recordData.teachingFeedback }}
            </p>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                请查看并核对花果学员档案、学情及跟进记录：{{ record.recordData.checkAcademicSituation }}
              </p>
              <p class="label-txt">
                请确认排课系统中的待上课节数是否充足：{{ record.recordData.confirmCourseScheduling }}
              </p>
              <p class="label-txt">
                与家长确认排课情况、确认上课时间及课频，班型及课时扣减规则、授课老师：{{
                  record.recordData.confirmClassTime
                }}
              </p>
              <p class="label-txt">与家长确认是否存在遗留问题：{{ record.recordData.confirmIssues }}</p>
              <p class="label-txt" v-if="record.recordData.confirmIssuesReason">
                遗留问题处理记录：{{ record.recordData.confirmIssuesReason }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【核对学情】：<span>{{ record.recordData.checkLearningSituation }}</span>
              </p>
              <p class="label-txt">
                【身份介绍】：<span>{{ record.recordData.identityIntroduction }}</span>
              </p>
              <p class="label-txt">
                【遗留问题】：<span>{{ record.recordData.remainingProblems }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div
            class="timeline-txt"
            v-else-if="record.recordData.taskType === 'TASK_STUDENT_ACTIVE' && record.recordData.version === 'version2'"
          >
            <p class="label-txt">请记录回暖沟通内容：{{ record.recordData.communicatingActive }}</p>
            <p class="label-txt">家长是否复课：{{ record.recordData.isResumeClasses }}</p>
            <p class="label-txt" v-if="record.recordData.isResumeClasses === '是'">
              复课时间：{{ record.recordData.resumeClassesTime }}
            </p>
            <p class="label-txt">备注：{{ record.recordData.describe }}</p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              图片：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              下次跟进时间：{{ record.recordData.nextFollowDate }}
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              下次跟进描述：{{ record.recordData.nextFollowDesc }}
            </p>
            <p class="label-txt" v-if="record.recordData.teachingFeedback">
              教学需求反馈：{{ record.recordData.teachingFeedback }}
            </p>
          </div>
          <div
            class="timeline-txt"
            v-else-if="
              record.recordData.taskType === 'TASK_MOTH_VISIT' || record.recordData.taskType === 'TASK_STUDENT_ACTIVE'
            "
          >
            <p class="label-txt">
              【家长回暖】：<span>{{ record.recordData.parentsRecovery }}</span>
            </p>
            <p class="label-txt">
              【沟通内容】：<span>{{ record.recordData.policySynchronization }}</span>
            </p>
            <p class="label-txt">
              【复课计划】：<span>{{ record.recordData.isResumeClasses }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.resumeClassesTime">
              【复课时间】：<span>{{ record.recordData.resumeClassesTime }}</span>
            </p>
            <p class="label-txt">
              【跟进描述】：<span>{{ record.recordData.describe }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              【图片】：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
            </p>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TEACHER_LATE'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">告知家长课时已自动返还：{{ record.recordData.reverseClassHours }}</p>

              <p class="label-txt" v-if="record.recordData.makeUpClasses">
                请引导家长安排补课：{{ record.recordData.makeUpClasses }}
              </p>
              <p class="label-txt" v-if="record.recordData.viewPlayback">
                提醒学员查看课程回放：{{ record.recordData.viewPlayback }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_MOTH_STUDY_VISIT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等：{{
                  record.recordData.communicatingMothStudy
                }}
              </p>
              <p class="label-txt">回访结果：{{ record.recordData.followUpResults }}</p>
              <p class="label-txt" v-if="record.recordData.processingRecords">
                家长异议处理记录：{{ record.recordData.processingRecords }}
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【跟进结果】：<span>{{ record.recordData.followResult }}</span>
              </p>
              <p class="label-txt">
                【学情反馈】：<span>{{ record.recordData.studyFeedBack }}</span>
              </p>

              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_W3_VISIT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                与家长沟通学员近期学习进步点，课堂表现、作业完成情况及待提升点：{{
                  record.recordData.communicatingStudent
                }}
              </p>
              <p class="label-txt">
                根据学员近期学习表现、薄弱点制定下一步学习规划及建议，铺垫学习重难点【{{
                  record.recordData.studentPerformance
                }}】
              </p>
              <p class="label-txt">补升方案介绍：结合学习情况铺垫方案性价比【{{ record.recordData.upgradePlan }}】</p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【学情分析】：<span>{{ record.recordData.situationAnalysis }}</span>
              </p>
              <p class="label-txt">
                【补升方案介绍】：<span>{{ record.recordData.promotionIntroduce }}</span>
              </p>

              <p class="label-txt">
                【学习规划制定】：<span>{{ record.recordData.studyFormulate }}</span>
              </p>
              <p class="label-txt">
                【补升意向】：<span>{{ record.recordData.promotionIntention }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>

              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_FIRST_VISIT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">授课老师：{{ record.recordData.instructor }}</p>
              <p class="label-txt" v-if="record.subject === 'CHINESE'">
                老师是否完成课前破冰：{{ record.recordData.icebreaker }}
              </p>
              <p class="label-txt" v-if="record.subject === 'CHINESE'">
                老师是否在课后与家长完成沟通：{{ record.recordData.classCommunication }}
              </p>
              <p class="label-txt" v-if="record.recordData.classCommunicationReason && record.subject === 'CHINESE'">
                老师未完成课后沟通的原因：{{ record.recordData.classCommunicationReason }}
              </p>
              <p class="label-txt">首课级别：{{ record.recordData.firstClassLevel }}</p>
              <p class="label-txt" v-if="record.recordData.feedback">
                与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等：{{ record.recordData.feedback }}
              </p>
              <p class="label-txt">了解家长对首课内容/学习进度的反馈：{{ record.recordData.understandingProgress }}</p>
              <p class="label-txt" v-if="record.recordData.understandingProgress">
                级别进度调整：{{ record.recordData.understandingProgress }}
              </p>
              <p class="label-txt">了解家长对首课老师的反馈：{{ record.recordData.understandingTeacher }}</p>
              <p class="label-txt" v-if="record.recordData.parentFeedback">
                其他反馈补充：{{ record.recordData.parentFeedback }}
              </p>
              <p class="label-txt">介绍如何复习、查作业和交作业：{{ record.recordData.reviewHomework }}</p>
              <p class="label-txt">介绍分享规则、操作方法和上传路径：{{ record.recordData.sharingRules }}</p>

              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【授课老师】：<span>{{ record.recordData.instructor }}</span>
              </p>
              <p class="label-txt">
                【看课反馈】：<span>{{ record.recordData.watchClass }}</span>
              </p>
              <p class="label-txt">
                【首课感受】：<span>{{ record.recordData.firstLessonFeel }}</span>
              </p>
              <p class="label-txt">
                【首课级别】：<span>{{ record.recordData.firstClassLevel }}</span>
              </p>
              <p class="label-txt">
                【级别进度】：<span>{{ record.recordData.levelProgress }}</span>
              </p>
              <p class="label-txt">
                【作业提醒】：<span>{{ record.recordData.jobReminder }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.sendConfirmation">
                【寄送确认】：<span>{{ record.recordData.sendConfirmation }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.teacherTask">
                【老师任务】：<span>{{ record.recordData.teacherTask }}</span>
              </p>
              <p class="label-txt">
                【分享推荐】：<span>{{ record.recordData.shareRecommendation }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div
            class="timeline-txt"
            v-else-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(record.recordData.taskType)"
          >
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">入学档案：{{ record.recordData.enrollmentProfile }}</p>
              <p class="label-txt">学员信息：中英文昵称、出生年月【{{ record.recordData.studentInfo }}】</p>
              <p class="label-txt" v-if="record.recordData.studentInfo">
                学员信息：中英文昵称、出生年月【{{ record.recordData.studentInfo }}】
              </p>
              <p class="label-txt" v-if="record.recordData.classIntention">
                上课意向：首课时间、上课频次及可排课时间、老师风格【{{ record.recordData.classIntention }}】
              </p>
              <p class="label-txt" v-if="record.recordData.sendInfo">
                邮寄信息：姓名、电话、地址邮编【{{ record.recordData.sendInfo }}】
              </p>
              <p class="label-txt" v-if="record.recordData.admissionRights">
                入学权益：课包、分享额度、课时扣减、课时有效期、退款手续费及规则【{{
                  record.recordData.admissionRights
                }}】
              </p>
              <p class="label-txt">
                与家长确认学习规划，做好预期管理，核对入学级别及班型、教学期望【{{ record.recordData.studyPlan }}】
              </p>
              <p class="label-txt">
                与家长确认上课账号（海外手机号和邮箱），引导家长登陆Web/App【{{ record.recordData.classAccount }}】
              </p>
              <p class="label-txt">
                与家长确认学习负责人、沟通时间方式偏好、介绍APP24小时服务群和课前提醒【{{
                  record.recordData.learningLeader
                }}】
              </p>
              <p class="label-txt">通知家长排课信息和请假规则【{{ record.recordData.notifyParents }}】</p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【身份职责】：<span>{{ record.recordData.identity }}</span>
              </p>
              <p class="label-txt">
                【学员信息】：<span>{{ record.recordData.studentInformation }}</span>
              </p>
              <p class="label-txt">
                【入学课包】：<span>{{ record.recordData.admissionPackage }}</span>
              </p>
              <p class="label-txt">
                【新生权益】：<span>{{ record.recordData.newborn }}</span>
              </p>
              <p class="label-txt">
                【入学学情】：<span>{{ record.recordData.admissionSituation }}</span>
              </p>
              <p class="label-txt">
                【服务信息】：<span>{{ record.recordData.serviceInformation }}</span>
              </p>
              <p class="label-txt">
                【教学期望】：<span>{{ record.recordData.mathHope }}</span>
              </p>
              <p class="label-txt">
                【学习计划】：<span>{{ record.recordData.studyPlan }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>

              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>

          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_CHANGE_TEACHER_VISIT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">授课老师：{{ record.recordData.instructor }}</p>
              <p class="label-txt" v-if="record.subject === 'CHINESE'">
                老师是否完成课前破冰：{{ record.recordData.icebreakerClass }}
              </p>
              <p class="label-txt" v-if="record.subject === 'CHINESE'">
                老师是否在课后与家长完成沟通：{{ record.recordData.classCommunicationVisit }}
              </p>
              <p
                class="label-txt"
                v-if="record.recordData.classCommunicationVisitReason && record.subject === 'CHINESE'"
              >
                老师未完成课后沟通的原因：{{ record.recordData.classCommunicationVisitReason }}
              </p>

              <p class="label-txt" v-if="record.recordData.feedbackVisit">
                与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等：{{ record.recordData.feedbackVisit }}
              </p>

              <p class="label-txt">了解家长对首课老师的反馈：{{ record.recordData.underTeacherVisit }}</p>
              <p class="label-txt" v-if="record.recordData.parentFeedbackVisit">
                请填写家长对授课老师不满意的地方或其他情况补充：{{ record.recordData.parentFeedbackVisit }}
              </p>

              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">【授课老师】：{{ record.recordData.instructor }}</p>
              <p class="label-txt">【看课反馈】：{{ record.recordData.watchClass }}</p>
              <p class="label-txt">【首课感受】：{{ record.recordData.firstLessonFeel }}</p>
              <p class="label-txt">【老师任务】：{{ record.recordData.teacherTask }}</p>
              <p class="label-txt">【交接备注】：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>

              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                【教学需求反馈】：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_LONG_TASK_OVER_VISIT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">授课老师：{{ record.recordData.instructor }}</p>

              <p class="label-txt" v-if="record.subject === 'MATH'">
                与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等：{{ record.recordData.feedbackOver }}
              </p>

              <p class="label-txt" v-if="record.subject === 'MATH'">
                了解家长对新老师的反馈：{{ record.recordData.underNewTeacher }}
              </p>
              <p class="label-txt" v-if="record.recordData.parentFeedbackOver && record.subject === 'MATH'">
                请填写家长对授课老师不满意的地方或其他情况补充：{{ record.recordData.parentFeedbackOver }}
              </p>

              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【看课反馈】：<span>{{ record.recordData.watchClass }}</span>
              </p>
              <p class="label-txt">
                【首课感受】：<span>{{ record.recordData.firstLessonFeel }}</span>
              </p>

              <p class="label-txt">
                【交接备注】：<span>{{ record.recordData.describe }}</span>
              </p>
            </div>
          </div>
          <!--结业证书-->

          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_STUDENT_ABSENT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">了解学员缺勤原因：{{ record.recordData.absenteeismReasons }}</p>
              <p class="label-txt" v-if="record.recordData.lessonArrangement">
                请引导家长安排补课：{{ record.recordData.lessonArrangement }}
              </p>
              <p class="label-txt" v-if="record.recordData.courseReplayAbsent">
                提醒学员查看课程回放：{{ record.recordData.courseReplayAbsent }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【旷课原因】：<span>{{ record.recordData.absenteeismReasons }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.lessonArrangement">
                【补课安排】：<span>{{ record.recordData.lessonArrangement }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>

              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'STAGE_LEARNING_REPORT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议：{{
                  record.recordData.communicatingReport
                }}
              </p>

              <p class="label-txt">铺垫下阶段的学习内容和重难点：{{ record.recordData.studyReport }}</p>
              <p class="label-txt">回访结果：{{ record.recordData.followupResultsReport }}</p>
              <p class="label-txt" v-if="record.recordData.processingRecordsReport">
                家长异议处理记录：{{ record.recordData.processingRecordsReport }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【家长有无意见反馈】：<span>{{ record.recordData.isParentFeedback }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.opinionFeedback">
                【家长意见反馈】：<span>{{ record.recordData.opinionFeedback }}</span>
              </p>
              <p class="label-txt">
                【阶段学情总结】：<span>{{ record.recordData.stageSummary }}</span>
              </p>
              <p class="label-txt">
                【互动作业提醒】：<span>{{ record.recordData.operationReminder }}</span>
              </p>
              <p class="label-txt">
                【是否发送报告】：<span>{{ record.recordData.isSendReport }}</span>
              </p>
              <!-- <p class="label-txt">
              【学习建议】：<span>{{ record.recordData.learningSugReport }}</span>
            </p> -->
              <p class="label-txt">
                【课程预告】：<span>{{ record.recordData.upcomingClassesReport }}</span>
              </p>
              <p class="label-txt">
                【分享推荐】：<span>{{ record.recordData.shareRecommendationsReport }}</span>
              </p>
              <p class="label-txt">
                【是否邮寄教辅资料】：<span>{{ record.recordData.isMailingInfo }}</span>
              </p>
              <!-- <p class="label-txt">
              【学习规划】：<span>{{ record.recordData.learningPlanningReport }}</span>
            </p> -->
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <!--结业证书-->
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_CERTIFICATE'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                请与家长沟通学员课堂参与度、内容掌握情况、薄弱项及学习建议：{{
                  record.recordData.communicateCertificate
                }}
              </p>
              <p class="label-txt">回访结果：{{ record.recordData.followResultsCertificate }}</p>
              <p class="label-txt" v-if="record.recordData.recordsCertificate">
                家长异议处理记录：{{ record.recordData.recordsCertificate }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                请完成“学生主页服务页面”中的学生画像补充/修改：{{ record.recordData.studentPortrait }}
              </p>
              <p class="label-txt">
                请完成“学生主页服务页面”中的家长画像补充/修改：{{ record.recordData.parentPortrait }}
              </p>
              <p class="label-txt">
                请完成“学生主页学情页面”中当前学情补充/修改：{{ record.recordData.academicSituation }}
              </p>
              <p class="label-txt">分享参与度：{{ record.recordData.sharingEngagement }}</p>
              <p class="label-txt" v-if="record.recordData.sharingEngagementReason">
                请补充说明不参与分享的原因：{{ record.recordData.sharingEngagementReason }}
              </p>
              <p class="label-txt">是否有待解决异议点/问题：{{ record.recordData.solveProblem }}</p>
              <p class="label-txt" v-if="record.recordData.solveProblemReason">
                请说明待解决异议点或遗留问题：{{ record.recordData.solveProblemReason }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【学情交接】：<span>{{ record.recordData.learningSituation }}</span>
              </p>
              <p class="label-txt">
                【课堂参与度】：<span>{{ record.recordData.classroomParticipation }}</span>
              </p>
              <p class="label-txt">
                【出勤情况】：<span>{{ record.recordData.attendance }}</span>
              </p>
              <p class="label-txt">
                【缺席/迟到原因】：<span>{{ record.recordData.attendanceReason }}</span>
              </p>
              <p class="label-txt">
                【课后作业】：<span>{{ record.recordData.homeworkAfterClass }}</span>
              </p>
              <p class="label-txt">
                【分享参与】：<span>{{ record.recordData.shareParticipation }}</span>
              </p>
              <p class="label-txt">
                【不积极原因】：<span>{{ record.recordData.shareReason }}</span>
              </p>
              <p class="label-txt">
                【教学期望】：<span>{{ record.recordData.teachingService }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_TEACHER_ABSENT'">
            <p class="label-txt">
              【老师旷课原因】：<span>{{ record.recordData.absenteeism }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.absenteeismReason">
              【其他原因】：<span>{{ record.recordData.absenteeismReason }}</span>
            </p>
            <p class="label-txt">
              【补课安排】：<span>{{ record.recordData.makeUpLessons }}</span>
            </p>
            <p class="label-txt">
              【跟进描述】：<span>{{ record.recordData.describe }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              【图片】：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
            </p>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_NOT_RENEW'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">是否抽检：{{ record.recordData.isExamination }}</p>

              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt" v-if="record.recordData.isRenewalReason">
                【不续费原因】：<span>{{ record.recordData.isRenewalReason }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.isRenewalReasonSub">
                【不续费次要原因】：<span>{{ record.recordData.isRenewalReasonSub }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.cancelCourse">
                【是否取消所有课程】：<span>{{ record.recordData.cancelCourse }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.lossDirection">
                【流失去向】：<span>{{ record.recordData.lossDirection }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.classAdminRecords">
                【班主任沟通概述】：<span>{{ record.recordData.classAdminRecords }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.isExamination">
                【是否抽查】：<span>{{ record.recordData.isExamination }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.isResponsible">
                【班主任是否有责】：<span>{{ record.recordData.isResponsible }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.responsibleReason">
                【班主任有责原因】：<span>{{ record.recordData.responsibleReason }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.threeResponsible">
                【三级原因是否有误】：<span>{{ record.recordData.threeResponsible }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.inspectionRemarks">
                【抽查备注】：<span>{{ record.recordData.inspectionRemarks }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_PROMOTE'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">补升情况：{{ record.recordData.isMakeUp }}</p>
              <p class="label-txt" v-if="record.recordData.oneMakeUpReason">
                未补升一级原因：{{ record.recordData.oneMakeUpReason }}
              </p>
              <p class="label-txt" v-if="record.recordData.twoMakeUpReason">
                未补升二级原因：{{ record.recordData.twoMakeUpReason }}
              </p>
              <p class="label-txt" v-if="record.recordData.threeMakeUpReason">
                未补升三级原因：{{ record.recordData.threeMakeUpReason }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【是否补升】：<span>{{ record.recordData.isMakeUp }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.noMakeUpReason">
                【未补升原因I】：<span>{{ record.recordData.noMakeUpReason }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.noMakeUpReason2">
                【未补升原因II】：<span>{{ record.recordData.noMakeUpReason2 }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.noMakeUpReason3">
                【未补升原因III】：<span>{{ record.recordData.noMakeUpReason3 }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.parentObjection">
                【家长异议】：<span>{{ record.recordData.parentObjection }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_TERM_VISIT'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议：{{
                  record.recordData.communicatingTerm
                }}
              </p>

              <p class="label-txt">铺垫下阶段的学习内容和重难点：{{ record.recordData.studyTerm }}</p>
              <p class="label-txt">回访结果：{{ record.recordData.followupResultsTerm }}</p>
              <p class="label-txt" v-if="record.recordData.processingRecordsTerm">
                家长异议处理记录：{{ record.recordData.processingRecordsTerm }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【家长有无意见反馈】：<span>{{ record.recordData.feedbackTerm }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.termFeedback">
                【家长意见反馈】：<span>{{ record.recordData.termFeedback }}</span>
              </p>
              <p class="label-txt">
                【阶段学情总结】：<span>{{ record.recordData.learningTermSummary }}</span>
              </p>
              <p class="label-txt">
                【互动作业提醒】：<span>{{ record.recordData.operationTermReminder }}</span>
              </p>
              <!-- <p class="label-txt">
              【学习建议】：<span>{{ record.recordData.learningTermSuggestions }}</span>
            </p> -->
              <p class="label-txt">
                【课程预告】：<span>{{ record.recordData.upcomingTermClasses }}</span>
              </p>
              <p class="label-txt">
                【分享推荐】：<span>{{ record.recordData.shareTermRecommendations }}</span>
              </p>
              <!-- <p class="label-txt">
              【学习规划】：<span>{{ record.recordData.learningTermPlanning }}</span>
            </p> -->
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div
            class="timeline-txt"
            v-else-if="['TASK_WEEK_VISIT', 'REVIEW_QUESTION_REMINDER'].includes(record.recordData.taskType)"
          >
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">
                与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等：{{
                  record.recordData.communicatingWeek
                }}
              </p>
              <p class="label-txt">回访结果：{{ record.recordData.followupResultsWeek }}</p>
              <p class="label-txt" v-if="record.recordData.processingRecordsWeek">
                家长异议处理记录：{{ record.recordData.processingRecordsWeek }}
              </p>

              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【跟进结果】：<span>{{ record.recordData.followResult }}</span>
              </p>
              <p class="label-txt">
                【学习建议】：<span>{{ record.recordData.weekSuggestions }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <!--流失学员首通-->
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt" v-if="record.recordData.oneLossReason">
                流失一级原因：{{ record.recordData.oneLossReason }}
              </p>
              <p class="label-txt" v-if="record.recordData.oneTwoReason">
                流失二级原因：{{ record.recordData.oneTwoReason }}
              </p>
              <p class="label-txt" v-if="record.recordData.oneThreeReason">
                流失三级原因：{{ record.recordData.oneThreeReason }}
              </p>
              <p class="label-txt">家长在意点：{{ record.recordData.parentsCare }}</p>
              <p class="label-txt">学员复课意向：{{ record.recordData.resumeClassesLoss }}</p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【流失原因】：<span>{{ record.recordData.loseReason }}</span>
              </p>
              <p class="label-txt">
                【家长在意点】：<span>{{ record.recordData.parentsCare }}</span>
              </p>
              <p class="label-txt">
                【学员复课意向】：<span>{{ record.recordData.resumeClasses }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <!--一家多娃任务-->
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_STUDENT_MULTI_CHILD'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">是否存在多娃：{{ record.recordData.existChild }}</p>
              <p class="label-txt" v-if="record.recordData.existChild === '未回复'">
                是否至少跟进3天：{{ record.recordData.followDay }}
              </p>
              <p class="label-txt" v-if="record.recordData.existChild === '是'">
                是否同意注册：{{ record.recordData.agreeRegister }}
              </p>
              <p
                class="label-txt"
                v-if="record.recordData.agreeRegister === '同意' && record.recordData.existChild === '是'"
              >
                同意注册科目：{{ record.recordData.registerSubject }}
              </p>
              <p
                class="label-txt"
                v-if="record.recordData.agreeRegister === '不同意' && record.recordData.existChild === '是'"
              >
                中文课不注册原因：{{ record.recordData.chineseReason }}
              </p>
              <p
                class="label-txt"
                v-if="record.recordData.agreeRegister === '不同意' && record.recordData.existChild === '是'"
              >
                数学课不注册原因：{{ record.recordData.mathReason }}
              </p>
              <p
                class="label-txt"
                v-if="record.recordData.agreeRegister === '不同意' && record.recordData.existChild === '是'"
              >
                英文课不注册原因：{{ record.recordData.englishReason }}
              </p>
              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【是否存在多娃】：<span>{{ record.recordData.isChild }}</span>
              </p>
              <p class="label-txt">
                【多娃拓科科目选择】：<span>{{ record.recordData.isChildSubject }}</span>
              </p>
              <p class="label-txt">
                【是否同意注册试听】：<span>{{ record.recordData.isAgreeAudition }}</span>
              </p>
              <p class="label-txt">
                【不注册试听原因】：<span>{{ record.recordData.isAgreeReason }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <!--拓科任务，线索创建-->
          <div
            class="timeline-txt"
            v-else-if="record.recordData.taskType === 'TASK_TRACK_CREATE' && record.recordData.version === 'version2'"
          >
            <p class="label-txt">是否同意注册试听：{{ record.recordData.isAudition }}</p>
            <p class="label-txt" v-if="record.recordData.isAudition === '未回复'">
              是否至少跟进3天：{{ record.recordData.followDayClue }}
            </p>
            <p class="label-txt" v-if="record.recordData.isAudition === '不同意'">
              不注册试听原因：{{ record.recordData.isAuditionReason }}
            </p>
            <p class="label-txt">备注：{{ record.recordData.describe }}</p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              图片：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              下次跟进时间：{{ record.recordData.nextFollowDate }}
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              下次跟进描述：{{ record.recordData.nextFollowDesc }}
            </p>
            <p class="label-txt" v-if="record.recordData.teachingFeedback">
              教学需求反馈：{{ record.recordData.teachingFeedback }}
            </p>
          </div>
          <!--约课任务-->
          <div
            class="timeline-txt"
            v-else-if="
              record.recordData.taskType === 'TASK_SUBSCRIBE_COURSE' && record.recordData.version === 'version2'
            "
          >
            <p class="label-txt">是否同意注册试听：{{ record.recordData.isAuditionCourse }}</p>
            <p class="label-txt" v-if="record.recordData.isAuditionCourse === '未回复'">
              是否至少跟进3天：{{ record.recordData.followDayClue }}
            </p>
            <p class="label-txt" v-if="record.recordData.isAuditionCourse === '同意'">
              是否约课成功：{{ record.recordData.isAppointmentClass }}
            </p>
            <p
              class="label-txt"
              v-if="record.recordData.isAuditionCourse === '同意' && record.recordData.isAppointmentClass === '未约'"
            >
              未约课原因：{{ record.recordData.isAppointmentReason }}
            </p>

            <p class="label-txt">备注：{{ record.recordData.describe }}</p>
            <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              图片：<img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDate">
              下次跟进时间：{{ record.recordData.nextFollowDate }}
            </p>
            <p class="label-txt" v-if="record.recordData.nextFollowDesc">
              下次跟进描述：{{ record.recordData.nextFollowDesc }}
            </p>
            <p class="label-txt" v-if="record.recordData.teachingFeedback">
              教学需求反馈：{{ record.recordData.teachingFeedback }}
            </p>
          </div>
          <!--时令变化-->
          <div class="timeline-txt" v-else-if="record.recordData.taskType === 'DAYLIGHT_TIME_CHANGED'">
            <div v-if="record.recordData.version === 'version2'">
              <p class="label-txt">通知更换时令后的上课时间并得到家长确认：{{ record.recordData.seasonalChanges }}</p>

              <p class="label-txt">备注：{{ record.recordData.describe }}</p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                图片：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                下次跟进时间：{{ record.recordData.nextFollowDate }}
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                下次跟进描述：{{ record.recordData.nextFollowDesc }}
              </p>
              <p class="label-txt" v-if="record.recordData.teachingFeedback">
                教学需求反馈：{{ record.recordData.teachingFeedback }}
              </p>
            </div>
            <div v-else>
              <p class="label-txt">
                【跟进结果】：<span>{{ record.recordData.followResult }}</span>
              </p>
              <p class="label-txt">
                【跟进描述】：<span>{{ record.recordData.describe }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
                【图片】：<img
                  style="width: 50px; height: 50px; margin-right: 5px"
                  preview="1"
                  v-for="(item, index) in record.recordData.imageList"
                  :src="item"
                  :key="index"
                />
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDate">
                【下次跟进时间】：<span>{{ record.recordData.nextFollowDate }}</span>
              </p>
              <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                【下次跟进描述】：<span>{{ record.recordData.nextFollowDesc }}</span>
              </p>
            </div>
          </div>
          <div class="timeline-txt" v-else>
            <div v-if="record.recordData.method === '邮件'">
              【邮件主题】：{{ record.recordData.describe
              }}<span
                style="cursor: pointer; padding-left: 20px; color: #04cb94"
                @click="lookDetailEmail(record, 'CLASS_ADMIN')"
                >查看详情</span
              >
            </div>
            <div v-else>{{ record.recordData.describe }}</div>
            <div style="margin-top: 5px" v-if="record.recordData.imageList && record.recordData.imageList.length > 0">
              <img
                style="width: 50px; height: 50px; margin-right: 5px"
                preview="1"
                v-for="(item, index) in record.recordData.imageList"
                :src="item"
                :key="index"
              />
            </div>
          </div>
          <div style="margin: 5px 0 0 0" v-if="record.recordData.zoomRecord && record.recordData.zoomRecord.url">
            <audio
              controls
              controlsList="nodownload"
              :src="`${baseUrl}/zoom/phone/record/play?url=${record.recordData.zoomRecord.url}`"
            />
          </div>
          <!-- <div class="info-recordItem">
                <div class="item-recordLabel">姓名：</div>
                <div class="info-recordTxt">李荷兰</div>
              </div> 
              <div class="info-recordItem">
                <div class="item-recordLabel">姓名：</div>
                <div class="info-recordTxt">李荷兰</div>
              </div>  -->
          <div class="info-recordFlag">
            <span v-if="record.recordData.taskType">
              {{ initOptionsType1(record.recordData.taskType, followTypeArr) }}</span
            >
            <span v-if="record.recordData.method">方式: {{ record.recordData.method }}</span>
            <!-- <span v-if="record.recordData.method">电话结果: {{ record.recordData.phoneResult }}</span> -->
            <span v-if="record.taskId">已完成任务</span>
          </div>
        </div>
      </div>
      <div class="info-subTitle" v-if="studentInfo.consultantFollowRecords.length > 0">学习规划师-跟进记录</div>
      <div v-if="studentInfo.consultantFollowRecords.length > 0">
        <div class="info-itemWrap" v-for="(record, index) in studentInfo.consultantFollowRecords" :key="index">
          <div class="record-topInfo">
            <span class="record-time">{{ record.whenCreated }}</span>
            <span>跟进处理人：{{ record.operatorName }} </span>
          </div>
          <div class="describeUrl" v-if="record.recordData.describeUrls && record.recordData.describeUrls.length > 0">
            <img v-for="(src, index) in record.recordData.describeUrls" :src="src" :key="index" preview="1" />

            <!-- <img :src="record.recordData.describeUrl" preview="1" preview-text="描述" /> -->
          </div>
          <div class="timeline-txt">
            【邮件主题】：{{ record.recordData.describe
            }}<span
              v-if="record.recordData.method === '邮件'"
              style="cursor: pointer; padding-left: 20px; color: #04cb94"
              @click="lookDetailEmail(record, 'CONSULTANT')"
              >查看详情</span
            >
          </div>

          <div style="margin: 5px 0 0 0" v-if="record.recordData.zoomRecord && record.recordData.zoomRecord.url">
            <audio
              controls
              controlsList="nodownload"
              :src="`${baseUrl}/zoom/phone/record/play?url=${record.recordData.zoomRecord.url}`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap" v-if="studentInfo.tasks.length > 0">
      <div class="info-title">待办任务</div>
      <div class="task-item" v-for="(item, index) in studentInfo.tasks" :key="index">
        <span class="task-name">{{ item.category }}</span>
        <span class="task-time"
          >截止时间：<span :class="item.expireFlag ? 'task-delay' : ''">{{ item.dueDate }}</span></span
        >
      </div>
    </div>
    <emailDetail
      v-if="showEmailDetail"
      @onCancel="cancelEmailDetail"
      :recordData="recordEmailData"
      :roleType="roleType"
    ></emailDetail>
  </div>
</template>
<script>
import {
  queryStudentInfoData,
  queryFormType,
  lookStudentDefaultMobile,
  getCourseScheduleList,
} from '@/api/headTeacher';
import emailDetail from '@/views/mainHome/emailDetail.vue';

export default {
  data() {
    return {
      followTypeArr: [],
      roleType: 'CLASS_ADMIN',
      recordEmailData: {},
      showEmailDetail: false,
      homeworkPreferArr: [
        { label: '互动作业', value: 'INTERACTION' },
        { label: '纸质作业(家长明确要求)', value: 'PAPER' },
        { label: '自定义作业', value: 'CLASSIN' },
        { label: '不布置作业(家长明确要求)', value: 'NONE' },
        { label: '未选择', value: 'EMPTY' },
      ],
      // studentInfo:{},
      // studentDetail:{},
      columns: [
        // {
        //   title: '序号',
        //   key: 'index',
        //   width: 60,
        //   fixed: 'left',
        //   dataIndex: 'num',
        //   customRender: (text, record, index) => `${(index+1)}`,
        // },
        {
          title: '体验课名称',
          dataIndex: 'scheduleName',
          key: 'scheduleName',
          width: 200,
          ellipsis: true,
        },
        {
          title: '体验课老师',
          dataIndex: 'teacherName',
          key: 'teacherName',
          width: 150,
        },

        {
          title: '体验课回放',
          dataIndex: 'accountStatus',
          scopedSlots: { customRender: 'playback' },
          width: 120,
        },
        {
          title: '课后点评',
          dataIndex: 'ratingComment',
          ellipsis: true,
        },
      ],
      studentCourseSchedules: [],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    studentInfo() {
      return this.data;
    },
    studentDetail() {
      return this.data.student ?? { data: {} };
    },
    cuDetail() {
      return this.data.student?.cuDetail ?? {};
    },
    courseSchedules() {
      return [
        // TODO: 课程表数据
        {
          name: 'a',
          id: 'asdf',
        },
      ];
    },
  },
  components: {
    emailDetail,
  },

  methods: {
    // 学生近三节课
    fetchStudentCourseSchedules() {
      getCourseScheduleList({
        orderBy: 'courseSchedule.endDateTime desc',
        timezone: this.studentDetail?.timezoneStd,
        studentId: this.studentDetail?.uuid,
        pageSize: 3,
        pageNum: 1,
        courseType: 'STANDARD',
      }).then((res) => {
        this.studentCourseSchedules = res.data.content;
      });
    },
    // 练习学生
    initMobile(data) {
      if (!data) return;
      let str = data.split(' ')[1].toString().replace(/ /g, '');
      const len = str.length;
      switch (true) {
        case len > 11:
          str = `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7, 4)}`;
          break;
        case len > 7:
          str = `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7)}`;
          break;
        case len > 3:
          str = `${str.substr(0, 3)} ${str.substr(3)}`;
          break;
        default:
      }
      this.zoomMoble = `zoomphonecall://${data.split(' ')[0]}${str}?cat=seccall`.replace(/\s+/g, '');
      window.location.href = this.zoomMoble;
    },
    call() {
      // 先查默认电话
      const params = {
        contactType: 'MOBILE',
        studentId: this.studentDetail.uuid,
      };
      lookStudentDefaultMobile(params).then((res) => {
        this.initMobile(res.data.content);
      });
    },

    cancelEmailDetail() {
      this.showEmailDetail = false;
    },
    initOptionsType1(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.type === value) {
          name = item.name;
        }
      });

      return name;
    },
    queryFormType() {
      queryFormType().then((res) => {
        this.followTypeArr = res.data.templateTypes;
      });
    },
    lookDetailEmail(record, type) {
      this.roleType = type;
      this.showEmailDetail = true;
      this.recordEmailData = record;
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    async playback(href, record) {
      this.playbackByCourseSchedule(record.courseScheduleId);
    },
    async playbackByCourseSchedule(courseScheduleId) {
      try {
        const { data } = await getReplayLink(courseScheduleId);
        const videoInfo = data?.[0];
        if (videoInfo?.type === 'HTML_URL') {
          window.open(videoInfo?.link);
        } else {
          window.open(`/lessonReplay?courseScheduleId=${courseScheduleId}`, '_blank');
        }
      } catch {
        window.open(`/lessonReplay?courseScheduleId=${courseScheduleId}`, '_blank');
      }
    },
    onClose() {
      this.$emit('closeModal');
    },
    queryStudentInfoData(data) {
      const params = {
        studentId: this.data.studentId,
        oldClassAdminId: this.data.oldClassAdminId,
      };
      queryStudentInfoData(params).then((res) => {
        this.studentInfo = res.data.content;
        this.studentDetail = this.studentInfo.student;
      });
    },
  },
  created() {
    // 获取跟进记录类型、
    this.queryFormType();
    this.fetchStudentCourseSchedules();
  },

  mounted() {
    // this.queryStudentInfoData()
  },
};
</script>
<style lang="less" scoped>
.student-name {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}
.info-wrap {
  margin-bottom: 12px;
}
.info-title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.02);
  padding: 10px 12px;
  border-radius: 2px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  &.info-title:first-child {
    margin-top: 0;
  }
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 10px 0 0;
    width: 3px;
    height: 19px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
.info-subTitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 16px;
  padding: 16px 0px;
  font-weight: 600;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  // &::before {
  //   content: '';
  //   display: block;
  //   float: left;
  //   margin: 10px 10px 0 0;
  //   width: 6px;
  //   height:6px;
  //   border-radius: 50%;
  //   background-color:#41CC83;
  // }
}
.info-item {
  display: flex;
}
.info-subItem {
  flex: 1;
  font-size: 14px;
  margin-bottom: 16px;
}
.item-label {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
.info-txt {
  color: rgba(0, 0, 0, 0.88);
  line-height: 22px;
}
.info-itemWrap {
  margin: 16px 0 0;
}
.info-recordItem {
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}
.item-recordLabel {
  width: 150px;
  color: rgba(0, 0, 0, 0.6);
}
.info-recordTxt {
  color: rgba(0, 0, 0, 0.88);
  flex: 1;
}
.record-topInfo {
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
}
.record-time {
  padding: 0 0 12px 0;
  display: inline-block;
  &::after {
    content: '';
    display: inline-block;
    float: right;
    margin: 4px 20px;
    width: 2px;
    height: 16px;
    background-color: #e0e0e0;
  }
}
.info-recordFlag {
  text-align: right;
  font-size: 12px;
  margin-top: 20px;
  span {
    padding: 0 0 12px 0;
    display: inline-block;
    color: rgba(250, 183, 0, 1);
    &::after {
      content: '';
      display: inline-block;
      float: right;
      margin: 3px 16px 0 16px;
      width: 1px;
      height: 12px;
      background: #e0e0e0;
    }
  }
  span:last-child {
    &::after {
      display: none;
    }
  }
}
.task-item {
  margin-top: 20px;
}

.task-name {
  padding: 0 16px 0 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16;
  font-weight: 600;
  &::after {
    content: '';
    display: inline-block;
    float: left;
    margin: 7px 10px 0 28px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #43d186;
  }
}
.task-time {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.task-delay {
  font-size: 14px;
  color: red;
  padding-left: 8px;
}
.info-recordTitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 16px;

  padding: 16px 12px 16px 23px;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.info-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .time-zone {
    margin-bottom: 20px;
  }
  .info-item {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    margin-bottom: 10px;
    span {
      display: inline-block;
      min-width: 120px;
      line-height: 22px;
      color: #999;
    }
    p {
      display: flex;
      justify-content: flex-start;
      color: #333;
      margin-bottom: 0;
      span {
        // display: inline-block;
        margin-right: 10px;
        width: auto;
      }
    }
  }
  .info-time {
    width: 100%;
    span {
      color: #333;
    }
  }
}

.all-time {
  margin-bottom: 10px;
}
.label-txt {
  margin-bottom: 5px;
  color:rgba(0, 0, 0, 0.6) span {
    color: rgba(0, 0, 0, 0.88);
  }
}
</style>
