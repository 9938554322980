<template>
  <div>
    <a-modal
      title="完成跟进"
      :maskClosable="false"
      :width="680"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @cancel="onClose(0)"
      wrapClassName="followModal"
      :footer="null"
    >
      <div style="margin-bottom: 12px; padding-left: 30px" v-if="type === 'task'">注意：提交该信息后任务同步完成！</div>
      <a-form :form="form">
        <div class="list-itemWrap" v-if="type === 'COMMON' && roleFLag" :class="{ requireStyle: errMap.subject }">
          <div class="list-item">
            <div class="list-label">跟进科目：</div>
            <div class="list-content">
              <a-radio-group v-model="subject" @change="(val) => changeErr(!val.target.value, 'subject')">
                <a-radio :value="item.value" :key="index" v-for="(item, index) in subjectArr">{{ item.label }}</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="list-itemWrap" :class="{ requireStyle: errMap.methodType }">
          <div class="list-item">
            <div class="list-label">跟进方式：</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="methodType"
                @change="(val) => changeErr(!val.length, 'methodType')"
                :options="methodTypeOptions"
              />
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="type === 'COMMON' && !roleFLag">
          <a-textarea
            v-decorator="[
              'description',
              {
                rules: [{ required: true, message: '请填写备注' }],
              },
            ]"
            :rows="4"
            placeholder="请输入"
            @blur="changeResult"
          />
        </a-form-item>

        <template v-if="taskType === 'SUBSCRIPTION_DEDUCTION_FAILED_TASK'">
          <div class="list-itemWrap" v-if="taskObj.nextCourse">
            <div class="list-item">
              <div class="list-label">最近一节待开课时间：</div>
              <div class="list-content">{{ taskObj.nextCourse.courseScheduleRespDTO.startChinaDateTime }}</div>
            </div>
          </div>
          <div class="list-itemWrap" :class="{ requireStyle: errMap.needCancelCourse }">
            <div class="list-item item-right">
              <div class="list-label">确认是否需取消对应的待开课课节</div>
              <div class="list-content">
                <a-radio-group
                  v-model="needCancelCourse"
                  @change="(val) => changeErr(!val.target.value, 'needCancelCourse')"
                >
                  <a-radio value="已取消"> 已取消 </a-radio>
                  <a-radio value="无需取消"> 无需取消 </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="list-itemWrap" :class="{ requireStyle: errMap.paymentCommunicationResult }">
            <div class="list-item item-right">
              <div class="list-label">与家长沟通扣款失败原因，引导更新支付方式确保扣款成功</div>
              <div class="list-content">
                <a-radio-group
                  v-model="paymentCommunicationResult"
                  @change="(val) => changeErr(!val.target.value, 'paymentCommunicationResult')"
                >
                  <a-radio value="沟通成功且已支付"> 沟通成功且已支付 </a-radio>
                  <a-radio value="沟通失败"> 沟通失败 </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div
            class="list-itemWrap"
            :class="{ requireStyle: errMap.paymentFailReason }"
            v-if="paymentCommunicationResult === '沟通失败'"
          >
            <div class="list-item item-right">
              <div class="list-label">失败原因</div>
              <div class="list-content">
                <a-radio-group
                  v-model="paymentFailReason"
                  @change="(val) => changeErr(!val.target.value, 'paymentFailReason')"
                >
                  <a-radio value="未和家长有效联系"> 未和家长有效联系 </a-radio>
                  <a-radio value="家长不再继续订阅"> 家长不再继续订阅 </a-radio>
                  <a-radio value="需暂停后期再续订"> 需暂停后期再续订 </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <a-form-item label="备注">
            <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
          </a-form-item>
        </template>

        <template v-if="taskType === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP'">
          <a-form-item label="系统未选课原因">
            <a-select
              allowClear
              placeholder="请选择"
              v-decorator="['nonAttendanceReason', { rules: [{ required: true, message: '请选择原因' }] }]"
              :options="nonAttendanceReasonOptions"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="选课结果">
            <a-select
              @change="handleResultChange"
              allowClear
              placeholder="请选择"
              v-decorator="['bookClassResult', { rules: [{ required: true, message: '请选择结果' }] }]"
              :options="bookClassResultOptions"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="最终未选课原因" v-if="bookClassResult === '失败'">
            <a-select
              allowClear
              placeholder="请选择"
              v-decorator="['nonAttendanceEndReason', { rules: [{ required: true, message: '请选择原因' }] }]"
              :options="nonAttendanceReasonOptions"
            >
            </a-select>
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
          </a-form-item>
        </template>

        <template v-if="taskType === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP'">
          <a-form-item label="系统未选课原因">
            <a-select
              allowClear
              placeholder="请选择"
              v-decorator="['nonAttendanceReason', { rules: [{ required: true, message: '请选择原因' }] }]"
              :options="nonAttendanceReasonOptions"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="选课结果">
            <a-select
              @change="handleResultChange"
              allowClear
              placeholder="请选择"
              v-decorator="['bookClassResult', { rules: [{ required: true, message: '请选择结果' }] }]"
              :options="bookClassResultOptions"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="最终未选课原因" v-if="bookClassResult === '失败'">
            <a-select
              allowClear
              placeholder="请选择"
              v-decorator="['nonAttendanceEndReason', { rules: [{ required: true, message: '请选择原因' }] }]"
              :options="nonAttendanceReasonOptions"
            >
            </a-select>
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
          </a-form-item>
        </template>
        <div class="list-itemWrap" v-if="type === 'COMMON' && roleFLag" :class="{ requireStyle: errMap.followTypeSub }">
          <div class="list-item">
            <div class="list-label">跟进类型：</div>
            <div class="list-content">
              <a-radio-group v-model="followTypeSub" @change="(val) => changeFollowType(val)">
                <a-radio value="COMMON"> 日常跟进 </a-radio>
                <a-radio value="QA"> 质检跟进</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>

        <div
          class="list-itemWrap"
          v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'"
          :class="{ requireStyle: errMap.qaType }"
        >
          <div class="list-item">
            <div class="list-label">质检类型：</div>
            <div class="list-content">
              <a-radio-group v-model="qaType" @change="(val) => changeErr(!val.target.value, 'qaType')">
                <a-radio value="投诉"> 投诉</a-radio>
                <a-radio value="流失"> 流失</a-radio>
                <a-radio value="质检"> 质检</a-radio>
                <a-radio value="优秀案例"> 优秀案例</a-radio>
                <a-radio value="其他"> 其他</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'"
          :class="{ requireStyle: errMap.qaOrigin }"
        >
          <div class="list-item">
            <div class="list-label">质检来源：</div>
            <div class="list-content">
              <a-radio-group v-model="qaOrigin" @change="(val) => changeErr(!val.target.value, 'qaOrigin')">
                <a-radio value="质检抽查"> 质检抽查</a-radio>
                <a-radio value="约课顾问"> 约课顾问</a-radio>
                <a-radio value="学习规划师"> 学习规划师</a-radio>
                <a-radio value="教学部">教学部</a-radio>
                <a-radio value="客服渠道"> 客服渠道</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'"
          :class="{ requireStyle: errMap.qaDetermine }"
        >
          <div class="list-item">
            <div class="list-label">质检判定：</div>
            <div class="list-content">
              <a-radio-group v-model="qaDetermine" @change="(val) => changeErr(!val.target.value, 'qaDetermine')">
                <a-radio value="奖励加分">奖励加分</a-radio>
                <a-radio value="有责扣罚">有责扣罚</a-radio>
                <a-radio value="无责不扣罚">无责不扣罚</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="奖惩分数" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-input
            style="width: 100%"
            @blur="changeResult"
            v-decorator="['rewardScore', { rules: [{ required: true, message: '请填写奖惩分数' }] }]"
            placeholder="提示：2或-2"
          />
          <!-- <a-select
            @blur="changeResult"
            v-decorator="['rewardScore', { rules: [{ required: true, message: '请选择奖惩分数' }] }]"
            placeholder="请选择"
          >
            <a-select-option :value="item" :key="index" v-for="(item, index) in rewardScoreArr">
              {{ item }}
            </a-select-option>
          </a-select> -->
        </a-form-item>
        <a-form-item label="奖惩金额" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-input-number
            style="width: 100%"
            @blur="changeResult"
            v-decorator="['rewardMoney', { rules: [{ required: true, message: '请选择奖惩金额' }] }]"
            placeholder="请填写-500~500的整数数字"
          />
        </a-form-item>
        <a-form-item label="质检判定时间" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-date-picker
            allowClear
            @blur="changeResult"
            v-decorator="['qaJudgeTime', { rules: [{ required: true, message: '请选择质检判定时间' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="责任人" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-select
            show-search
            allowClear
            @search="searchHeadmaster"
            placeholder="请选择"
            v-decorator="['personLiable', { rules: [{ required: true, message: '请选择责任人' }] }]"
          >
            <a-select-option v-for="item in headmasterList" :key="item.uuid" :value="item.fullName">{{
              item.fullName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="质检人员" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-select
            show-search
            allowClear
            @search="searchQaHeadmaster"
            v-decorator="['qaPerson', { rules: [{ required: true, message: '请选择质检人员' }] }]"
            placeholder="请选择"
          >
            <a-select-option v-for="item in qaHeadmasterList" :key="item.uuid" :value="item.fullName">{{
              item.fullName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="质检描述" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'qualityDescription',
              {
                rules: [{ required: true, message: '请填写质检描述' }],
              },
            ]"
            :rows="4"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="备注" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'COMMON'">
          <a-textarea
            v-decorator="[
              'description',
              {
                rules: [{ required: true, message: '请填写备注' }],
              },
            ]"
            :rows="4"
            placeholder="请输入"
            @blur="changeResult"
          />
        </a-form-item>
        <a-form-item label="备注" v-if="type === 'COMMON' && roleFLag && followTypeSub === 'QA'">
          <a-textarea
            v-decorator="[
              'description',
              {
                rules: [{ required: true, message: '请填写备注' }],
              },
            ]"
            :rows="4"
            placeholder="请输入审批编号、流程描述"
            @blur="changeResult"
          />
        </a-form-item>

        <!--老师迟到-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'TEACHER_LATE'"
          :class="{ requireStyle: errMap.reverseClassHours }"
        >
          <div class="list-item">
            <div class="list-label">告知家长课时已自动返还：</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="reverseClassHours"
                @change="(val) => changeErr(!val.length, 'reverseClassHours')"
              >
                <a-checkbox value="已同步"> 已同步 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TEACHER_LATE' && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.makeUpClasses }"
        >
          <div class="list-item">
            <div class="list-label">请引导家长安排补课：</div>
            <div class="list-content">
              <a-radio-group
                v-model="makeUpClasses"
                @blur="changeResult"
                @change="(val) => changeErr(!val.target.value, 'makeUpClasses')"
              >
                <a-radio value="已安排"> 已安排 </a-radio>
                <a-radio value="无需补课"> 无需补课</a-radio>
                <a-radio value="未回复">未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TEACHER_LATE' && (taskObj.subject === 'MATH' || taskObj.subject === 'ENGLISH')"
          :class="{ requireStyle: errMap.viewPlayback }"
        >
          <div class="list-item">
            <div class="list-label">提醒学员查看课程回放：</div>
            <div class="list-content">
              <a-checkbox-group v-model="viewPlayback" @change="(val) => changeErr(!val.length, 'viewPlayback')">
                <a-checkbox value="已提醒"> 已提醒</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TEACHER_LATE'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--新生首通-->
        <template v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(taskType)">
          <div
            class="list-itemWrap"
            :class="{ requireStyle: errMap.selfBookingConfirm }"
            v-if="taskObj.selfBookingInfo"
          >
            <div class="list-item item-right">
              <div class="list-label">通知家长入学级别、班型、开课时间、请假规则、课时扣减规则等信息</div>
              <div class="list-content">
                <a-radio-group
                  v-model="selfBookingConfirm"
                  @blur="changeResult"
                  @change="(val) => changeErr(!val.target.value, 'selfBookingConfirm')"
                >
                  <a-radio value="已确认"> 已通知，家长已确认 </a-radio>
                  <a-radio value=" 无需寄送"> 已通知，家长未回复 </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <a-form-item
            label="沟通结果"
            v-if="!(taskObj.selfBookingInfo && taskObj.selfBookingInfo.firstCourseSchedule)"
          >
            <a-select
              show-search
              placeholder="请选择"
              v-decorator="['communicateResult', { rules: [{ required: true, message: '请选择沟通结果' }] }]"
              @change="changeCommunicateResult"
            >
              <a-select-option value="SUCCESS">沟通成功且已排课</a-select-option>
              <a-select-option value="FAILD">沟通失败OR未排课</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="失败原因" v-if="form.getFieldValue('communicateResult') === 'FAILD'">
            <a-select
              show-search
              placeholder="请选择"
              @change="changeResult"
              v-decorator="['faildReason', { rules: [{ required: true, message: '请选择失败原因' }] }]"
            >
              <a-select-option value="未和家长有效联系">未和家长有效联系</a-select-option>
              <a-select-option value="家长改约沟通时间">家长改约沟通时间</a-select-option>
              <a-select-option value="家长未确认开课时间">家长未确认开课时间</a-select-option>
              <a-select-option value="首课日期在30天之后">首课日期在30天之后</a-select-option>
              <a-select-option value="无老师接单，排课失败">无老师接单，排课失败</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="下次跟进时间（系统生成新的首通任务）"
            v-if="form.getFieldValue('communicateResult') === 'FAILD'"
          >
            <a-date-picker
              style="width: 100%"
              :disabled-date="disabledDate"
              :disabled-time="disabledDateTime"
              v-decorator="['nextStuFirstFollowDate', { rules: [{ required: true, message: '请选择下次跟进时间' }] }]"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              @change="changeResult"
            />
          </a-form-item>

          <template v-if="form.getFieldValue('communicateResult') === 'SUCCESS'">
            <div class="list-itemWrap" :class="{ requireStyle: errMap.nicknameAndBirth }">
              <div class="list-item item-right">
                <div class="list-label">【学员信息】中英文昵称、出生年月、常驻城市、学习负责人</div>
                <div class="list-content">
                  <a-checkbox-group
                    v-model="nicknameAndBirth"
                    @change="(val) => changeErr(!val.length, 'nicknameAndBirth')"
                  >
                    <a-checkbox value="已确认"> 已确认 </a-checkbox>
                  </a-checkbox-group>
                </div>
              </div>
            </div>
            <div class="list-itemWrap" :class="{ requireStyle: errMap.courseInfo }">
              <div class="list-item item-right">
                <div class="list-label">【课时信息】课包、分享额度/规则、课时有效期</div>
                <div class="list-content">
                  <a-checkbox-group v-model="courseInfo" @change="(val) => changeErr(!val.length, 'courseInfo')">
                    <a-checkbox value="已确认"> 已确认 </a-checkbox>
                  </a-checkbox-group>
                </div>
              </div>
            </div>
            <div class="list-itemWrap" :class="{ requireStyle: errMap.classArrangement }">
              <div class="list-item item-right">
                <div class="list-label">【上课安排】核对入学级别，确认老师风格、上课频次及时间，课时扣减/请假规则</div>
                <div class="list-content">
                  <a-checkbox-group
                    v-model="classArrangement"
                    @change="(val) => changeErr(!val.length, 'classArrangement')"
                  >
                    <a-checkbox value="已确认"> 已确认 </a-checkbox>
                  </a-checkbox-group>
                </div>
              </div>
            </div>
            <div class="list-itemWrap" :class="{ requireStyle: errMap.appDownload }">
              <div class="list-item item-right">
                <div class="list-label">
                  与家长确认是否下载手机版WuKong APP，与家长确认孩子的上课设备及上课账号，并引导登陆（海外手机号或邮箱）
                </div>
                <div class="list-content">
                  <a-checkbox-group v-model="appDownload" @change="(val) => changeErr(!val.length, 'appDownload')">
                    <a-checkbox value="已确认"> 已确认 </a-checkbox>
                  </a-checkbox-group>
                </div>
              </div>
            </div>
            <div class="list-itemWrap" :class="{ requireStyle: errMap.appIntroduction }">
              <div class="list-item item-right">
                <div class="list-label">介绍App功能，课前预习、上课提醒、24小时客服</div>
                <div class="list-content">
                  <a-checkbox-group
                    v-model="appIntroduction"
                    @change="(val) => changeErr(!val.length, 'appIntroduction')"
                  >
                    <a-checkbox value="已介绍"> 已介绍 </a-checkbox>
                  </a-checkbox-group>
                </div>
              </div>
            </div>
          </template>

          <a-form-item label="备注">
            <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
          </a-form-item>
        </template>

        <!--时令变化-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'DAYLIGHT_TIME_CHANGED'"
          :class="{ requireStyle: errMap.seasonalChanges }"
        >
          <div class="list-item" style="flex-direction: column">
            <div class="list-label" style="margin-top: 10px">通知更换时令后的上课时间并得到家长确认：</div>
            <div class="list-content">
              <a-radio-group v-model="seasonalChanges" @change="(val) => changeErr(!val, 'seasonalChanges')">
                <a-radio value="家长已确认">家长已确认 </a-radio>
                <a-radio value="家长未确认">家长未确认（若产生课损，由班主任承担） </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'DAYLIGHT_TIME_CHANGED'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--首课回访-->
        <div class="list-itemWrap" v-if="taskType === 'TASK_FIRST_VISIT'">
          <div class="list-item">
            <div class="list-label">授课老师：</div>
            <div class="list-content">{{ taskObj.teacherName }}</div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT' && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.icebreaker }"
        >
          <div class="list-item item-right">
            <div class="list-label">老师是否完成课前破冰</div>
            <div class="list-content">
              <a-radio-group v-model="icebreaker" @change="(val) => changeErr(!val.target.value, 'icebreaker')">
                <a-radio value="已完成"> 已完成 </a-radio>
                <a-radio value="未完成"> 未完成 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT' && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.classCommunication }"
        >
          <div class="list-item item-right">
            <div class="list-label">老师是否在课后与家长完成沟通</div>
            <div class="list-content">
              <a-radio-group v-model="classCommunication" @change="(val) => changeCommunication(val.target.value)">
                <a-radio value="已完成"> 已完成 </a-radio>
                <a-radio value="未完成"> 未完成 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT' && showCommunication && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.classCommunicationReason }"
        >
          <div class="list-item item-right">
            <div class="list-label">老师未完成课后沟通的原因</div>
            <div class="list-content">
              <a-radio-group
                v-model="classCommunicationReason"
                @change="(val) => changeErr(!val.target.value, 'classCommunicationReason')"
              >
                <a-radio value="家长未出现"> 家长未出现 </a-radio>
                <a-radio value="老师忘记沟通"> 老师忘记沟通 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="list-itemWrap" v-if="taskType === 'TASK_FIRST_VISIT'">
          <div class="list-item">
            <div class="list-label">首课级别：</div>
            <div class="list-content">{{ taskObj.firstClassLevel }}</div>
          </div>
        </div>
        <div class="list-itemWrap" v-if="taskType === 'TASK_FIRST_VISIT'" :class="{ requireStyle: errMap.feedback }">
          <div class="list-item item-right">
            <div class="list-label">与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等</div>
            <div class="list-content">
              <a-checkbox-group v-model="feedback" @change="(val) => changeErr(!val.length, 'feedback')">
                <a-checkbox value="已反馈"> 已反馈 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT'"
          :class="{ requireStyle: errMap.understandingProgress }"
        >
          <div class="list-item item-right">
            <div class="list-label">了解家长对首课内容/学习进度的反馈</div>
            <div class="list-content">
              <a-radio-group v-model="understandingProgress" @change="(val) => changeUnderstanding(val.target.value)">
                <a-radio value="适合"> 适合 </a-radio>
                <a-radio value="不适合，需调整"> 不适合，需调整 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="级别进度如何调整" v-if="taskType === 'TASK_FIRST_VISIT' && showLevel">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'adjustLevel',
              {
                rules: [{ required: true, message: '请填写级别进度如何调整' }],
              },
            ]"
            :rows="3"
            placeholder="提示：请说明不合适的点，需要调整的地方"
          />
        </a-form-item>

        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT'"
          :class="{ requireStyle: errMap.understandingTeacher }"
        >
          <div class="list-item item-right">
            <div class="list-label">了解家长对首课老师的反馈</div>
            <div class="list-content">
              <a-radio-group v-model="understandingTeacher" @change="(val) => changeStandingTeacher(val.target.value)">
                <a-radio value="很满意"> 很满意</a-radio>
                <a-radio value="正常"> 正常</a-radio>
                <a-radio value="不满意"> 不满意</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="其他反馈补充" v-if="taskType === 'TASK_FIRST_VISIT' && showParentFeedback">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'parentFeedback',
              {
                rules: [{ required: true, message: '请填写其他反馈补充' }],
              },
            ]"
            :rows="3"
            placeholder="请填写家长对授课老师不满意的地方或其他情况补充"
          />
        </a-form-item>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT'"
          :class="{ requireStyle: errMap.parentExpectationManagement }"
        >
          <div class="list-item item-right">
            <div class="list-label">了解家长教学期望，与家长确认学习规划，做好预期管理</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="parentExpectationManagement"
                @change="(val) => changeErr(!val.length, 'parentExpectationManagement')"
              >
                <a-checkbox value="已沟通"> 已沟通 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT'"
          :class="{ requireStyle: errMap.reviewHomework }"
        >
          <div class="list-item item-right">
            <div class="list-label" v-if="taskObj.subject === 'CHINESE'">介绍如何复习、查作业和交作业</div>
            <div class="list-label" v-if="taskObj.subject === 'MATH'">介绍如何复习、查作业和交作业、错题本功能</div>
            <div class="list-content">
              <a-checkbox-group v-model="reviewHomework" @change="(val) => changeErr(!val.length, 'reviewHomework')">
                <a-checkbox value="已介绍"> 已介绍 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT'"
          :class="{ requireStyle: errMap.sharingRules }"
        >
          <div class="list-item item-right">
            <div class="list-label">介绍分享规则、操作方法和上传路径</div>
            <div class="list-content">
              <a-checkbox-group v-model="sharingRules" @change="(val) => changeErr(!val.length, 'sharingRules')">
                <a-checkbox value="已介绍"> 已介绍 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_FIRST_VISIT'"
          :class="{ requireStyle: errMap.communicationPreferences }"
        >
          <div class="list-item item-right">
            <div class="list-label">了解沟通时间及沟通方式偏好和学习负责人</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="communicationPreferences"
                @change="(val) => changeErr(!val.length, 'communicationPreferences')"
              >
                <a-checkbox value="已沟通"> 已沟通 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_FIRST_VISIT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--数学长期交接班后首课回访-->
        <template v-if="taskType === 'TASK_LONG_TASK_OVER_VISIT'">
          <div class="list-itemWrap" v-if="['MATH', 'ENGLISH'].includes(taskObj.subject)">
            <div class="list-item">
              <div class="list-label">授课老师：</div>
              <div class="list-content">{{ taskObj.teacherName }}</div>
            </div>
          </div>

          <div
            class="list-itemWrap"
            v-if="['MATH', 'ENGLISH'].includes(taskObj.subject)"
            :class="{ requireStyle: errMap.feedbackOver }"
          >
            <div class="list-item item-right">
              <div class="list-label">与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等</div>
              <div class="list-content">
                <a-checkbox-group v-model="feedbackOver" @change="(val) => changeErr(!val.length, 'feedbackOver')">
                  <a-checkbox value="已反馈"> 已反馈 </a-checkbox>
                </a-checkbox-group>
              </div>
            </div>
          </div>
          <div
            class="list-itemWrap"
            v-if="['MATH', 'ENGLISH'].includes(taskObj.subject)"
            :class="{ requireStyle: errMap.underNewTeacher }"
          >
            <div class="list-item item-right">
              <div class="list-label">了解家长对新老师的反馈</div>
              <div class="list-content">
                <a-radio-group
                  v-model="underNewTeacher"
                  @change="(val) => changeErr(!val.target.value, 'underNewTeacher')"
                >
                  <a-radio value="很满意">很满意</a-radio>
                  <a-radio value="正常"> 正常</a-radio>
                  <a-radio value="不满意">不满意</a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div v-if="underNewTeacher === '不满意' && ['MATH', 'ENGLISH'].includes(taskObj.subject)">
            <div style="margin-bottom: 5px; padding-left: 30px">请填写家长对授课老师不满意的地方或其他情况补充：</div>
            <a-form-item>
              <a-textarea
                @blur="changeResult"
                v-decorator="[
                  'parentFeedbackOver',
                  {
                    rules: [{ required: true, message: '请填写其他情况补充' }],
                  },
                ]"
                :rows="3"
                placeholder="请输入"
              />
            </a-form-item>
          </div>
          <a-form-item label="备注">
            <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
          </a-form-item>
        </template>

        <!--w3回访-->

        <div v-if="taskType === 'TASK_W3_VISIT'">
          <div style="margin-bottom: 5px; padding-left: 30px">
            与家长沟通学员近期学习进步点，课堂表现、作业完成情况及待提升点：
          </div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicatingStudent',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请完成与家长的学情沟通，并记录沟通内容"
            />
          </a-form-item>
        </div>

        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_W3_VISIT'"
          :class="{ requireStyle: errMap.studentPerformance }"
        >
          <div class="list-item item-right">
            <div class="list-label">根据学员近期学习表现、薄弱点制定下一步学习规划及建议，铺垫学习重难点</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="studentPerformance"
                @change="(val) => changeErr(!val.length, 'studentPerformance')"
              >
                <a-checkbox value="已制定"> 已制定</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div class="list-itemWrap" v-if="taskType === 'TASK_W3_VISIT'" :class="{ requireStyle: errMap.upgradePlan }">
          <div class="list-item item-right">
            <div class="list-label">补升方案介绍：结合学习情况铺垫方案性价比</div>
            <div class="list-content">
              <a-checkbox-group v-model="upgradePlan" @change="(val) => changeErr(!val.length, 'upgradePlan')">
                <a-checkbox value="已介绍"> 已介绍</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_W3_VISIT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--学员激活-->
        <div v-if="taskType === 'TASK_STUDENT_ACTIVE'">
          <div style="margin-bottom: 5px; padding-left: 30px">请记录回暖沟通内容：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicatingActive',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录沟通内容"
            />
          </a-form-item>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_ACTIVE'"
          :class="{ requireStyle: errMap.isResumeClasses }"
        >
          <div class="list-item">
            <div class="list-label">家长是否复课：</div>
            <div class="list-content">
              <a-radio-group
                v-model="isResumeClasses"
                @change="(val) => changeErr(!val.target.value, 'isResumeClasses')"
              >
                <a-radio value="是"> 是</a-radio>
                <a-radio value="否"> 否</a-radio>
                <a-radio value="未回复"> 未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="复课时间" v-if="taskType === 'TASK_STUDENT_ACTIVE' && isResumeClasses === '是'">
          <a-date-picker
            allowClear
            style="width: 100%"
            @blur="changeResult"
            v-decorator="['resumeClassesTime', { rules: [{ required: true, message: '请选择复课时间' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="备注" v-if="taskType === 'TASK_STUDENT_ACTIVE'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--每月回访,学员激活-->
        <a-form-item label="家长回暖" v-if="taskType === 'TASK_MOTH_VISIT'">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'parentsRecovery',
              {
                rules: [{ required: true, message: '请填写家长回暖' }],
              },
            ]"
            :rows="3"
            placeholder="参考：询问近期近况，孩子学情，可包含疫情（复课）、学校近况、天气等寒暄"
          />
        </a-form-item>
        <a-form-item label="沟通内容" v-if="taskType === 'TASK_MOTH_VISIT'">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'policySynchronization',
              {
                rules: [{ required: true, message: '请填写沟通内容' }],
              },
            ]"
            :rows="3"
            placeholder="参考：专题课推荐，利好政策同步等"
          />
        </a-form-item>
        <a-form-item label="复课计划" v-if="taskType === 'TASK_MOTH_VISIT'">
          <a-select
            @change="changeResumeClasses"
            v-decorator="['isResumeClasses', { rules: [{ required: true, message: '请选择复课计划' }] }]"
            placeholder="请选择"
          >
            <a-select-option :value="item.label" :key="index" v-for="(item, index) in isResumeClassesArr">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="复课时间" v-if="taskType === 'TASK_MOTH_VISIT' && showResumeClasses">
          <a-date-picker
            allowClear
            style="width: 100%"
            @blur="changeResult"
            v-decorator="['resumeClassesTime', { rules: [{ required: true, message: '请选择复课时间' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>

        <!--学习回访-->
        <div v-if="taskType === 'TASK_MOTH_STUDY_VISIT'">
          <div style="margin-bottom: 5px; padding-left: 30px">
            与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等：
          </div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicatingMothStudy',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请完成与家长的学情沟通，并记录沟通内容"
            />
          </a-form-item>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_MOTH_STUDY_VISIT'"
          :class="{ requireStyle: errMap.followUpResults }"
        >
          <div class="list-item">
            <div class="list-label">回访结果：</div>
            <div class="list-content">
              <a-radio-group
                v-model="followUpResults"
                @change="(val) => changeErr(!val.target.value, 'followUpResults')"
              >
                <a-radio value="家长认可"> 家长认可</a-radio>
                <a-radio value="家长有异议">家长有异议</a-radio>
                <a-radio value="家长未回复">家长未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="taskType === 'TASK_MOTH_STUDY_VISIT' && followUpResults === '家长有异议'">
          <div style="margin-bottom: 5px; padding-left: 30px">家长异议处理记录：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'processingRecords',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录家长异议、解决方案和结果"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_MOTH_STUDY_VISIT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <!--班主任交接学员首通-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'"
          :class="{ requireStyle: errMap.checkAcademicSituation }"
        >
          <div class="list-item">
            <div class="list-label">请查看并核对花果学员档案、学情及跟进记录：</div>
            <div class="list-content">
              <a-radio-group
                v-model="checkAcademicSituation"
                @change="(val) => changeErr(!val.target.value, 'checkAcademicSituation')"
              >
                <a-radio value="已确认学情信息一致"> 已确认学情信息一致</a-radio>
                <a-radio value="不一致，已完成修改">不一致，已完成修改</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'"
          :class="{ requireStyle: errMap.confirmCourseScheduling }"
        >
          <div class="list-item item-right">
            <div class="list-label">请确认排课系统中的待上课节数是否充足：</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="confirmCourseScheduling"
                @change="(val) => changeErr(!val.length, 'confirmCourseScheduling')"
              >
                <a-checkbox value="已确认"> 已确认</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'"
          :class="{ requireStyle: errMap.confirmClassTime }"
        >
          <div class="list-item item-right">
            <div class="list-label">与家长确认排课情况、确认上课时间及课频，班型及课时扣减规则、授课老师：</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="confirmClassTime"
                @change="(val) => changeErr(!val.length, 'confirmClassTime')"
              >
                <a-checkbox value="已确认"> 已确认</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'"
          :class="{ requireStyle: errMap.confirmIssues }"
        >
          <div class="list-item">
            <div class="list-label">与家长确认是否存在遗留问题：</div>
            <div class="list-content">
              <a-radio-group v-model="confirmIssues" @change="(val) => changeErr(!val.target.value, 'confirmIssues')">
                <a-radio value="无遗留"> 无遗留</a-radio>
                <a-radio value="有遗留待处理">有遗留待处理</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="taskType === 'TASK_CLASS_ADMIN_FIRST_PASS' && confirmIssues === '有遗留待处理'">
          <div style="margin-bottom: 5px; padding-left: 30px">遗留问题处理记录：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'confirmIssuesReason',
                {
                  rules: [{ required: true, message: '请填写遗留问题处理记录' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录遗留问题、解决方案和结果"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--手动添加跟进记录，课时转移-->
        <a-form-item label="转移类型" v-if="taskType === 'PERIOD_CHANGE'">
          <a-select
            @blur="changeResult"
            v-decorator="['transferType', { rules: [{ required: true, message: '请选择遗转移类型' }] }]"
            placeholder="请选择"
          >
            <a-select-option :value="item.label" :key="index" v-for="(item, index) in transferTypeArr">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="转移学员学号" v-if="taskType === 'PERIOD_CHANGE'">
          <a-select
            show-search
            allowClear
            :filter-option="filterOption"
            option-filter-prop="children"
            @search="searchStudentName"
            v-decorator="['transferStudents', { rules: [{ required: true, message: '请选择转移学生!' }] }]"
            placeholder="请选择转移学生"
          >
            <a-select-option
              v-for="(item, index) in studentList"
              :key="index"
              :value="`${item.fullName}（${item.code}）`"
              >{{ item.fullName }}（{{ item.code }}）</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="接收学员学号" v-if="taskType === 'PERIOD_CHANGE'">
          <a-select
            show-search
            allowClear
            :filter-option="filterOption"
            option-filter-prop="children"
            @search="searchStudentName"
            v-decorator="['receiveStudents', { rules: [{ required: true, message: '请选择接收学生!' }] }]"
            placeholder="请选择接收学生"
          >
            <a-select-option
              v-for="(item, index) in studentList"
              :key="index"
              :value="`${item.fullName}（${item.code}）`"
              >{{ item.fullName }}（{{ item.code }}）</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="折扣情况" v-if="taskType === 'PERIOD_CHANGE'">
          <a-select
            @blur="changeResult"
            v-decorator="['discountSituation', { rules: [{ required: true, message: '请选择折扣情况' }] }]"
            placeholder="请选择"
          >
            <a-select-option :value="item.label" :key="index" v-for="(item, index) in discountSituationArr">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="课时转移记录" v-if="taskType === 'PERIOD_CHANGE'">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'classTransferRecord',
              {
                rules: [{ required: true, message: '请填写课时转移记录' }],
              },
            ]"
            :rows="3"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="课时转移原因" v-if="taskType === 'PERIOD_CHANGE'">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'classTransferReason',
              {
                rules: [{ required: true, message: '请填写课时转移原因' }],
              },
            ]"
            :rows="3"
            placeholder="请输入"
          />
        </a-form-item>
        <!--学员缺勤-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_ABSENT'"
          :class="{ requireStyle: errMap.absenteeismReasons }"
        >
          <div class="list-item">
            <div class="list-label">了解学员缺勤原因：</div>
            <div class="list-content">
              <a-radio-group
                v-model="absenteeismReasons"
                @change="(val) => changeErr(!val.target.value, 'absenteeismReasons')"
              >
                <a-radio value="忘记请假"> 忘记请假</a-radio>
                <a-radio value="突发原因：停电、生病、断网等">突发原因：停电、生病、断网等 </a-radio>
                <a-radio value="家长未回复">其他</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_ABSENT' && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.lessonArrangement }"
        >
          <div class="list-item">
            <div class="list-label">请引导家长安排补课：</div>
            <div class="list-content">
              <a-radio-group
                v-model="lessonArrangement"
                @change="(val) => changeErr(!val.target.value, 'lessonArrangement')"
              >
                <a-radio value="已安排">已安排</a-radio>
                <a-radio value="无需补课">无需补课</a-radio>
                <a-radio value="未回复">未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_ABSENT' && (taskObj.subject === 'MATH' || taskObj.subject === 'ENGLISH')"
          :class="{ requireStyle: errMap.courseReplayAbsent }"
        >
          <div class="list-item">
            <div class="list-label">提醒学员查看课程回放：</div>
            <div class="list-content">
              <a-checkbox-group
                v-model="courseReplayAbsent"
                @change="(val) => changeErr(!val.length, 'courseReplayAbsent')"
              >
                <a-checkbox value="已提醒"> 已提醒 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_STUDENT_ABSENT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <!--老师交接班后的首课回访-->
        <div class="list-itemWrap" v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT'">
          <div class="list-item">
            <div class="list-label">授课老师：</div>
            <div class="list-content">{{ taskObj.teacherName }}</div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT' && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.icebreakerClass }"
        >
          <div class="list-item item-right">
            <div class="list-label">老师是否完成课前破冰</div>
            <div class="list-content">
              <a-radio-group
                v-model="icebreakerClass"
                @change="(val) => changeErr(!val.target.value, 'icebreakerClass')"
              >
                <a-radio value="已完成"> 已完成 </a-radio>
                <a-radio value="未完成"> 未完成 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT' && taskObj.subject === 'CHINESE'"
          :class="{ requireStyle: errMap.classCommunicationVisit }"
        >
          <div class="list-item item-right">
            <div class="list-label">老师是否在课后与家长完成沟通</div>
            <div class="list-content">
              <a-radio-group
                v-model="classCommunicationVisit"
                @change="(val) => changeErr(!val.target.value, 'classCommunicationVisit')"
              >
                <a-radio value="已完成"> 已完成 </a-radio>
                <a-radio value="未完成"> 未完成 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="
            taskType === 'TASK_CHANGE_TEACHER_VISIT' &&
            classCommunicationVisit === '未完成' &&
            taskObj.subject === 'CHINESE'
          "
          :class="{ requireStyle: errMap.classCommunicationVisitReason }"
        >
          <div class="list-item item-right">
            <div class="list-label">老师未完成课后沟通的原因</div>
            <div class="list-content">
              <a-radio-group
                v-model="classCommunicationVisitReason"
                @change="(val) => changeErr(!val.target.value, 'classCommunicationVisitReason')"
              >
                <a-radio value="家长未出现"> 家长未出现 </a-radio>
                <a-radio value="老师忘记沟通"> 老师忘记沟通 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>

        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT'"
          :class="{ requireStyle: errMap.feedbackVisit }"
        >
          <div class="list-item item-right">
            <div class="list-label">与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等</div>
            <div class="list-content">
              <a-checkbox-group v-model="feedbackVisit" @change="(val) => changeErr(!val.length, 'feedbackVisit')">
                <a-checkbox value="已反馈"> 已反馈 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>

        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT'"
          :class="{ requireStyle: errMap.underTeacherVisit }"
        >
          <div class="list-item item-right">
            <div class="list-label">了解家长对首课老师的反馈</div>
            <div class="list-content">
              <a-radio-group
                v-model="underTeacherVisit"
                @change="(val) => changeErr(!val.target.value, 'underTeacherVisit')"
              >
                <a-radio value="很满意"> 很满意</a-radio>
                <a-radio value="正常"> 正常</a-radio>
                <a-radio value="不满意"> 不满意</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT' && underTeacherVisit === '不满意'">
          <div style="margin-bottom: 5px; padding-left: 30px">请填写家长对授课老师不满意的地方或其他情况补充：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'parentFeedbackVisit',
                {
                  rules: [{ required: true, message: '请填写其他情况补充' }],
                },
              ]"
              :rows="3"
              placeholder="请输入"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_CHANGE_TEACHER_VISIT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <div
          class="list-itemWrap"
          v-if="['NEW_STUDENT_FOLLOW_UP', 'TRANSFER_STUDENT_FOLLOW_UP'].includes(taskType)"
          :class="{ requireStyle: errMap.introduceAndInvite }"
        >
          <div class="list-item item-right">
            <div class="list-label">完成自我介绍和首通邀约</div>
            <div class="list-content">
              <a-radio-group
                v-model="introduceAndInvite"
                @change="(val) => changeErr(!val.target.value, 'introduceAndInvite')"
              >
                <a-radio value="已完成"> 已完成 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="['NEW_STUDENT_FOLLOW_UP', 'TRANSFER_STUDENT_FOLLOW_UP'].includes(taskType)">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <!--学习报告-->
        <div v-if="taskType === 'STAGE_LEARNING_REPORT'">
          <div style="margin-bottom: 5px; padding-left: 30px">
            与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议：
          </div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicatingReport',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请完成阶段学情反馈并记录反馈内容"
            />
          </a-form-item>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'STAGE_LEARNING_REPORT'"
          :class="{ requireStyle: errMap.studyReport }"
        >
          <div class="list-item item-right">
            <div class="list-label">铺垫下阶段的学习内容和重难点</div>
            <div class="list-content">
              <a-checkbox-group v-model="studyReport" @change="(val) => changeErr(!val.length, 'studyReport')">
                <a-checkbox value="已介绍"> 已介绍 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'STAGE_LEARNING_REPORT'"
          :class="{ requireStyle: errMap.followupResultsReport }"
        >
          <div class="list-item">
            <div class="list-label">回访结果：</div>
            <div class="list-content">
              <a-radio-group
                v-model="followupResultsReport"
                @change="(val) => changeErr(!val.target.value, 'followupResultsReport')"
              >
                <a-radio value="家长认可">家长认可</a-radio>
                <a-radio value="家长有异议"> 家长有异议</a-radio>
                <a-radio value="家长未回复"> 家长未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="taskType === 'STAGE_LEARNING_REPORT' && followupResultsReport === '家长有异议'">
          <div style="margin-bottom: 5px; padding-left: 30px">家长异议处理记录：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'processingRecordsReport',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录家长异议、解决方案和结果"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="taskType === 'STAGE_LEARNING_REPORT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <!--原班主任交接学生-->
        <template v-if="taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN'">
          <div class="list-itemWrap" :class="{ requireStyle: errMap.studentPortrait }">
            <div class="list-item">
              <div class="list-label">学生画像补充:</div>
              <div class="list-content">
                <a-button @click="goStudentDetailServiceTab">立即填写</a-button>
                <!-- <a-checkbox-group v-model="studentPortrait" @change="(val) => changeErr(!val.length, 'studentPortrait')">
                <a-checkbox value="已完成">已完成</a-checkbox>
              </a-checkbox-group> -->
              </div>
            </div>
          </div>
          <div class="list-itemWrap" :class="{ requireStyle: errMap.parentPortrait }">
            <div class="list-item">
              <div class="list-label">家长画像补充:</div>
              <div class="list-content">
                <a-button @click="goStudentDetailServiceTab">立即填写</a-button>
                <!-- <a-checkbox-group v-model="parentPortrait" @change="(val) => changeErr(!val.length, 'parentPortrait')">
                <a-checkbox value="已完成">已完成</a-checkbox>
              </a-checkbox-group> -->
              </div>
            </div>
          </div>
          <div
            class="list-itemWrap"
            v-if="taskObj.processVariables.subject === 'CHINESE'"
            :class="{ requireStyle: errMap.academicSituation }"
          >
            <div class="list-item">
              <div class="list-label">当前学情补充:</div>
              <div class="list-content">
                <a-button @click="goStudentDetailSituationTab">立即填写</a-button>

                <!-- <a-checkbox-group
                v-model="academicSituation"
                @change="(val) => changeErr(!val.length, 'academicSituation')"
              >
                <a-checkbox value="已完成">已完成</a-checkbox>
              </a-checkbox-group> -->
              </div>
            </div>
          </div>
          <div class="list-itemWrap" :class="{ requireStyle: errMap.sharingEngagement }">
            <div class="list-item">
              <div class="list-label">分享参与度：</div>
              <div class="list-content">
                <a-radio-group
                  v-model="sharingEngagement"
                  @change="(val) => changeErr(!val.target.value, 'sharingEngagement')"
                >
                  <a-radio value="积极参与">积极参与</a-radio>
                  <a-radio value="一般，不积极">一般，不积极</a-radio>
                  <a-radio value="不参与，很排斥">不参与，很排斥</a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div v-if="sharingEngagement === '一般，不积极' || sharingEngagement === '不参与，很排斥'">
            <div style="margin-bottom: 5px; padding-left: 30px">请补充说明不参与分享的原因：</div>
            <a-form-item>
              <a-textarea
                @blur="changeResult"
                v-decorator="[
                  'sharingEngagementReason',
                  {
                    rules: [{ required: true, message: '请填写不参与分享的原因' }],
                  },
                ]"
                :rows="3"
                :maxlength="999"
                placeholder="提示：请补充说明"
              />
            </a-form-item>
          </div>
          <div
            class="list-itemWrap"
            v-if="taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN'"
            :class="{ requireStyle: errMap.solveProblems }"
          >
            <div class="list-item">
              <div class="list-label">待解决异议点/问题：</div>
              <div class="list-content">
                <a-checkbox-group v-model="solveProblems" @change="(val) => changeErr(!val.length, 'solveProblems')">
                  <a-checkbox value="无">无</a-checkbox>
                  <a-checkbox value="沟通课时转移中">沟通课时转移中</a-checkbox>
                  <a-checkbox value="沟通退费中">沟通退费中</a-checkbox>
                  <a-checkbox value="存在课时赔付">存在课时赔付</a-checkbox>
                  <a-checkbox value="其他">其他</a-checkbox>
                </a-checkbox-group>
              </div>
            </div>
          </div>
          <div v-if="solveProblems.includes('其他')">
            <div style="margin-bottom: 5px; padding-left: 30px">请说明待解决异议点或遗留问题：</div>
            <a-form-item>
              <a-textarea
                @blur="changeResult"
                v-decorator="[
                  'solveProblemReason',
                  {
                    rules: [{ required: true, message: '请填写待解决异议点或遗留问题' }],
                  },
                ]"
                :rows="3"
                :maxlength="999"
                placeholder="提示：请补充说明"
              />
            </a-form-item>
          </div>
          <a-form-item label="备注">
            <a-textarea
              v-decorator="['description']"
              :rows="4"
              placeholder="请输入"
              @blur="changeResult"
              :maxlength="999"
            />
          </a-form-item>
        </template>
        <!-- 新班主任交接学生 -->
        <template v-if="['TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_V2'].includes(taskType)">
          <a-form-item label="沟通结果" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <a-radio-group
              style="margin-top: 10px"
              v-decorator="[
                'communicationResult',
                {
                  rules: [{ required: true, message: '请填写不参与分享的原因' }],
                },
              ]"
              @blur="changeResult"
              @change="(val) => changeErr(!val.target.value, 'communicationResult')"
            >
              <a-radio value="沟通成功"> 沟通成功 </a-radio>
              <a-radio value="沟通失败"> 沟通失败</a-radio>
            </a-radio-group>
          </a-form-item>
          <template v-if="form.getFieldValue('communicationResult') === '沟通失败'">
            <a-form-item label="失败原因" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
              <a-radio-group
                style="margin-top: 10px"
                v-decorator="[
                  'failedReason',
                  {
                    rules: [{ required: true, message: '请选择失败原因' }],
                  },
                ]"
                @blur="changeResult"
                @change="(val) => changeErr(!val.target.value, 'failedReason')"
              >
                <a-radio value="未和家长联系"> 未和家长联系 </a-radio>
                <a-radio value="家长改约沟通时间"> 家长改约沟通时间</a-radio>
                <a-radio value="未成功添加企微"> 未成功添加企微</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="下次跟进时间">
              <a-date-picker
                style="width: 100%"
                :disabled-date="disabledDate"
                :disabled-time="disabledDateTime"
                v-decorator="['nextFollowDate', { rules: [{ required: true, message: '请选择下次跟进时间' }] }]"
                format="YYYY-MM-DD HH:mm:ss"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                @change="changeResult"
              />
            </a-form-item>
          </template>
          <template v-if="form.getFieldValue('communicationResult') === '沟通成功'">
            <a-form-item
              label="请查看并核对花果学员档案，学情及跟进记录"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-radio-group
                style="margin-top: 10px"
                v-decorator="[
                  'isCheckedStudent',
                  {
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
                @blur="changeResult"
                @change="(val) => changeErr(!val.target.value, 'isCheckedStudent')"
              >
                <a-radio value="已确认学情信息一致"> 已确认学情信息一致 </a-radio>
                <a-radio value="不一致，已完成修改"> 不一致，已完成修改</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="请确认排课系统中待上课节是否充足"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-radio-group
                style="margin-top: 10px"
                v-decorator="[
                  'isCheckedSchedule',
                  {
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
                @blur="changeResult"
                @change="(val) => changeErr(!val.target.value, 'isCheckedSchedule')"
              >
                <a-radio value="已确认">已确认</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="与家长确认排课情况、确认上课时间及课频，班型及课时扣减规则、授课老师"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-radio-group
                style="margin-top: 10px"
                v-decorator="[
                  'isCheckedGenearch',
                  {
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
                @blur="changeResult"
                @change="(val) => changeErr(!val.target.value, 'isCheckedGenearch')"
              >
                <a-radio value="已确认">已确认</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="与家长确认是否存在遗留问题"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-radio-group
                style="margin-top: 10px"
                v-decorator="[
                  'isCheckedLegacyIssues',
                  {
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
                @blur="changeResult"
                @change="(val) => changeErr(!val.target.value, 'isCheckedLegacyIssues')"
              >
                <a-radio value="无遗留">无遗留</a-radio>
                <a-radio value="有遗留待处理">有遗留待处理</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="遗留问题处理记录" v-if="form.getFieldValue('isCheckedLegacyIssues') === '有遗留待处理'">
              <a-textarea
                @blur="changeResult"
                v-decorator="[
                  'legacyIssuesRecord',
                  {
                    rules: [{ required: true, message: '请填写遗留问题处理记录' }],
                  },
                ]"
                :rows="3"
                :maxlength="999"
                placeholder="请记录遗留问题、解决方案和结果"
              />
            </a-form-item>
          </template>
          <a-form-item label="备注">
            <a-textarea :maxlength="999" placeholder="请输入" :rows="3" v-decorator="['remark']" />
          </a-form-item>
        </template>
        <!--授课老师旷课-->
        <a-form-item label="老师旷课原因" v-if="taskType === 'TASK_TEACHER_ABSENT'">
          <a-select
            @change="changeAbsenteeism"
            v-decorator="['absenteeism', { rules: [{ required: true, message: '请选择旷课原因' }] }]"
            placeholder="请选择"
          >
            <a-select-option :value="item.label" :key="index" v-for="(item, index) in absenteeismArr">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="其他原因" v-if="taskType === 'TASK_TEACHER_ABSENT' && showAbsent">
          <a-textarea
            @blur="changeResult"
            v-decorator="[
              'absenteeismReason',
              {
                rules: [{ required: true, message: '请填写原因' }],
              },
            ]"
            :rows="3"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="补课安排" v-if="taskType === 'TASK_TEACHER_ABSENT'">
          <a-select
            @blur="changeResult"
            v-decorator="['makeUpLessons', { rules: [{ required: true, message: '请选择补课安排' }] }]"
            placeholder="请选择"
          >
            <a-select-option :value="item.label" :key="index" v-for="(item, index) in makeUpLessonsArr">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!--拓科任务，数学，英语线索创建-->
        <div class="list-itemWrap" v-if="taskType === 'TASK_TRACK_CREATE'" :class="{ requireStyle: errMap.isAudition }">
          <div class="list-item">
            <div class="list-label">是否同意注册试听：</div>
            <div class="list-content">
              <a-radio-group v-model="isAudition" @change="(val) => changeErr(!val.target.value, 'isAudition')">
                <a-radio value="同意">同意</a-radio>
                <a-radio value="不同意">不同意</a-radio>
                <a-radio value="未回复">未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_TRACK_CREATE' && isAudition === '未回复'"
          :class="{ requireStyle: errMap.followDayClue }"
        >
          <div class="list-item">
            <div class="list-label">是否至少跟进3天：</div>
            <div class="list-content">
              <a-checkbox-group v-model="followDayClue" @change="(val) => changeErr(!val.length, 'followDayClue')">
                <a-checkbox value="是">是</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_TRACK_CREATE' && isAudition === '不同意'"
          :class="{ requireStyle: errMap.isAuditionReason }"
        >
          <div class="list-item">
            <div class="list-label" style="width: 175px">不注册试听原因：</div>
            <div class="list-content">
              <a-radio-group
                v-model="isAuditionReason"
                @change="(val) => changeErr(!val.target.value, 'isAuditionReason')"
              >
                <a-radio value="无学习诉求">无学习诉求</a-radio>
                <a-radio value="线上已有课">线上已有课</a-radio>
                <a-radio value="线下已有课">线下已有课</a-radio>
                <a-radio value="不认可班型/时长">不认可班型/时长</a-radio>
                <a-radio value="不符入学条件">不符入学条件</a-radio>
                <a-radio value="课程内容不符合需求">课程内容不符合需求</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_TRACK_CREATE'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--拓科任务，约课任务-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_SUBSCRIBE_COURSE'"
          :class="{ requireStyle: errMap.isAuditionCourse }"
        >
          <div class="list-item">
            <div class="list-label">是否同意注册试听：</div>
            <div class="list-content">
              <a-radio-group
                v-model="isAuditionCourse"
                @change="(val) => changeErr(!val.target.value, 'isAuditionCourse')"
              >
                <a-radio value="同意">同意</a-radio>
                <a-radio value="不同意">不同意</a-radio>
                <a-radio value="未回复">未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_SUBSCRIBE_COURSE' && isAuditionCourse === '未回复'"
          :class="{ requireStyle: errMap.followDayCourse }"
        >
          <div class="list-item">
            <div class="list-label">是否至少跟进3天：</div>
            <div class="list-content">
              <a-checkbox-group v-model="followDayCourse" @change="(val) => changeErr(!val.length, 'followDayCourse')">
                <a-checkbox value="是">是</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_SUBSCRIBE_COURSE' && isAuditionCourse === '同意'"
          :class="{ requireStyle: errMap.isAppointmentClass }"
        >
          <div class="list-item">
            <div class="list-label" style="width: 175px">是否约课成功：</div>
            <div class="list-content">
              <a-radio-group
                v-model="isAppointmentClass"
                @change="(val) => changeErr(!val.target.value, 'isAppointmentClass')"
              >
                <a-radio value="已约">已约</a-radio>
                <a-radio value="未约">未约</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_SUBSCRIBE_COURSE' && isAuditionCourse === '同意' && isAppointmentClass === '未约'"
          :class="{ requireStyle: errMap.isAppointmentReason }"
        >
          <div class="list-item">
            <div class="list-label" style="width: 175px">未约课原因：</div>
            <div class="list-content">
              <a-radio-group
                v-model="isAppointmentReason"
                @change="(val) => changeErr(!val.target.value, 'isAppointmentReason')"
              >
                <a-radio value="可试听时间不符">可试听时间不符</a-radio>
                <a-radio value="线上已有课">线上已有课</a-radio>
                <a-radio value="不认可班型/时长">不认可班型/时长</a-radio>
                <a-radio value="课程内容不符合需求">课程内容不符合需求</a-radio>
                <a-radio value="不符合入学条件">不符合入学条件</a-radio>
                <a-radio value="线下已有课">线下已有课</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_SUBSCRIBE_COURSE'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--添加结业证书-->
        <div v-if="taskType === 'TASK_CERTIFICATE'">
          <div style="margin-bottom: 5px; padding-left: 30px">
            请与家长沟通学员课堂参与度、内容掌握情况、薄弱项及学习建议：
          </div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicateCertificate',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请完成此阶段学情反馈并记录反馈内容"
            />
          </a-form-item>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_CERTIFICATE'"
          :class="{ requireStyle: errMap.followResultsCertificate }"
        >
          <div class="list-item">
            <div class="list-label">回访结果：</div>
            <div class="list-content">
              <a-radio-group
                v-model="followResultsCertificate"
                @change="(val) => changeErr(!val.target.value, 'followResultsCertificate')"
              >
                <a-radio value="家长认可">家长认可</a-radio>
                <a-radio value="家长有异议"> 家长有异议</a-radio>
                <a-radio value="家长未回复"> 家长未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="taskType === 'TASK_CERTIFICATE' && followResultsCertificate === '家长有异议'">
          <div style="margin-bottom: 5px; padding-left: 30px">家长异议处理记录：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'recordsCertificate',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录家长异议、解决方案和结果"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_CERTIFICATE'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <!--不续费质检角色的跟进记录-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_NOT_RENEW' && taskObj.userRole === 'QA'"
          :class="{ requireStyle: errMap.isExamination }"
        >
          <div class="list-item">
            <div class="list-label">是否抽检：</div>
            <div class="list-content">
              <a-radio-group v-model="isExamination" @change="(val) => changeErr(!val.target.value, 'isExamination')">
                <a-radio value="是">是</a-radio>
                <a-radio value="否"> 否</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_NOT_RENEW' && taskObj.userRole === 'QA'">
          <a-textarea
            v-decorator="['description', { rules: [{ required: true, message: '请填写备注' }] }]"
            :rows="4"
            placeholder="请输入"
            @blur="changeResult"
          />
        </a-form-item>

        <!--新生补升-->
        <div class="list-itemWrap" v-if="taskType === 'TASK_PROMOTE'" :class="{ requireStyle: errMap.isMakeUp }">
          <div class="list-item">
            <div class="list-label">补升情况：</div>
            <div class="list-content">
              <a-radio-group v-model="isMakeUp" @change="(val) => changeErr(!val.target.value, 'isMakeUp')">
                <a-radio value="已补升"> 已补升 </a-radio>
                <a-radio value="放弃补升权益"> 放弃补升权益</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_PROMOTE' && isMakeUp === '放弃补升权益'"
          :class="{ requireStyle: errMap.oneMakeUpReason }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 115px">未补升一级原因：</div>
            <div class="list-content">
              <a-radio-group v-model="oneMakeUpReason" @change="(val) => changeOneMakeUpReason(val.target.value)">
                <a-radio value="认可课程设置和规则，效果满意">认可课程设置和规则，效果满意</a-radio>
                <a-radio value="认可课程设置和规则，不满意效果"> 认可课程设置和规则，不满意效果</a-radio>
                <a-radio value="不认可课程设置和规则">不认可课程设置和规则</a-radio>
                <a-radio value="其他"> 其他</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_PROMOTE' && oneMakeUpReason && isMakeUp === '放弃补升权益'"
          :class="{ requireStyle: errMap.twoMakeUpReason }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 115px">未补升二级原因：</div>
            <div class="list-content">
              <a-radio-group v-model="twoMakeUpReason" @change="(val) => changeTwoMakeUpReason(val.target.value)">
                <div v-show="oneMakeUpReason === '认可课程设置和规则，效果满意'">
                  <a-radio value="学生原因">学生原因</a-radio>
                  <a-radio value="家长原因"> 家长原因</a-radio>
                  <a-radio value="暂无补升需求">暂无补升需求</a-radio>
                </div>
                <div v-show="oneMakeUpReason === '认可课程设置和规则，不满意效果'">
                  <a-radio value="老师原因">老师原因</a-radio>
                  <a-radio value="产品原因"> 产品原因</a-radio>
                  <a-radio value="作业">作业</a-radio>
                  <a-radio value="系统">系统</a-radio>
                </div>
                <div v-show="oneMakeUpReason === '不认可课程设置和规则'">
                  <a-radio value="规则">规则</a-radio>
                  <a-radio value="课程设置"> 课程设置</a-radio>
                </div>
                <div v-show="oneMakeUpReason === '其他'">
                  <a-radio value="家长拒绝进一步沟通">家长拒绝进一步沟通</a-radio>
                  <!-- <a-radio value="其他"> 其他</a-radio> -->
                </div>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_PROMOTE' && isMakeUp === '放弃补升权益' && twoMakeUpReason"
          :class="{ requireStyle: errMap.threeMakeUpReason }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 115px">未补升三级原因：</div>
            <div class="list-content">
              <a-radio-group
                v-model="threeMakeUpReason"
                @change="(val) => changeErr(!val.target.value, 'threeMakeUpReason')"
              >
                <div v-show="twoMakeUpReason === '学生原因'">
                  <a-radio value="学习兴趣">学习兴趣</a-radio>
                  <a-radio value="学生低龄，学习不稳定"> 学生低龄，学习不稳定</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '家长原因'">
                  <a-radio value="消费观念保守">消费观念保守</a-radio>
                  <a-radio value="冻结课时"> 冻结课时</a-radio>
                  <a-radio value="资金紧张">资金紧张</a-radio>
                  <a-radio value="学习规划">学习规划</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '暂无补升需求'">
                  <a-radio value="课时消耗慢">课时消耗慢</a-radio>
                  <a-radio value="现有课时满足学生目前学习需求"> 现有课时满足学生目前学习需求</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '老师原因'">
                  <a-radio value="教学课上服务">教学课上服务</a-radio>
                  <a-radio value="老师更换"> 老师更换</a-radio>
                  <a-radio value="教师课后服务">教师课后服务</a-radio>
                  <a-radio value="资质/经验问题">资质/经验问题</a-radio>
                  <a-radio value="级别定位不准">级别定位不准</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '产品原因'">
                  <a-radio value="中文课件不适配">中文课件不适配</a-radio>
                  <a-radio value="观望学习效果"> 观望学习效果</a-radio>
                  <a-radio value="数学A系列课件">数学A系列课件</a-radio>
                  <a-radio value="数学竞赛课件">数学竞赛课件</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '作业'">
                  <a-radio value="在线作业/预复习资料模式">在线作业/预复习资料模式</a-radio>
                  <a-radio value="课后作业数量/难度"> 课后作业数量/难度</a-radio>
                  <a-radio value="在线作业内容有误">在线作业内容有误</a-radio>
                  <a-radio value="在线作业内容单一">在线作业内容单一</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '系统'">
                  <a-radio value="上课软件">上课软件</a-radio>
                  <a-radio value="App问题">App问题</a-radio>
                  <a-radio value="小程序问题">小程序问题</a-radio>
                  <a-radio value="支付方式">支付方式</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '规则'">
                  <a-radio value="请假机制">请假机制</a-radio>
                  <a-radio value="分享赠课机制">分享赠课机制</a-radio>
                  <a-radio value="课时扣减机制">课时扣减机制</a-radio>
                  <a-radio value="课时有效期规则">课时有效期规则</a-radio>
                  <a-radio value="课包退费规则">课包退费规则</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '课程设置'">
                  <a-radio value="课节时长">课节时长</a-radio>
                  <a-radio value="教学应试体系不匹配">教学应试体系不匹配</a-radio>
                  <a-radio value="课程难易度不匹配">课程难易度不匹配</a-radio>
                  <a-radio value="字体问题">字体问题</a-radio>
                </div>
                <div v-show="twoMakeUpReason === '家长拒绝进一步沟通'">
                  <a-radio value="家长拒绝进一步沟通">家长拒绝进一步沟通</a-radio>
                </div>
                <!-- <div v-show="twoMakeUpReason==='其他'">
                    <a-radio value="已有原因无法选择">已有原因无法选择</a-radio>
                </div> -->
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_PROMOTE'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--阶段回访任务-->
        <div v-if="taskType === 'TASK_TERM_VISIT'">
          <div style="margin-bottom: 5px; padding-left: 30px">
            与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议：
          </div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicatingTerm',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请完成阶段学情反馈并记录反馈内容"
            />
          </a-form-item>
        </div>
        <div class="list-itemWrap" v-if="taskType === 'TASK_TERM_VISIT'" :class="{ requireStyle: errMap.studyTerm }">
          <div class="list-item item-right">
            <div class="list-label">铺垫下阶段的学习内容和重难点</div>
            <div class="list-content">
              <a-checkbox-group v-model="studyTerm" @change="(val) => changeErr(!val.length, 'studyTerm')">
                <a-checkbox value="已介绍"> 已介绍 </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_TERM_VISIT'"
          :class="{ requireStyle: errMap.followupResultsTerm }"
        >
          <div class="list-item">
            <div class="list-label">回访结果：</div>
            <div class="list-content">
              <a-radio-group
                v-model="followupResultsTerm"
                @change="(val) => changeErr(!val.target.value, 'followupResultsTerm')"
              >
                <a-radio value="家长认可">家长认可</a-radio>
                <a-radio value="家长有异议"> 家长有异议</a-radio>
                <a-radio value="家长未回复"> 家长未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="taskType === 'TASK_TERM_VISIT' && followupResultsTerm === '家长有异议'">
          <div style="margin-bottom: 5px; padding-left: 30px">家长异议处理记录：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'processingRecordsTerm',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录家长异议、解决方案和结果"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_TERM_VISIT'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--周阶段任务回访-->
        <div v-if="['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(taskType)">
          <div style="margin-bottom: 5px; padding-left: 30px">
            与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等：
          </div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'communicatingWeek',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请完成与家长的学情沟通，并记录沟通内容"
            />
          </a-form-item>
        </div>
        <div
          class="list-itemWrap"
          v-if="['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(taskType)"
          :class="{ requireStyle: errMap.followupResultsWeek }"
        >
          <div class="list-item">
            <div class="list-label">回访结果：</div>
            <div class="list-content">
              <a-radio-group
                v-model="followupResultsWeek"
                @change="(val) => changeErr(!val.target.value, 'followupResultsWeek')"
              >
                <a-radio value="家长认可">家长认可</a-radio>
                <a-radio value="家长有异议"> 家长有异议</a-radio>
                <a-radio value="家长未回复"> 家长未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(taskType) && followupResultsWeek === '家长有异议'">
          <div style="margin-bottom: 5px; padding-left: 30px">家长异议处理记录：</div>
          <a-form-item>
            <a-textarea
              @blur="changeResult"
              v-decorator="[
                'processingRecordsWeek',
                {
                  rules: [{ required: true, message: '请填写' }],
                },
              ]"
              :rows="3"
              placeholder="提示：请记录家长异议、解决方案和结果"
            />
          </a-form-item>
        </div>
        <a-form-item label="备注" v-if="['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(taskType)">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>

        <!--流失学员首通-->

        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'"
          :class="{ requireStyle: errMap.oneLossReason }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 100px">流失一级原因：</div>
            <div class="list-content">
              <a-radio-group v-model="oneLossReason" @change="(val) => changeOneLossReason(val.target.value)">
                <a-radio value="认可课程服务规划，效果满意">认可课程服务规划，效果满意</a-radio>
                <a-radio value="认可课程服务规划，效果不满意">认可课程服务规划，效果不满意</a-radio>
                <a-radio value="不认可课程服务规划">不认可课程服务规划</a-radio>
                <a-radio value="其他"> 其他</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' && oneLossReason"
          :class="{ requireStyle: errMap.twoLossReason }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 100px">流失二级原因：</div>
            <div class="list-content">
              <a-radio-group v-model="twoLossReason" @change="(val) => changeTwoLossReason(val.target.value)">
                <div v-show="oneLossReason === '认可课程服务规划，效果满意'">
                  <a-radio value="学生原因">学生原因</a-radio>
                  <a-radio value="家长原因"> 家长原因</a-radio>
                  <a-radio value="价格因素">价格因素</a-radio>
                  <a-radio value="不可抗力因素">不可抗力因素</a-radio>
                </div>
                <div v-show="oneLossReason === '认可课程服务规划，效果不满意'">
                  <a-radio value="教学原因">教学原因</a-radio>
                  <a-radio value="班主任服务原因">班主任服务原因</a-radio>
                  <a-radio value="课件和课程设置问题">课件和课程设置问题</a-radio>
                  <a-radio value="教材作业">教材作业</a-radio>
                  <a-radio value="系统问题">系统问题</a-radio>
                </div>
                <div v-show="oneLossReason === '不认可课程服务规划'">
                  <a-radio value="服务">服务</a-radio>
                  <a-radio value="规则">规则</a-radio>
                  <a-radio value="课程设置其他"> 课程设置其他</a-radio>
                </div>
                <div v-show="oneLossReason === '其他'">
                  <a-radio value="家长拒绝进一步沟通">家长拒绝进一步沟通</a-radio>
                  <a-radio value="其他">其他</a-radio>
                </div>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' && twoLossReason"
          :class="{ requireStyle: errMap.threeLossReason }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 100px">流失三级原因：</div>
            <div class="list-content">
              <a-radio-group
                v-model="threeLossReason"
                @change="(val) => changeErr(!val.target.value, 'threeLossReason')"
              >
                <div v-show="twoLossReason === '学生原因'">
                  <a-radio value="学生健康原因">学生健康原因</a-radio>
                  <a-radio value="学生时间原因"> 学生时间原因</a-radio>
                  <a-radio value="学生兴趣原因">学生兴趣原因</a-radio>
                  <a-radio value="学生无学习诉求">学生无学习诉求</a-radio>
                  <a-radio value="学生年龄因素">学生年龄因素</a-radio>
                </div>
                <div v-show="twoLossReason === '家长原因'">
                  <a-radio value="家庭因素">家庭因素</a-radio>
                  <a-radio value="经济因素"> 经济因素</a-radio>
                  <a-radio value="家长时间紧张">家长时间紧张</a-radio>
                  <a-radio value="设备网络">设备网络</a-radio>
                  <a-radio value="退后重报">退后重报</a-radio>
                </div>
                <div v-show="twoLossReason === '价格因素'">
                  <a-radio value="课程总价/单价">课程总价/单价</a-radio>
                </div>
                <div v-show="twoLossReason === '不可抗力因素'">
                  <a-radio value="自然灾害/战争等">自然灾害/战争等</a-radio>
                  <a-radio value="国家/国际政策">国家/国际政策</a-radio>
                  <a-radio value="线上机构影响">线上机构影响</a-radio>
                </div>
                <div v-show="twoLossReason === '教学原因'">
                  <a-radio value="教学课上服务">教学课上服务</a-radio>
                  <a-radio value="老师更换">老师更换</a-radio>
                  <a-radio value="中文教师课后服务">中文教师课后服务</a-radio>
                  <a-radio value="资质/经验问题">资质/经验问题</a-radio>
                  <a-radio value="因时间/小语种匹配不到老师">因时间/小语种匹配不到老师</a-radio>
                  <a-radio value="级别定位不准">级别定位不准</a-radio>
                </div>
                <div v-show="twoLossReason === '班主任服务原因'">
                  <a-radio value="邮寄资料">邮寄资料</a-radio>
                  <a-radio value="班主任排课问题">班主任排课问题</a-radio>
                  <a-radio value="班主任教师安排问题">班主任教师安排问题</a-radio>
                  <a-radio value="班主任专业性">班主任专业性</a-radio>
                  <a-radio value="班主任更换">班主任更换</a-radio>
                  <a-radio value="班主任服务态度/言语/及时性">班主任服务态度/言语/及时性</a-radio>
                </div>
                <div v-show="twoLossReason === '课件和课程设置问题'">
                  <a-radio value="S系列课件不适配">S系列课件不适配</a-radio>
                  <a-radio value="K系列课件不适配">K系列课件不适配</a-radio>
                  <a-radio value="L系列课件不适配">L系列课件不适配</a-radio>
                  <a-radio value="G系列课件不适配">G系列课件不适配</a-radio>
                  <a-radio value="数学A系列课件">数学A系列课件</a-radio>
                  <a-radio value="课程设置（学习效果相关）">课程设置（学习效果相关）</a-radio>
                </div>
                <div v-show="twoLossReason === '教材作业'">
                  <a-radio value="作业资料">作业资料</a-radio>
                  <a-radio value="课后作业数量">课后作业数量</a-radio>
                  <a-radio value="课后作业难度">课后作业难度</a-radio>
                  <a-radio value="在线作业订正方式">在线作业订正方式</a-radio>
                  <a-radio value="在线作业单一&模式">在线作业单一&模式</a-radio>
                </div>
                <div v-show="twoLossReason === '系统问题'">
                  <a-radio value="上课软件">上课软件</a-radio>
                  <a-radio value="App问题">App问题</a-radio>
                  <a-radio value="小程序问题">小程序问题</a-radio>
                  <a-radio value="支付方式">支付方式</a-radio>
                </div>
                <div v-show="twoLossReason === '服务'">
                  <a-radio value="学习规划师过度承诺">学习规划师过度承诺</a-radio>
                  <a-radio value="学习规划师虚假宣传">学习规划师虚假宣传</a-radio>
                  <a-radio value="学习规划师新生交接">学习规划师新生交接</a-radio>
                  <a-radio value="班主任过度承诺">班主任过度承诺</a-radio>
                </div>
                <div v-show="twoLossReason === '规则'">
                  <a-radio value="请假机制">请假机制</a-radio>
                  <a-radio value="分享赠课机制">分享赠课机制</a-radio>
                  <a-radio value="课时课件机制">课时课件机制</a-radio>
                  <a-radio value="班型&班级人数">班型&班级人数</a-radio>
                  <a-radio value="课节时长">课节时长</a-radio>
                </div>
                <div v-show="twoLossReason === '课程设置其他'">
                  <a-radio value="教学应试体系不匹配">教学应试体系不匹配 </a-radio>
                  <a-radio value="课程难易度不匹配">课程难易度不匹配</a-radio>
                  <a-radio value="字体问题">字体问题</a-radio>
                </div>
                <div v-show="twoLossReason === '家长拒绝进一步沟通'">
                  <a-radio value="家长拒绝进一步沟通">家长拒绝进一步沟通 </a-radio>
                </div>
                <div v-show="twoLossReason === '其他'">
                  <a-radio value="已有三级原因无法选择">已有三级原因无法选择 </a-radio>
                </div>
              </a-radio-group>
            </div>
          </div>
        </div>

        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'"
          :class="{ requireStyle: errMap.parentsCare }"
        >
          <div class="list-item">
            <div class="list-label" style="min-width: 100px">家长在意点：</div>
            <div class="list-content">
              <a-radio-group v-model="parentsCare" @change="(val) => changeErr(!val.target.value, 'parentsCare')">
                <a-radio value="价格活动">价格活动</a-radio>
                <a-radio value="学员兴趣">学员兴趣</a-radio>
                <a-radio value="学员时间">学员时间</a-radio>
                <a-radio value="服务-学校规则">服务-学校规则</a-radio>
                <a-radio value="服务-家长感受或学员学习">服务-家长感受或学员学习</a-radio>
                <a-radio value="学习侧重效果">学习侧重效果</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'"
          :class="{ requireStyle: errMap.resumeClassesLoss }"
        >
          <div class="list-item">
            <div class="list-label">学员复课意向：</div>
            <div class="list-content">
              <a-radio-group
                v-model="resumeClassesLoss"
                @change="(val) => changeErr(!val.target.value, 'resumeClassesLoss')"
              >
                <a-radio value="高">高</a-radio>
                <a-radio value="中">中</a-radio>
                <a-radio value="低">低</a-radio>
                <a-radio value="无">无</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'">
          <a-textarea
            v-decorator="[
              'description',
              {
                rules: [{ required: true, message: '请填写' }],
              },
            ]"
            :rows="4"
            placeholder="请输入"
            @blur="changeResult"
          />
        </a-form-item>

        <!--一家多娃任务-->
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD'"
          :class="{ requireStyle: errMap.existChild }"
        >
          <div class="list-item">
            <div class="list-label">是否存在多娃：</div>
            <div class="list-content">
              <a-radio-group v-model="existChild" @change="(val) => changeErr(!val.target.value, 'existChild')">
                <a-radio value="是">是</a-radio>
                <a-radio value="否"> 否</a-radio>
                <a-radio value="未回复"> 未回复</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD' && existChild === '未回复'"
          :class="{ requireStyle: errMap.followDay }"
        >
          <div class="list-item">
            <div class="list-label">是否至少跟进3天：</div>
            <div class="list-content">
              <a-checkbox-group v-model="followDay" @change="(val) => changeErr(!val.length, 'followDay')">
                <a-checkbox value="是">是</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD' && existChild === '是'"
          :class="{ requireStyle: errMap.agreeRegister }"
        >
          <div class="list-item">
            <div class="list-label">是否同意注册：</div>
            <div class="list-content">
              <a-radio-group v-model="agreeRegister" @change="(val) => changeErr(!val.target.value, 'agreeRegister')">
                <a-radio value="同意">同意</a-radio>
                <a-radio value="不同意"> 不同意</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD' && agreeRegister === '同意' && existChild === '是'"
          :class="{ requireStyle: errMap.registerSubject }"
        >
          <div class="list-item">
            <div class="list-label">同意注册科目：</div>
            <div class="list-content">
              <a-checkbox-group v-model="registerSubject" @change="(val) => changeErr(!val.length, 'registerSubject')">
                <a-checkbox value="中文课">中文课</a-checkbox>
                <a-checkbox value="数学课">数学课</a-checkbox>
                <a-checkbox value="英文课">英文课</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD' && agreeRegister === '不同意' && existChild === '是'"
          :class="{ requireStyle: errMap.chineseReason }"
        >
          <div class="list-item">
            <div class="list-label" style="width: 210px">中文课不注册原因：</div>
            <div class="list-content">
              <a-radio-group v-model="chineseReason" @change="(val) => changeErr(!val.target.value, 'chineseReason')">
                <a-radio value="线上已有课">线上已有课</a-radio>
                <a-radio value="线下已有课"> 线下已有课</a-radio>
                <a-radio value="无学习诉求">无学习诉求</a-radio>
                <a-radio value="课程内容不符合需求">课程内容不符合需求</a-radio>
                <a-radio value="已有线索/二娃再读">已有线索/二娃再读</a-radio>
                <a-radio value="不符合入学条件">不符合入学条件</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD' && agreeRegister === '不同意' && existChild === '是'"
          :class="{ requireStyle: errMap.mathReason }"
        >
          <div class="list-item">
            <div class="list-label" style="width: 210px">数学课不注册原因：</div>
            <div class="list-content">
              <a-radio-group v-model="mathReason" @change="(val) => changeErr(!val.target.value, 'mathReason')">
                <a-radio value="线上已有课">线上已有课</a-radio>
                <a-radio value="线下已有课"> 线下已有课</a-radio>
                <a-radio value="无学习诉求">无学习诉求</a-radio>
                <a-radio value="课程内容不符合需求">课程内容不符合需求</a-radio>
                <a-radio value="已有线索/二娃再读">已有线索/二娃再读</a-radio>
                <a-radio value="不符合入学条件">不符合入学条件</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div
          class="list-itemWrap"
          v-if="taskType === 'TASK_STUDENT_MULTI_CHILD' && agreeRegister === '不同意' && existChild === '是'"
          :class="{ requireStyle: errMap.englishReason }"
        >
          <div class="list-item">
            <div class="list-label" style="width: 210px">英文课不注册原因：</div>
            <div class="list-content">
              <a-radio-group v-model="englishReason" @change="(val) => changeErr(!val.target.value, 'englishReason')">
                <a-radio value="线上已有课">线上已有课</a-radio>
                <a-radio value="线下已有课"> 线下已有课</a-radio>
                <a-radio value="无学习诉求">无学习诉求</a-radio>
                <a-radio value="课程内容不符合需求">课程内容不符合需求</a-radio>
                <a-radio value="已有线索/二娃再读">已有线索/二娃再读</a-radio>
                <a-radio value="不符合入学条件">不符合入学条件</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <a-form-item label="备注" v-if="taskType === 'TASK_STUDENT_MULTI_CHILD'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" @blur="changeResult" />
        </a-form-item>
        <!--学员跟进-->
        <a-form-item label="备注" v-if="taskType === 'TASK_NEXT_FOLLOW_RECORD'">
          <a-textarea
            v-decorator="['description', { rules: [{ required: true, message: '请填写备注' }] }]"
            :rows="4"
            placeholder="请输入"
            @blur="changeResult"
          />
        </a-form-item>
        <a-form-item label="挽单结果" v-if="taskType === 'ATTEMPT_RETENTION_ON_CANCELLATION'">
          <a-select
            v-decorator="['communicateResult', { rules: [{ required: true, message: '请选择挽单结果' }] }]"
            placeholder="请选择"
          >
            <a-select-option value="SUCCESS">成功</a-select-option>
            <a-select-option value="FAILD">失败</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="上传图片">
          <a-upload
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            v-decorator="['imageUrl']"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="备注" v-if="taskType === 'ATTEMPT_RETENTION_ON_CANCELLATION'">
          <a-textarea v-decorator="['description']" :rows="4" placeholder="请输入" />
        </a-form-item>

        <div style="padding-left: 8px; margin-bottom: 10px">
          <span
            class="operation-btn"
            @click="showFollowFun"
            v-show="!showFollow && !(form.getFieldValue('communicateResult') === 'FAILD')"
            >+ 下次跟进任务</span
          >
        </div>
        <a-form-item label="下次跟进时间" v-if="showFollow && !(form.getFieldValue('communicateResult') === 'FAILD')">
          <a-date-picker
            style="width: 100%"
            :disabled-date="disabledDate"
            :disabled-time="disabledDateTime"
            v-decorator="['nextFollowDate', { rules: [{ required: showFollow, message: '请选择下次跟进时间' }] }]"
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            @blur="changeResult"
          />
        </a-form-item>
        <a-form-item label="下次跟进描述" v-if="showFollow && !(form.getFieldValue('communicateResult') === 'FAILD')">
          <a-textarea
            v-decorator="['nextFollowDesc', { rules: [{ required: showFollow, message: '请填写下次跟进描述' }] }]"
            :rows="4"
            placeholder="请输入"
            @blur="changeResult"
          />
        </a-form-item>
        <div
          style="padding-left: 32px; font-size: 12px; margin-bottom: 20px"
          v-show="showFollow && !(form.getFieldValue('communicateResult') === 'FAILD')"
        >
          提交后将会生成【学员跟进】任务，可<span style="color: #04cb94; cursor: pointer" @click="cancelFollowFun">
            取消下次跟进</span
          >
        </div>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <!-- <div class="finish-task" v-if="showFinishFlag && taskType !== 'TASK_STUDENT_ACTIVE'">
          <a-checkbox v-model="checked" @change="onChange" :disabled="disabledTask"> 同时完成任务 </a-checkbox>
        </div> -->
        <a-button :style="{ marginRight: '8px' }" @click="onClose(0)"> 取消 </a-button>
        <a-button type="primary" :loading="submitLoading" @click="handleSubmit"> 完成并提交 </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="showLossRefundFlag"
      title="操作提示"
      okText="去添加"
      cancelText="关闭"
      @ok="hrefLostRefundStudent"
      @cancel="cancelLostRefundStudent"
    >
      <div>
        请先添加该学员<span style="color: #04cb94">{{ `${taskObj.studentName}(${taskObj.studentCode})` }}</span
        >的{{ initOptionsType(taskObj.subject, subjectArr) }}【流失记录】，才可完成该任务
      </div>
    </a-modal>
    <!--学员激活里面的完成-->

    <a-modal
      width="550px"
      title="完成任务"
      :visible="visibleActive"
      @ok="saveActive"
      @cancel="cancelActive"
      okText="提交"
      cancelText="取消"
    >
      <a-form :form="activeForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="冻结/延期结束时间">
          <a-range-picker
            v-decorator="['queryActiveDateArr', { rules: [{ required: true, message: '请选择延期/冻结时间!' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="补充说明">
          <a-textarea placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 5 }" v-decorator="['remark']" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import storage from 'store';
import { loadScheduleOptions } from '@/api/schedule';
import { imgpostUrl } from '@/api/register';

import {
  queryFormType,
  submitRecord,
  cacheFollowRecord,
  getCacheFollowRecord,
  getRecordStudent,
  getHeadmasterList,
  searchTeacherName,
  getUploadOssToken,
  queryScreenOption,
  queryLossRefundCount,
  queryConfirmFistDate,
  studentReminderActive,
  confirmFirstLesson,
  unSubscribeOnWinback,
  getStudentInfoValidate,
} from '@/api/headTeacher';
import { Modal } from 'ant-design-vue';

export default {
  data() {
    return {
      // 未选课原因
      nonAttendanceReasonOptions: [
        {
          label: '对课程不感兴趣',
          value: '对课程不感兴趣',
        },
        {
          label: '课程价格太高',
          value: '课程价格太高',
        },
        {
          label: '上课时间不匹配',
          value: '上课时间不匹配',
        },
        {
          label: '未联系上家长',
          value: '未联系上家长',
        },
      ],
      // 选课结果
      bookClassResultOptions: [
        {
          label: '成功',
          value: '成功',
        },
        {
          label: '失败',
          value: '失败',
        },
      ],
      activeForm: this.$form.createForm(this),
      activeParams: {},
      visibleActive: false,
      // 长期交接后首课回访
      underNewTeacher: '',
      feedbackOver: [],
      // 周阶段回访任务
      followupResultsWeek: '',
      subjectEnd: '',
      // 约课任务
      isAuditionCourse: '',
      isAppointmentClass: '',
      isAppointmentReason: '',
      followDayCourse: [],
      // 拓科任务，线索创建
      isAudition: '',
      followDayClue: [],
      isAuditionReason: '',
      // 流失首通
      threeLossReason: '',
      resumeClassesLoss: '',
      oneLossReason: '',
      parentsCare: '',
      twoLossReason: '',
      // 不续费
      isExamination: '',
      // 学员激活
      isResumeClasses: '',
      // 结业证书
      followResultsCertificate: '',
      // 多娃任务
      chineseReason: '',
      mathReason: '',
      englishReason: '',
      registerSubject: [],
      agreeRegister: '',
      followDay: [],
      existChild: '',
      // 老师交接后首课回访
      icebreakerClass: '',
      classCommunicationVisit: '',
      classCommunicationVisitReason: '',
      introduceAndInvite: '',
      feedbackVisit: [],
      underTeacherVisit: '',
      // 班主任交接学员首通
      checkAcademicSituation: '',
      confirmCourseScheduling: [],
      confirmClassTime: [],
      confirmIssues: '',
      // 院班主任交接
      studentPortrait: [],
      parentPortrait: [],
      academicSituation: [],
      sharingEngagement: '',
      solveProblems: [],
      // 时令变化
      seasonalChanges: '',
      qaHeadmasterList: [],
      showConfirmFistDate: false,
      // 学员缺勤
      absenteeismReasons: '',
      lessonArrangement: '',
      courseReplayAbsent: [],
      // 学习报告
      studyReport: [],
      followupResultsReport: '',
      roleFLag: '',
      // 阶段回访任务
      followupResultsTerm: '',
      studyTerm: [],

      // 质检跟进

      qaType: '',
      qaOrigin: '',
      qaDetermine: '',
      // 日常跟进
      followTypeSub: '',
      subject: '',
      submitLoadingSub: false,
      // 老师迟到
      reverseClassHours: [],
      makeUpClasses: '',
      viewPlayback: [],
      // 学习回访
      followUpResults: '',
      // 新生补升
      isMakeUp: [],
      oneMakeUpReason: '',
      twoMakeUpReason: '',
      threeMakeUpReason: '',
      // w3回访、
      studentPerformance: [],
      upgradePlan: [],
      // 首课回访
      icebreaker: '',
      classCommunication: '',
      showCommunication: false,
      feedback: [],
      understandingProgress: '',
      showLevel: false,
      understandingTeacher: '',
      showParentFeedback: false,
      reviewHomework: [],
      parentExpectationManagement: [],
      communicationPreferences: [],
      sharingRules: [],
      classCommunicationReason: '',
      parentFeedback: '',
      // 新生首通
      nicknameAndBirth: [],
      courseInfo: [],
      classArrangement: [],
      appDownload: [],
      appIntroduction: [],
      selfBookingConfirm: '',
      studyPlan: [],
      admissionRights: [],
      needCancelCourse: '',
      paymentCommunicationResult: '',
      paymentFailReason: '',
      errMap: {
        introduceAndInvite: false,
        // 长期交接后首课回访
        underNewTeacher: false,
        feedbackOver: false,
        // 约课任务
        isAuditionCourse: false,
        isAppointmentClass: false,
        isAppointmentReason: false,
        followDayCourse: false,
        // 拓科任务、线索创建
        isAudition: false,
        followDayClue: false,
        isAuditionReason: false,
        // 流失首通
        resumeClassesLoss: false,
        oneLossReason: false,
        parentsCare: false,
        twoLossReason: false,
        threeLossReason: false,
        // 学员激活
        isResumeClasses: false,
        // 多娃任务
        chineseReason: false,
        mathReason: false,
        englishReason: false,
        registerSubject: false,
        agreeRegister: false,
        followDay: false,
        existChild: false,
        // 老师交接后首课回访
        icebreakerClass: false,
        classCommunicationVisit: false,
        classCommunicationVisitReason: false,
        underTeacherVisit: false,
        feedbackVisit: false,
        // 班主任交接学员首通
        checkAcademicSituation: false,
        confirmCourseScheduling: false,
        confirmIssues: false,
        confirmClassTime: false,
        // 原班主任交接学员首通
        studentPortrait: false,
        parentPortrait: false,
        academicSituation: false,
        // 时令变化
        seasonalChanges: false,
        // 学员缺勤
        absenteeismReasons: false,
        lessonArrangement: false,
        courseReplayAbsent: false,
        // 学习报告
        studyReport: false,
        followupResultsReport: false,
        // 阶段回访任务
        studyTerm: false,
        followupResultsTerm: false,
        // 周阶段回访任务
        followupResultsWeek: false,
        // 日常跟进
        followTypeSub: false,
        subject: false,

        // 质检跟进

        qaType: false,
        qaOrigin: false,
        qaDetermine: false,

        // 用来校验必填

        methodType: false,

        // 老师迟到
        reverseClassHours: false,
        makeUpClasses: false,
        viewPlayback: false,
        // 学习回访
        followUpResults: false,
        // 新生补升
        isMakeUp: false,
        oneMakeUpReason: false,
        twoMakeUpReason: false,
        threeMakeUpReason: false,
        // 首课回访
        icebreaker: false,
        classCommunication: false,
        classCommunicationReason: false,
        understandingProgress: false,
        understandingTeacher: false,
        reviewHomework: false,
        parentExpectationManagement: false,
        communicationPreferences: false,
        feedback: false,
        sharingRules: false,
        // 新生首通
        nicknameAndBirth: false,
        courseInfo: false,
        classArrangement: false,
        appDownload: false,
        appIntroduction: false,
        // w3回访
        studentPerformance: false,
        upgradePlan: false,
        needCancelCourse: false,
        paymentCommunicationResult: false,
        paymentFailReason: false,
      },

      methodType: [],
      // 旧的
      operationReminderArr: [
        '准时完成，且正确率高',
        '准时完成，正确率待提升',
        '偶尔提交/未完成，已提醒按时完成',
        '从不完成作业，已提醒按时完成',
      ],
      disabledTask: false,
      requireDesc: true,
      followResultArr: ['家长有回复', '家长未回复'],
      followLabel: '任务类型',
      // 一家多娃
      showAgreeFlag: false,
      isAgreeReasonArr: [
        '学员原因-学员时间紧张',
        '学员原因-无中文/数学补习需求',
        '学员原因-已有线下中文/数学课',
        '学员原因-授课语言不匹配',
        '学员原因-已有Online中文/数学课',
        '学员原因-不符合入学条件（如年龄等）',
        '课程产品-可试听时间不适合',
        '课程产品-不认可班型/人数等',
        '课程产品-课程体系不匹配',
        '学员原因-已有线索',
        '学员原因-二娃已在读',
        '其他-请在“跟进备注”填写相关原因',
      ],
      isAgreeAuditionArr: ['同意', '不同意'],
      isChildSubjectArr: ['中文', '数学', '2科均拓'],
      showChildFlag: false,
      isChildArr: ['是', '否'],
      isResponsibleArr: ['是', '否'],

      showQAFlagReason: false,
      showQAFlag: false,
      threeResponsibleArr: ['是（请修改为正确原因）', '否'],
      responsibleReasonArr: [
        '家长异议未解决',
        '未按照任务跟进回访',
        '中文、数学续费谈单≥2次',
        '班主任/辅导老师因服务态度导致家长不续费',
        '其他原因：请补充',
      ],
      isResponsibleArr: ['是', '否'],
      examinationArr: ['是', '否'],
      showLossRefundFlag: false,
      reasonTwoOptions: [],
      reasonThreeOptions: [],
      optionsArr: {},
      showMakeUpFlag: false,
      teacherFirstTaskArr: [
        '完成课前破冰；完成家长沟通',
        '未做课前破冰；完成家长沟通',
        '完成课前破冰；未做家长沟通',
        '未做课前破冰；未做家长沟通',
      ],
      teacherTaskArr: [
        '完成欢迎学员；完成家长沟通',
        '未做欢迎学员；完成家长沟通',
        '完成欢迎学员；未做家长沟通',
        '未做欢迎学员；未做家长沟通',
      ],
      showImage: false,
      fileList: [],
      showFollow: false,
      nextStuFirstFollowRecord: false,
      resumeClassesArr: ['高', '中', '低', '无'],
      parentsCareArr: [
        '价格活动',
        '学员兴趣',
        '学员时间',
        '规则-分享赠课',
        '服务-家长感受',
        '服务-学员学习',
        '学习效果-书写',
        '学习效果-认字',
        '学习效果-听说',
        '学习效果-作文',
        '学习效果-作业',
        '学习效果-阅读',
      ],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      // 每月回访
      isResumeClassesArr: [{ label: '是' }, { label: '未告知' }],
      showResumeClasses: false,
      // 阶段回访任务
      showLevelFeedback: false,
      feedBackTermArr: [{ label: '有' }, { label: '无' }],
      operationTermReminderArr: [
        '准时完成，且正确率高',
        '准时完成，正确率待提升',
        '偶尔提交/未完成，已提醒按时完成',
        '从不完成作业，已提醒按时完成',
      ],
      shareTermRecommendationsArr: [{ label: '是' }],
      // 学习报告
      shareReportArr: [{ label: '是' }],
      isParentFeedbackArr: [{ label: '有' }, { label: '无' }],
      isMailingInfoArr: [{ label: '是' }, { label: '否' }],
      isSendReportArr: [{ label: '已发送' }, { label: '未发送' }],
      showParentFeedback: false,
      // 新生补升
      makeUpArr: [{ label: '已补升' }, { label: '放弃补升权益' }],
      parentObjectionArr: [{ label: '价格' }, { label: '学习效果' }, { label: '无异议' }, { label: '其他' }],
      // 不续费
      scheduleOptions: {
        lossRefundReason: {
          otherLossReasons: [],
        },
      },
      lossDirectionArr: [
        '转lingoace',
        '转其他线上直播课',
        '转其他线上录播课',
        '转线下机构',
        '转线下学校',
        '家长自己教学',
        '未知，请补充',
      ],
      isRenewalArr: [{ label: '是' }, { label: '否' }],
      showRenewalReason: false,
      cancelCourseArr: [{ label: '已取消' }, { label: '未取消' }],
      clearQuotaArr: [{ label: '已清空' }, { label: '未清空' }],
      isSendApprovalArr: [{ label: '已发起' }, { label: '未发起' }],
      showSendApproval: false,
      // 结业证书
      feedbackArr: [{ label: '有' }, { label: '无' }],

      shareRecommendationsArr: [{ label: '是' }, { label: '否,在跟进描述中补充说明原因' }],
      showFeedback: false,
      // 约课任务
      showAppointment: false,
      noAuditionResultArr: [
        { label: '学员原因-学员时间紧张' },
        { label: '学员原因-无数学补习需求' },
        { label: '学员原因-已有线下数学课' },
        { label: '学员原因-授课语言不匹配' },
        { label: '学员原因-已有Online数学课' },
        { label: '课程产品-可试听时间不适合' },
        { label: '课程产品-不认可1对多班型' },
        { label: '课程产品-想学启蒙基础课' },
      ],
      showAudition: false,
      showAppointmentResult: false,
      appointmentResultArr: [{ label: '成功' }, { label: '失败' }],
      noAppointmentResultArr: [
        { label: '学员原因-学员时间紧张' },
        { label: '学员原因-无数学补习需求' },
        { label: '学员原因-已有线下数学课' },
        { label: '学员原因-已有Online数学课' },
        { label: '学员原因-学员年龄不符合' },
        { label: '学员原因-授课语言不匹配' },
        { label: '课程产品-可试听时间不适合' },
        { label: '课程产品-不认可1对多班型' },
        { label: '课程产品-想学启蒙基础课' },
      ],
      // 授课老师旷课
      makeUpLessonsArr: [{ label: '已安排补课' }, { label: '家长反馈无需补课，顺延即可' }, { label: '家长未回复' }],
      absenteeismArr: [
        { label: '忘记上课、忘记请假' },
        { label: '操作系统无法登陆' },
        { label: '停电、断网、电脑故障' },
        { label: '突发原因：停电、生病、断网等' },
        { label: '其他' },
      ],
      showAbsent: false,
      teacherNameList: [],

      // 原班主任交接学生
      showShareReason: false,
      shareParticipationArr: [{ label: '积极参与' }, { label: '一般，不积极' }, { label: '不参与，很排斥' }],
      classroomParticipationArr: [
        { label: '高，乐于表达' },
        { label: '中，需要老师引导' },
        { label: '低，基本不参与互动' },
      ],
      teachingServiceArr: [{ label: '已更新完善教学期望' }, { label: '已更新完善服务信息' }],
      // 交接班后首课回访
      firstLessonFeelArr1: [
        { label: '对新老师满意，交接成功', value: 'firstLessonFeel1' },
        { label: '对老师不满意，需更换老师', value: 'firstLessonFeel2' },
      ], // 首课感受
      // 质检跟进
      rewardScoreArr: [
        '-10',
        '-9',
        '-8',
        '-7',
        '-6',
        '-5',
        '-4',
        '-3',
        '-2',
        '-1',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
      ],
      headmasterList: [],
      qualityAccountArr: [
        { label: '中文', value: 'qualityAccount1' },
        { label: 'Math', value: 'qualityAccount2' },
      ],
      qualityTypeArr: [
        { label: '投诉', value: 'qualityType1' },
        { label: '流失', value: 'qualityType2' },
        { label: '质检', value: 'qualityType3' },
        { label: '优秀案例', value: 'qualityType4' },
        { label: '其他', value: 'qualityType5' },
      ],
      qualitySourceArr: [
        { label: '质检抽检', value: 'qualitySource1' },
        { label: '约课顾问', value: 'qualitySource2' },
        { label: '学习规划师', value: 'qualitySource3' },
        { label: '教学部', value: 'qualitySource4' },
        { label: '客服渠道', value: 'qualitySource5' },
      ],
      qualityDetermineArr: [
        { label: '奖励加分', value: 'qualityDetermine1' },
        { label: '有责扣罚', value: 'qualityDetermine2' },
        { label: '无责不扣罚', value: 'qualityDetermine3' },
      ],
      rewardRecords: '',

      // 课时转移
      discountSituationArr: [
        { label: '待转移课时无折扣', value: 'discountSituation1' },
        { label: '待转移课时有折扣', value: 'discountSituation2' },
      ],
      studentList: [],
      transferTypeArr: [
        { label: '中文课时不同学员互转', value: 'transferType1' },
        { label: 'Math课时不同学员互转', value: 'transferType2' },
        { label: '中文转移数学', value: 'transferType3' },
        { label: '数学转移中文', value: 'transferType4' },
      ], // 转移类型
      // 班主任交接学员首通
      remainingProblemsArr: [
        { label: '无遗留问题', value: 'remainingProblems1' },
        { label: '有遗留问题待处理（请在跟进描述中详细备注）', value: 'remainingProblems2' },
      ], // 遗留问题
      identityIntroductionArr: [
        { label: '已介绍', value: 'identity1' },
        // { label: '暂未，在跟进描述中补充说明原因', value: 'identity2' },
      ], // 身份介绍
      checkLearningSituationArr: [
        { label: '和系统信息一致', value: 'checkLearningSituation1' },
        { label: '不一致，请描述需补充的内容', value: 'checkLearningSituation2' },
      ], // 核对学情
      // 每月回访
      showAttendance: false,
      attendanceArr: [
        { label: '准时出席', value: 'attendance1' },
        { label: '偶尔缺席/迟到', value: 'attendance2' },
        { label: '高频缺席/迟到', value: 'attendance3' },
      ], // 出勤次数
      homeworkAfterClassArr: [
        { label: '超80%完成，优秀', value: 'homeworkAfterClass1' },
        { label: '80%～50%完成，良好', value: 'homeworkAfterClass2' },
        { label: '低于50%，需关注（需在备注中记录沟通结果）', value: 'homeworkAfterClass3' },
      ],
      // W3回访
      promotionIntentionArr: [
        { label: '低', value: 'promotionIntention1' },
        { label: '中', value: 'promotionIntention2' },
        { label: '高', value: 'promotionIntention3' },
      ], // 补升意向
      promotionObjectionArr: [
        { label: '学习效果不理想', value: 'promotionObjection1' },
        { label: '价格不划算', value: 'promotionObjection2' },
        { label: '授课老师不满意', value: 'promotionObjection3' },
        { label: '消费观保守', value: 'promotionObjection4' },
        { label: '其他（请在跟进描述中做好备注）', value: 'promotionObjection5' },
      ], // 补升异议
      showReason: false,
      jobCompletionArr: [
        { label: '准时完成', value: 'jobCompletion1' },
        { label: '偶尔未提交/未完成', value: 'jobCompletion2' },
        { label: '从不完成作业', value: 'jobCompletion3' },
      ], // 作业完成度

      classroomPerformanceArr: [
        { label: '高，乐于表达', value: 'classroomPerformance1' },
        { label: '中，需要老师引导', value: 'classroomPerformance2' },
        { label: '低，基本不参与互动', value: 'classroomPerformance3' },
      ], // 课堂表现
      // 首课回访
      shareRecommendationArr: [
        { label: '已推广，转介绍活动', value: 'shareRecommendation1' },
        { label: '未推广，在跟进描述中补充说明原因', value: 'shareRecommendation2' },
      ], // 分享推荐
      sendConfirmationArr: [
        { label: '已确认，并安排寄送', value: 'sendConfirmation1' },
        { label: '调整级别，暂缓寄送', value: 'sendConfirmation2' },
        { label: '无需寄送', value: 'sendConfirmation2' },
      ], // 寄送确认
      jobReminderArr: [
        { label: '作业提交方法已同步，已提醒', value: 'jobReminder1' },
        // { label: '暂未，在跟进描述中补充说明原因', value: 'jobReminder2' },
      ], // 作业提醒
      levelProgressArr: [
        { label: '当前级别合适，不需要调整', value: 'levelProgress1' },
        { label: '当前级别不合适，需调整，在跟进描述中补充说明原因', value: 'levelProgress2' },
      ], // 级别进度
      firstLessonFeelArr: [
        { label: '对课程内容不满意，已提交反馈', value: 'firstLessonFeel1' },
        { label: '对授课老师不满意，已提交反馈', value: 'firstLessonFeel2' },
        { label: '均不满意，已提交反馈', value: 'firstLessonFeel3' },
        { label: '均满意，无问题', value: 'firstLessonFeel4' },
      ], // 首课感受
      instructorArr: [{ label: '魏老师', value: 'teacher1' }], // 授课老师
      // 新生首通
      studyPlanArr: [
        { label: '班型（私教课、班课）已核对', value: 'plan1' },
        { label: '入学级别已核对', value: 'plan2' },
        { label: '上课频率（每周）已核对', value: 'plan3' },
        { label: '学员可排课时间已核对', value: 'plan4' },
      ],

      serviceInformationArr: [
        {
          label:
            '已确认（学习主要负责人手机号为海外、邮箱&APP账号、沟通方式偏好（服务群文字or语音电话or邮件）、上课账号、上课提醒）',
        },
      ], // 服务信息
      admissionSituationArr: [
        { label: '已介绍（悟空学员中心App/Web介绍、引导下载App、新生补升权益、24H在线客服介绍-App IM功能 ）' },
      ], // 入学学情
      newbornArr: [{ label: '已确认（课包信息、分享额度及规则、课时扣减、请假规则、退款权益）' }], // 新生权益
      admissionPackageArr: [
        { label: '已核对入学课时，含购买课时、购买赠课', value: 'package1' },
        { label: '已核对分享赠课额度及规则', value: 'package2' },
        { label: '暂未，在跟进描述中补充说明原因', value: 'package3' },
      ], // 入学课包
      studentInformationArr: [{ label: '已核对（姓名、出生年月、拼音学习史（中文）、学习经历和期望）' }], // 学生信息
      identityArr: [
        { label: '已介绍', value: 'Introduced' },
        // { label: '暂未，在跟进描述中补充说明原因', value: 'NoIntroduced' },
      ], // 身份职责，新生首通才有
      taskType: '', // 任务类型
      changeFlag: false, // 是否变更了跟进记录
      checked: false,
      showflag: false,
      showFinishFlag: false,
      submitTxt: '提交',
      submitLoading: false,
      userInfo: {},
      form: this.$form.createForm(this),
      followTypeArr: [],
      methodTypesArr: ['电话', '花果IM', '微信', 'Email', '其它'],
      phoneTypesArr: ['成功接通', '电话未接听、占线、无人接听', '错误号码、电话无法接通', '电话留言', '其它'],
      recordResultTypesArr: ['未报价', '已报价', '已拒绝', '已成交'],
      intentionTypesArr: ['高', '中', '低'],
      stateTypesArr: ['完成', '开启'],
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      },
    };
  },
  props: {
    visible: Boolean,
    taskObj: {
      type: Object,
      required: true,
    },
    type: {
      // 标明从哪里打开的跟进记录
      type: String,
    },
  },
  computed: {
    ...mapState({
      currentUserAvatar: (state) => state.userIM.currentUserAvatar,
      currentUserProfile: (state) => state.userIM.currentUserProfile,
    }),
    methodTypeOptions() {
      const optionKeys = {
        Zoom外呼: 'Zoom外呼',
        微信: '微信',
        微信电话: '微信电话',
        Email: 'Email',
        花果IM: '花果IM',
        其他: '其他',
        WhatsApp: 'WhatsApp',
        学员中心: '学员中心',
      };
      const makeOptions = (keys) => keys?.map((item) => ({ label: item, value: item }));
      const defaultOptions = [
        optionKeys.微信,
        optionKeys.微信电话,
        optionKeys.Zoom外呼,
        optionKeys.Email,
        optionKeys.花果IM,
        optionKeys.其他,
      ];
      const options = {
        ATTEMPT_RETENTION_ON_CANCELLATION: [
          optionKeys.WhatsApp,
          optionKeys.Zoom外呼,
          optionKeys.Email,
          optionKeys.花果IM,
          optionKeys.微信,
          optionKeys.其他,
        ],
        DAYLIGHT_TIME_CHANGED: [
          optionKeys.微信,
          optionKeys.微信电话,
          optionKeys.Zoom外呼,
          optionKeys.Email,
          optionKeys.花果IM,
          optionKeys.学员中心,
          optionKeys.其他,
        ],
      };
      if (Object.hasOwn(options, this.taskType)) {
        return makeOptions(options[this.taskType]);
      }
      return makeOptions(defaultOptions);
    },
  },

  methods: {
    moment,
    async validateHandoverStudentOldAdmin() {
      const res = await getStudentInfoValidate(this.taskObj.studentId);
      const {
        chineseOtherLearningInfoFinish,
        chineseTeachingExpectationsFinish,
        familyProfileFinish,
        studentProfileFinish,
      } = res.data;

      this.errMap.studentPortrait = !studentProfileFinish;
      this.errMap.parentPortrait = !familyProfileFinish;
      this.errMap.academicSituation = !chineseOtherLearningInfoFinish || !chineseTeachingExpectationsFinish;
      if (this.errMap.studentPortrait || this.errMap.studentPortrait || this.errMap.studentPortrait) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    goStudentDetailServiceTab() {
      const router = this.$router.resolve({
        path: '/studentDetail',
        query: {
          uuid: this.taskObj.studentId,
          type: 'service',
          action: 'edit',
        },
      });
      window.open(router.href, '_blank');
    },
    goStudentDetailSituationTab() {
      const router = this.$router.resolve({
        path: '/studentDetail',
        query: {
          uuid: this.taskObj.studentId,
          type: 'situation',
          action: 'edit',
        },
      });
      window.open(router.href, '_blank');
    },
    handleResultChange(value) {
      this.bookClassResult = value;
    },
    changeCommunicateResult(e) {
      this.changeResult();
      this.nextStuFirstFollowRecord = e === 'FAILD';
    },
    queryStudentReminderActive(objParams) {
      this.activeParams = { ...objParams };

      // 学员激活要单独判断完成
      const params = {
        studentId: this.taskObj.studentId,
      };
      studentReminderActive(params).then((res) => {
        if (res.data.content) {
          this.activeForm.resetFields();
          this.visibleActive = true;
        } else {
          this.submitRecord(objParams);
        }
      });
    },
    cancelActive() {
      this.visibleActive = false;
      this.submitLoading = false;
    },
    saveActive(e) {
      e.preventDefault();
      this.activeForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...this.activeParams,
            message: values.remark,
            freeze: {
              freezeFromDate: values.queryActiveDateArr[0].format('YYYY-MM-DD'), // 冻结开始时间
              freezeToDate: values.queryActiveDateArr[1].format('YYYY-MM-DD'), // 冻结结束时间
            },
          };

          this.submitRecord(params);
        }
      });
    },
    changeTwoLossReason(val) {
      this.threeLossReason = '';
      this.changeErr(!val, 'twoLossReason');
    },
    changeOneLossReason(val) {
      this.twoLossReason = '';
      this.changeErr(!val, 'oneLossReason');
    },
    changeFollowType(val) {
      this.taskType = val.target.value;
      this.changeErr(!val.target.value, 'followTypeSub');
    },
    changeTwoMakeUpReason(val) {
      this.threeMakeUpReason = '';
      this.changeErr(!val, 'twoMakeUpReason');
    },
    changeOneMakeUpReason(val) {
      this.twoMakeUpReason = '';
      this.changeErr(!val, 'oneMakeUpReason');
    },
    changeStandingTeacher(val) {
      if (val === '不满意') {
        this.showParentFeedback = true;
      } else {
        this.showParentFeedback = false;
      }
      this.changeErr(!val, 'understandingTeacher');
    },
    changeUnderstanding(val) {
      if (val === '不适合，需调整') {
        this.showLevel = true;
      } else {
        this.showLevel = false;
      }
      this.changeErr(!val, 'understandingProgress');
    },
    changeCommunication(val) {
      if (val === '未完成') {
        this.showCommunication = true;
      } else {
        this.showCommunication = false;
      }
      this.changeErr(!val, 'classCommunication');
    },
    changeErr(val, field) {
      // 保存草稿箱
      this.changeResult();
      this.errMap[field] = val;
      return val;
      // this.$set(this.errMap,field,val)
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr.forEach((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    changeFollowResult(val) {
      if (val === '家长未回复') {
        this.disabledTask = true;
        this.checked = false;
      } else {
        this.disabledTask = false;
      }
    },
    changeSubject(val) {
      // 科目变化了，对应的模版也要变化，如果描述默认是一样的，就可以把设置代码去掉

      this.form.setFieldsValue({
        followType: '',
      });
      this._queryFormType(val);
    },
    changeAgreeAudition(val) {
      this.changeResult();
      if (val === '不同意') {
        this.showAgreeFlag = true;
      } else {
        this.showAgreeFlag = false;
      }
    },
    changeChildFun(val) {
      this.changeResult();
      if (val === '是') {
        this.showChildFlag = true;
      } else {
        this.showChildFlag = false;
      }
    },
    reasonTwoChange() {},
    cancelLostRefundStudent() {
      this.showLossRefundFlag = false;
    },
    hrefLostRefundStudent() {
      const routeUrl = this.$router.resolve({
        path: '/administration/lostRefundStudent',
      });
      window.open(routeUrl.href, '_blank');
    },
    changeNoMakeUpReason(val) {
      if (val) {
        this.reasonTwoOptions = [];
        this.reasonThreeOptions = [];
        this.form.setFieldsValue({
          noMakeUpReason2: '',
          noMakeUpReason3: '',
        });
        // 初始化reasonTwoOptions
        let options = [];
        for (const item of this.optionsArr.noLevelUpReason[val]) {
          options = options.concat(Object.keys(item));
        }
        this.reasonTwoOptions = options;
      }
    },
    changeNoMakeUpReasonTwo(val) {
      if (val) {
        this.form.setFieldsValue({
          noMakeUpReason3: '',
        });
        this.reasonThreeOptions = [];
        const reasonOne = this.form.getFieldValue('noMakeUpReason');

        // 初始化reasonThreeOptions
        const arr = this.optionsArr.noLevelUpReason[reasonOne];
        const reasonTwoArr = arr.filter((item) => {
          return Object.keys(item)[0] === val;
        });
        this.reasonThreeOptions = reasonTwoArr[0][val];
      }
    },
    // 获取未补升原因下来项
    queryScreenOption() {
      queryScreenOption().then((res) => {
        this.optionsArr = res.data.content;
      });
    },
    handleChange(info) {
      this.changeResult();
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          //  let transUrl=`${this.transUrlToCDN(res.data.url)}?response-content-type=image/png`

          this.fileList.push({
            uid: res.data.fileId,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
        })
        .finally(() => {});
    },
    transUrlToCDN(value) {
      const [, content] = value.split('//');
      const [, ...paths] = content.split('/');
      return `${[process.env.VUE_APP_CDN_HOST, ...paths].join('/')}`;
    },
    disabledDate(current) {
      const time = moment().endOf('day').subtract(1, 'days');
      return current && current < time;
    },
    rangeHours(start, end, value, chooseday, day) {
      const result = [];
      if (chooseday === day) {
        for (let i = start; i < end; i++) {
          if (i < value) {
            result.push(i);
          }
        }
      }
      return result;
    },
    rangeMinutes(start, end, value, chooseday, day, chooseh, h) {
      const result = [];
      if (chooseday === day && chooseh === h) {
        for (let i = start; i < end; i++) {
          if (i < value) {
            result.push(i);
          }
        }
      }
      return result;
    },
    rangeSeconds(start, end, value, chooseday, day, chooseh, h, choosem, m) {
      const result = [];
      if (chooseday === day && chooseh === h && choosem === m) {
        for (let i = start; i < end; i++) {
          if (i < value) {
            result.push(i);
          }
        }
      }
      return result;
    },
    disabledDateTime(date) {
      // 选中的时间
      const choosetime = moment(date).format('HH-mm-ss');
      const chooseday = parseInt(moment(date).format('YYYY-MM-DD').split('-')[2], 10);
      const chooseh = parseInt(choosetime.split('-')[0], 10);
      const choosem = parseInt(choosetime.split('-')[1], 10);
      // 当前时间
      const nowtime = moment().format('HH-mm-ss');
      const day = parseInt(moment().format('YYYY-MM-DD').split('-')[2], 10);
      const h = parseInt(nowtime.split('-')[0], 10);
      const m = parseInt(nowtime.split('-')[1], 10);
      const s = parseInt(nowtime.split('-')[2], 10);
      return {
        disabledHours: () => this.rangeHours(0, 24, h, chooseday, day),
        disabledMinutes: () => this.rangeMinutes(0, 60, m, chooseday, day, chooseh, h),
        disabledSeconds: () => this.rangeSeconds(0, 60, s, chooseday, day, chooseh, h, choosem, m),
      };
    },
    cancelFollowFun() {
      this.showFollow = false;
    },
    showFollowFun() {
      this.showFollow = true;
    },

    changeResumeClasses(val) {
      this.changeResult();
      if (val === '是') {
        this.showResumeClasses = true;
      } else {
        this.showResumeClasses = false;
      }
    },
    changeSendApproval(val) {
      this.changeResult();
      if (val === '已发起') {
        this.showSendApproval = true;
      } else {
        this.showSendApproval = false;
      }
    },
    changeIsRenewal(val) {
      this.changeResult();
      if (val === '是') {
        this.showRenewalReason = true;
      } else {
        this.showRenewalReason = false;
      }
    },
    changeLevelFeedback(val) {
      this.changeResult();
      if (val === '有') {
        this.showLevelFeedback = true;
      } else {
        this.showLevelFeedback = false;
      }
    },
    changeParentFeedback(val) {
      this.changeResult();
      if (val === '有') {
        this.showParentFeedback = true;
      } else {
        this.showParentFeedback = false;
      }
    },
    changeMakeUp(val) {
      this.changeResult();
      if (val === '放弃补升权益') {
        this.showMakeUpFlag = true;
      } else {
        this.showMakeUpFlag = false;
      }
    },
    changeFeedback(val) {
      if (val === '有') {
        this.showFeedback = true;
      } else {
        this.showFeedback = false;
      }
    },
    changeExamination(val) {
      if (val === '是') {
        this.showQAFlag = true;
      } else {
        this.showQAFlag = false;
      }
    },
    changeResponsible(val) {
      if (val === '是') {
        this.showQAFlagReason = true;
      } else {
        this.showQAFlagReason = false;
      }
    },
    changeAudition(val) {
      if (val === '不同意') {
        this.showAudition = true;
        this.showAppointment = false;
      } else {
        this.showAudition = false;
        this.showAppointment = true;
      }
    },
    changeAppointmentResult(val) {
      if (val === '失败') {
        this.showAppointmentResult = true;
      } else {
        this.showAppointmentResult = false;
      }
    },
    changeShareReason(val) {
      this.changeResult();
      if (val === '一般，不积极') {
        this.showShareReason = true;
      } else {
        this.showShareReason = false;
      }
    },
    searchTeacherName(name) {
      const params = {
        fullName: name,
        subject: this.taskObj.subject,
      };
      searchTeacherName(params).then((res) => {
        this.teacherNameList = res.data.content?.content;
      });
    },
    searchHeadmaster(name) {
      const params = {
        roles: ['CLASS_ADMIN'],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.headmasterList = res.data.content;
      });
    },
    // 质检人员

    searchQaHeadmaster(name) {
      const params = {
        roles: ['CLASS_ADMIN', 'TEACHING_STAFF', 'X_WUKONG', 'TEACHING_STAFF_ADMIN', 'HEAD_TEACHER_SUPER'],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.qaHeadmasterList = res.data.content;
      });
    },
    searchStudentName(name) {
      this.getRecordStudent(name);
    },
    getRecordStudent(name) {
      const params = {
        name,
      };
      getRecordStudent(params).then((res) => {
        if (res.status === 200) {
          this.studentList = res.data?.content?.list;
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    changeAttendance(val) {
      this.changeResult();
      if (val === '偶尔缺席/迟到' || val === '高频缺席/迟到') {
        this.showAttendance = true;
      } else {
        this.showAttendance = false;
      }
    },
    changeAbsenteeism(val) {
      this.changeResult();
      if (val === '其他') {
        this.showAbsent = true;
      } else {
        this.showAbsent = false;
      }
    },
    changeJobCompletion(val) {
      this.changeResult();
      if (val === '偶尔未提交/未完成' || val === '从不完成作业') {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    onChange() {
      this.changeFlag = true;
    },
    // 获取草稿
    getCacheFollowRecord() {
      const params = {
        type: this.type === 'task' ? this.taskObj.type : 'COMMON',
        studentId: this.taskObj.studentId,
        subject: this.taskObj.subject,
      };
      getCacheFollowRecord(params).then((res) => {
        const data = res.data.content;

        // 回填对应的草稿表单
        if (data !== null) {
          let obj = {};
          if (this.type !== 'task') {
            this.taskType = data.taskType;
          }

          if (['SUBSCRIPTION_DEDUCTION_FAILED_TASK'].includes(this.taskType)) {
            this.needCancelCourse = data.needCancelCourse;
            this.paymentCommunicationResult = data.paymentCommunicationResult;
            this.paymentFailReason = data.paymentFailReason;
          } else if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.taskType)) {
            this.nicknameAndBirth = nicknameAndBirth;
            this.courseInfo = courseInfo;
            this.classArrangement = classArrangement;
            this.appDownload = appDownload;
            this.appIntroduction = appIntroduction;
            this.selfBookingConfirm = data.selfBookingConfirm;
            this.form.setFieldsValue({
              communicateResult: data.communicateResult,
            });
            this.$nextTick(() => {
              this.form.setFieldsValue({
                faildReason: data.faildReason,
                nextStuFirstFollowDate: data.nextStuFirstFollowDate,
              });
            });
          } else if (['NEW_STUDENT_FOLLOW_UP', 'TRANSFER_STUDENT_FOLLOW_UP'].includes(this.taskType)) {
            this.introduceAndInvite = data.introduceAndInvite;
          } else if (this.taskType === 'TEACHER_LATE') {
            this.reverseClassHours = data.reverseClassHours;
            this.makeUpClasses = data.makeUpClasses;
            this.viewPlayback = data.viewPlayback;
          } else if (this.taskType === 'TASK_FIRST_VISIT') {
            this.icebreaker = data.icebreaker;
            (this.classCommunication = data.classCommunication),
              (this.understandingProgress = data.understandingProgress);
            this.understandingTeacher = data.understandingTeacher;
            this.classCommunicationReason = data.classCommunicationReason;
            this.reviewHomework = data.reviewHomework;
            this.parentExpectationManagement = data.parentExpectationManagement;
            this.communicationPreferences = data.communicationPreferences;
            this.sharingRules = data.sharingRules;
            this.feedback = data.feedback;

            obj = {
              adjustLevel: data.adjustLevel,
              parentFeedback: data.parentFeedback,
            };
            if (this.understandingTeacher === '不满意') {
              this.showParentFeedback = true;
            } else {
              this.showParentFeedback = false;
            }
            if (this.understandingProgress === '不适合，需调整') {
              this.showLevel = true;
            } else {
              this.showLevel = false;
            }
            if (this.classCommunication === '未完成') {
              this.showCommunication = true;
            } else {
              this.showCommunication = false;
            }
          } else if (this.taskType === 'TASK_W3_VISIT') {
            this.studentPerformance = data.studentPerformance;
            this.upgradePlan = data.upgradePlan;
            obj = {
              communicatingStudent: data.communicatingStudent,
            };
          } else if (this.taskType === 'TASK_STUDENT_ACTIVE') {
            this.isResumeClasses = data.isResumeClasses;
            obj = {
              communicatingActive: data.communicatingActive,
              resumeClassesTime: data.resumeClassesTime,
            };
          } else if (this.taskType === 'TASK_MOTH_VISIT') {
            obj = {
              parentsRecovery: data.parentsRecovery,
              policySynchronization: data.policySynchronization,
              isResumeClasses: data.isResumeClasses,
              resumeClassesTime: data.resumeClassesTime,
            };
            if (data.isResumeClasses === '是') {
              this.showResumeClasses = true;
            } else {
              this.showResumeClasses = false;
            }
          } else if (this.taskType === 'TASK_CLASS_ADMIN_FIRST_PASS') {
            this.checkAcademicSituation = data.checkAcademicSituation;
            this.confirmCourseScheduling = data.confirmCourseScheduling;
            this.confirmClassTime = data.confirmClassTime;
            this.confirmIssues = data.confirmIssues;
            obj = {
              confirmIssuesReason: data.confirmIssuesReason,
            };
          } else if (this.taskType === 'PERIOD_CHANGE') {
            obj = {
              classTransferReason: data.classTransferReason,
              classTransferRecord: data.classTransferRecord,
              discountSituation: data.discountSituation,
              receiveStudents: data.receiveStudents,
              transferStudents: data.transferStudents,
              transferType: data.transferType,
            };
          } else if (this.taskType === 'TASK_STUDENT_ABSENT') {
            this.lessonArrangement = data.lessonArrangement;
            this.courseReplayAbsent = data.courseReplayAbsent;
            this.absenteeismReasons = data.absenteeismReasons;
          } else if (this.taskType === 'QA') {
            this.qaType = data.qaType;
            this.qaOrigin = data.qaOrigin;
            this.qaDetermine = data.qaDetermine;
            this.subject = data.subject;
            this.followTypeSub = data.followTypeSub;
            obj = {
              personLiable: data.personLiable,
              qaPerson: data.qaPerson,
              qualityDescription: data.qualityDescription,
              rewardMoney: data.rewardMoney,
              rewardScore: data.rewardScore,
              qaJudgeTime: data.qaJudgeTime,
            };
          } else if (this.taskType === 'TASK_CHANGE_TEACHER_VISIT') {
            this.icebreakerClass = data.icebreakerClass;
            this.classCommunicationVisit = data.classCommunicationVisit;
            this.underTeacherVisit = data.underTeacherVisit;

            this.feedbackVisit = data.feedbackVisit;
            (this.classCommunicationVisitReason = data.classCommunicationVisitReason),
              (obj = {
                parentFeedbackVisit: data.parentFeedbackVisit,
              });
          } else if (this.taskType === 'TASK_LONG_TASK_OVER_VISIT') {
            this.underNewTeacher = data.underNewTeacher;
            this.feedbackOver = data.feedbackOver;

            obj = {
              parentFeedbackOver: data.parentFeedbackOver,
            };
          } else if (this.taskType === 'STAGE_LEARNING_REPORT') {
            this.followupResultsReport = data.followupResultsReport;
            this.studyReport = data.studyReport;
            obj = {
              processingRecordsReport: data.processingRecordsReport,
              communicatingReport: data.communicatingReport,
            };
          } else if (this.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN') {
            this.solveProblems = data.solveProblems ?? [];
            // this.studentPortrait = data.studentPortrait;
            // this.parentPortrait = data.parentPortrait;
            this.sharingEngagement = data.sharingEngagement;
            // this.academicSituation = data.academicSituation;
            obj = {
              solveProblemReason: data.solveProblemReason,
              sharingEngagementReason: data.sharingEngagementReason,
            };
          } else if (['TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_V2'].includes(this.taskType)) {
            const { remark, ...draftData } = data;
            obj = {
              ...draftData,
              nextFollowDate: data.nextFollowDate ? this.$moment(data.nextFollowDate) : undefined,
            };
            this.form.setFieldsValue(obj);
          } else if (this.taskType === 'TASK_TEACHER_ABSENT') {
            this.lessonArrangement = data.lessonArrangement;
            this.courseReplayAbsent = data.courseReplayAbsent;
            this.absenteeismReasons = data.absenteeismReasons;
          } else if (this.taskType === 'TASK_TRACK_CREATE') {
            // 线索创建
            this.isAudition = data.isAudition;
            this.isAuditionReason = data.isAuditionReason;
            this.followDayClue = data.followDayClue;
            obj = {};
          } else if (this.taskType === 'TASK_SUBSCRIBE_COURSE') {
            this.isAuditionCourse = data.isAuditionCourse;
            this.followDayCourse = data.followDayCourse;
            this.isAppointmentClass = data.isAppointmentClass;
            this.isAppointmentReason = data.isAppointmentReason;
            obj = {};
          } else if (this.taskType === 'TASK_CERTIFICATE') {
            this.followResultsCertificate = data.followResultsCertificate;
            obj = {
              communicateCertificate: data.communicateCertificate,
              recordsCertificate: data.recordsCertificate,
            };
          } else if (this.taskType === 'TASK_NOT_RENEW') {
            obj = {};

            if (this.taskObj.userRole === 'QA') {
              this.isExamination = data.isExamination;
            }
          } else if (this.taskType === 'TASK_PROMOTE') {
            this.isMakeUp = data.isMakeUp;
            this.oneMakeUpReason = data.oneMakeUpReason;
            this.twoMakeUpReason = data.twoMakeUpReason;
            this.threeMakeUpReason = data.threeMakeUpReason;

            obj = {};
          } else if (this.taskType === 'TASK_TERM_VISIT') {
            this.followupResultsTerm = data.followupResultsTerm;
            this.studyTerm = data.studyTerm;
            obj = {
              processingRecordsTerm: data.processingRecordsTerm,
              communicatingTerm: data.communicatingTerm,
            };
          } else if (['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(this.taskType)) {
            this.followupResultsWeek = data.followupResultsWeek;

            obj = {
              processingRecordsWeek: data.processingRecordsWeek,
              communicatingWeek: data.communicatingWeek,
            };
          } else if (this.taskType === 'TASK_MOTH_STUDY_VISIT') {
            this.followUpResults = data.followUpResults;
            obj = {
              processingRecords: data.processingRecords,
              communicatingMothStudy: data.communicatingMothStudy,
            };
          } else if (this.taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS') {
            this.parentsCare = data.parentsCare;
            this.oneLossReason = data.oneLossReason;
            this.twoLossReason = data.twoLossReason;
            this.threeLossReason = data.threeLossReason;
            this.resumeClassesLoss = data.resumeClassesLoss;

            obj = {};
          } else if (this.taskType === 'TASK_STUDENT_MULTI_CHILD') {
            this.existChild = data.existChild;
            this.followDay = data.followDay;
            this.agreeRegister = data.agreeRegister;
            this.registerSubject = data.registerSubject;
            this.chineseReason = data.chineseReason;
            this.mathReason = data.mathReason;
            this.englishReason = data.englishReason;
          } else if (this.taskType === 'DAYLIGHT_TIME_CHANGED') {
            this.seasonalChanges = data.seasonalChanges;
          } else if (this.taskType === 'COMMON') {
            this.subject = data.subject;
            this.followTypeSub = data.followType;
          }
          this.$nextTick(() => {
            if (data.methodType === '电话') {
              this.showflag = true;
            } else {
              this.showflag = false;
            }
          });
          let followObj = {};
          // 如果有下次跟进时间
          if (data.nextFollowRecord) {
            this.showFollow = true;
            followObj = {
              nextFollowDate: data.nextFollowDate,
              nextFollowDesc: data.nextFollowDesc,
            };
          }
          setTimeout(() => {
            // 从任务点击
            if (this.type === 'task' && this.taskObj.taskName) {
              this.form.setFieldsValue({
                description: data.description,
                ...obj,
                ...followObj,
              });
              this.fileList = data.imageUrl || [];
              this.methodType = data.methodType;
            } else {
              this.methodType = data.methodType;
              this.form.setFieldsValue({
                followType: data.taskType,
                subject: data.subject,
                phoneType: data.phoneType,
                description: data.description,

                ...obj,
                ...followObj,
              });
              this.fileList = data.imageUrl || [];
            }
          }, 0);
        } else if (this.type === 'task' && this.taskObj.taskName) {
          // 默认选中当前跟进类型
          this.$nextTick(() => {
            this.form.setFieldsValue({
              followType: this.taskObj.type,
              subject: this.taskObj.subject,
            });
            //  this.handleSelectChange(this.taskObj.type);
          });
        }
      });
    },
    // 保存草稿
    cacheFollowRecord() {
      // 获取表单的值
      const values = this.form.getFieldsValue();
      let typeObj = {};

      // 新生首通
      if (['SUBSCRIPTION_DEDUCTION_FAILED_TASK'].includes(this.taskType)) {
        typeObj = {
          needCancelCourse: this.needCancelCourse,
          paymentCommunicationResult: this.paymentCommunicationResult,
          paymentFailReason: this.paymentFailReason,
        };
      } else if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.taskType)) {
        typeObj = {
          nicknameAndBirth: this.nicknameAndBirth,
          courseInfo: this.courseInfo,
          classArrangement: this.classArrangement,
          appDownload: this.appDownload,
          appIntroduction: this.appIntroduction,
          selfBookingConfirm: this.selfBookingConfirm,
          communicateResult: values.communicateResult,
          faildReason: values.faildReason,
          nextStuFirstFollowDate: values.nextStuFirstFollowDate,
        };
      } else if (['NEW_STUDENT_FOLLOW_UP', 'TRANSFER_STUDENT_FOLLOW_UP'].includes(this.taskType)) {
        typeObj = {
          introduceAndInvite: this.introduceAndInvite,
        };
      } else if (this.taskType === 'TASK_FIRST_VISIT') {
        typeObj = {
          icebreaker: this.icebreaker,
          classCommunication: this.classCommunication,
          understandingProgress: this.understandingProgress,
          understandingTeacher: this.understandingTeacher,
          reviewHomework: this.reviewHomework,
          parentExpectationManagement: this.parentExpectationManagement,
          communicationPreferences: this.communicationPreferences,
          sharingRules: this.sharingRules,
          classCommunicationReason: this.classCommunicationReason,
          feedback: this.feedback,
          adjustLevel: values.adjustLevel,
          parentFeedback: values.parentFeedback,
          instructor: this.taskObj.teacherName,
        };
      } else if (this.taskType === 'TEACHER_LATE') {
        // 老师迟到
        typeObj = {
          reverseClassHours: this.reverseClassHours,
          makeUpClasses: this.makeUpClasses,
          viewPlayback: this.viewPlayback,
        };
      } else if (this.taskType === 'TASK_W3_VISIT') {
        typeObj = {
          studentPerformance: this.studentPerformance,
          upgradePlan: this.upgradePlan,
          communicatingStudent: values.communicatingStudent,
        };
      } else if (this.taskType === 'TASK_STUDENT_ACTIVE') {
        typeObj = {
          communicatingActive: values.communicatingActive,
          isResumeClasses: this.isResumeClasses,
          resumeClassesTime: values.resumeClassesTime,
        };
      } else if (this.taskType === 'TASK_MOTH_VISIT' || this.taskType === 'TASK_STUDENT_ACTIVE') {
        typeObj = {
          parentsRecovery: values.parentsRecovery,
          policySynchronization: values.policySynchronization,
          isResumeClasses: values.isResumeClasses,
          resumeClassesTime: values.resumeClassesTime,
          // attendance: values.attendance,
          // attendanceReason: values.attendanceReason,
          // learningPlanning: values.learningPlanning,
          // parentalFeedback: values.parentalFeedback,
          // shareRecommendation: values.shareRecommendation,
          // stageLearningSummary: values.stageLearningSummary,
          // homeworkAfterClass:values.homeworkAfterClass
        };
      } else if (this.taskType === 'TASK_CLASS_ADMIN_FIRST_PASS') {
        typeObj = {
          checkAcademicSituation: this.checkAcademicSituation,
          confirmCourseScheduling: this.confirmCourseScheduling,
          confirmClassTime: this.confirmClassTime,
          confirmIssues: this.confirmIssues,
          confirmIssuesReason: values.confirmIssuesReason,
        };
      } else if (this.taskType === 'PERIOD_CHANGE') {
        typeObj = {
          classTransferReason: values.classTransferReason,
          classTransferRecord: values.classTransferRecord,
          discountSituation: values.discountSituation,
          receiveStudents: values.receiveStudents,
          transferStudents: values.transferStudents,
          transferType: values.transferType,
        };
      } else if (this.taskType === 'TASK_STUDENT_ABSENT') {
        typeObj = {
          absenteeismReasons: this.absenteeismReasons,
          lessonArrangement: this.lessonArrangement,
          courseReplayAbsent: this.courseReplayAbsent,
        };
      } else if (this.followTypeSub === 'QA') {
        typeObj = {
          followTypeSub: this.followTypeSub,

          qaType: this.qaType,
          qaOrigin: this.qaOrigin,
          qaDetermine: this.qaDetermine,
          personLiable: values.personLiable,
          qaPerson: values.qaPerson,
          qualityDescription: values.qualityDescription,
          rewardMoney: values.rewardMoney,
          rewardScore: values.rewardScore,
          qaJudgeTime: this.$moment(values.qaJudgeTime).format('YYYY-MM-DD'),
        };
      } else if (this.taskType === 'TASK_CHANGE_TEACHER_VISIT') {
        typeObj = {
          icebreakerClass: this.icebreakerClass,
          classCommunicationVisit: this.classCommunicationVisit,
          underTeacherVisit: this.underTeacherVisit,
          parentFeedbackVisit: values.parentFeedbackVisit,
          feedbackVisit: this.feedbackVisit,
          classCommunicationVisitReason: this.classCommunicationVisitReason,
        };
      } else if (this.taskType === 'TASK_LONG_TASK_OVER_VISIT') {
        typeObj = {
          feedbackOver: this.feedbackOver,
          underNewTeacher: this.underNewTeacher,
          parentFeedbackOver: values.parentFeedbackOver,
          instructor: this.taskObj.teacherName,
        };
      } else if (this.taskType === 'STAGE_LEARNING_REPORT') {
        typeObj = {
          followupResultsReport: this.followupResultsReport,
          processingRecordsReport: values.processingRecordsReport,
          communicatingReport: values.communicatingReport,
          studyReport: this.studyReport,
        };
      } else if (this.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN') {
        typeObj = {
          solveProblems: this.solveProblems,
          // studentPortrait: this.studentPortrait,
          // parentPortrait: this.parentPortrait,
          sharingEngagement: this.sharingEngagement,
          // academicSituation: this.academicSituation,
          solveProblemReason: values.solveProblemReason,
          sharingEngagementReason: values.sharingEngagementReason,
        };
      } else if (['TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_V2'].includes(this.taskType)) {
        typeObj = {
          ...values,
          nextFollowDate: values.nextFollowDate ? values.nextFollowDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
        };
      } else if (this.taskType === 'TASK_TEACHER_ABSENT') {
        typeObj = {
          absenteeism: values.absenteeism,
          absenteeismReason: values.absenteeismReason,
          makeUpLessons: values.makeUpLessons,
        };
      } else if (this.taskType === 'TASK_TRACK_CREATE') {
        // 线索创建

        typeObj = {
          isAudition: this.isAudition,
          isAuditionReason: this.isAuditionReason,
          followDayClue: this.followDayClue,
        };
      } else if (this.taskType === 'TASK_SUBSCRIBE_COURSE') {
        typeObj = {
          isAuditionCourse: this.isAuditionCourse,
          followDayCourse: this.followDayCourse,
          isAppointmentClass: this.isAppointmentClass,
          isAppointmentReason: this.isAppointmentReason,
        };
      } else if (this.taskType === 'TASK_CERTIFICATE') {
        typeObj = {
          followResultsCertificate: this.followResultsCertificate,
          communicateCertificate: values.communicateCertificate,
          recordsCertificate: values.recordsCertificate,
        };
      } else if (this.taskType === 'TASK_NOT_RENEW') {
        typeObj = {};
        if (this.taskObj.userRole === 'QA') {
          typeObj.isExamination = this.isExamination;
        }
      } else if (this.taskType === 'TASK_PROMOTE') {
        typeObj = {
          isMakeUp: this.isMakeUp,
          oneMakeUpReason: this.oneMakeUpReason,
          twoMakeUpReason: this.twoMakeUpReason,
          threeMakeUpReason: this.threeMakeUpReason,
        };
      } else if (this.taskType === 'TASK_TERM_VISIT') {
        typeObj = {
          followupResultsTerm: this.followupResultsTerm,
          processingRecordsTerm: values.processingRecordsTerm,
          communicatingTerm: values.communicatingTerm,
          studyTerm: this.studyTerm,
        };
      } else if (['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(this.taskType)) {
        typeObj = {
          followupResultsWeek: this.followupResultsWeek,
          processingRecordsWeek: values.processingRecordsWeek,
          communicatingWeek: values.communicatingWeek,
        };
      } else if (this.taskType === 'TASK_MOTH_STUDY_VISIT') {
        typeObj = {
          processingRecords: values.processingRecords,
          communicatingMothStudy: values.communicatingMothStudy,
          followUpResults: this.followUpResults,
        };
      } else if (this.taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS') {
        typeObj = {
          parentsCare: this.parentsCare,
          oneLossReason: this.oneLossReason,
          twoLossReason: this.twoLossReason,
          threeLossReason: this.threeLossReason,
          resumeClassesLoss: this.resumeClassesLoss,
        };
      } else if (this.taskType === 'TASK_STUDENT_MULTI_CHILD') {
        typeObj = {
          existChild: this.existChild,
          followDay: this.followDay,
          agreeRegister: this.agreeRegister,
          registerSubject: this.registerSubject,
          chineseReason: this.chineseReason,
          mathReason: this.mathReason,
          englishReason: this.englishReason,
        };
      } else if (this.taskType === 'DAYLIGHT_TIME_CHANGED') {
        typeObj = {
          seasonalChanges: this.seasonalChanges,
        };
      } else if (this.taskType === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP') {
        typeObj = {
          bookClassResult: values.bookClassResult,
          description: values.description,
          nonAttendanceEndReason: values.nonAttendanceEndReason,
          nonAttendanceReason: values.nonAttendanceReason,
        };
      }
      // 判断科目
      let subjectEnd = '';
      if (this.type === 'task') {
        subjectEnd = this.taskObj.subject;
      } else if (this.roleFLag) {
        // 代表非班主任角色
        subjectEnd = this.subject;
      } else {
        subjectEnd = this.userInfo.subject;
      }
      const params = {
        studentId: this.taskObj.studentId,
        type: this.taskType || 'COMMON',
        cacheData: {
          followType: this.type === 'task' ? this.taskObj.type : this.followTypeSub ? this.followTypeSub : 'COMMON', // 班主任角色默认日常跟进类型
          subject: subjectEnd,
          methodType: this.methodType,
          description: values.description,
          taskType: this.type === 'task' ? this.taskObj.type : this.followTypeSub ? this.followTypeSub : 'COMMON',
          imageUrl: this.fileList,
          ...typeObj,
        },
      };
      // 如果有下次跟进时间
      if (this.showFollow) {
        params.cacheData.nextFollowRecord = true;
        params.cacheData.nextFollowDate = this.$moment(values.nextFollowDate).format('YYYY-MM-DD HH:mm:ss');
        params.cacheData.nextFollowDesc = values.nextFollowDesc;
      } else {
        params.cacheData.nextFollowRecord = false;
      }
      cacheFollowRecord(params).then(() => {
        this.$message.success('暂存草稿成功');
        this.form.resetFields();
        this.showflag = false;
        this.$emit('closeModal');
      });
    },
    _queryFormType(val) {
      const params = {
        type: this.taskObj.followType,
        subject: val || this.taskObj.subject,
      };
      return queryFormType(params).then((res) => {
        this.followTypeArr = res.data.templateTypes;
      });
    },
    queryLossRefundCount() {
      const params = {
        studentId: this.taskObj.studentId,
        subject: this.taskObj.subject,
        lossRefundType: 'NOT_RENEW',
      };
      return queryLossRefundCount(params).then((res) => {
        // 没有记录
        if (res.data.content === 0) {
          this.showLossRefundFlag = true;
          this.submitLoading = false;
        }
      });
    },
    // 是否确认首课
    queryConfirmFistDate() {
      const params = {
        studentId: this.taskObj.studentId,
        subject: this.taskObj.subject,
      };
      return queryConfirmFistDate(params).then((res) => {
        this.showConfirmFistDate = res.data.content;
        // 没有确认首课
        if (!res.data.content) {
          this.$message.warning('请完成排课，并确认科目首课课节！');
          this.submitLoading = false;
        }
      });
    },

    changeResult() {
      this.changeFlag = true;
    },
    otherValidateFields(item) {
      // noRequire代表非必填
      // 绑定字符串额外处理
      if (typeof this[item] === 'string') {
        // 绑定的字符串，需要额外处理
        const flag = this.changeErr(!this[item], item);
        return !!flag;
      }
      // 绑定的数组
      const flag = this.changeErr(!this[item].length, item);

      return !!flag;
    },
    async handleSubmit(e) {
      e.preventDefault();
      let typeObj = {};
      this.form.validateFields(async (err, values) => {
        // 新生首通
        let arr = []; // 需要判断的

        if (['SUBSCRIPTION_DEDUCTION_FAILED_TASK'].includes(this.taskType)) {
          arr = ['methodType', 'needCancelCourse', 'paymentCommunicationResult'];
          if (this.paymentCommunicationResult === '沟通失败') {
            arr.push('paymentFailReason');
          }
        }

        if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.taskType)) {
          if (this.taskObj?.selfBookingInfo?.firstCourseSchedule) {
            arr = ['methodType', 'selfBookingConfirm'];
          } else {
            if (values.communicateResult === 'SUCCESS') {
              arr = [
                'methodType',
                'nicknameAndBirth',
                'courseInfo',
                'classArrangement',
                'appDownload',
                'appIntroduction',
              ];
            } else {
              arr = ['methodType'];
            }
          }
        }

        // 首课回访
        if (this.taskType === 'TASK_FIRST_VISIT') {
          arr = [
            'methodType',
            'understandingProgress',
            'understandingTeacher',
            'reviewHomework',
            'parentExpectationManagement',
            'communicationPreferences',
            'sharingRules',
            'feedback',
          ];
          if (this.taskObj.subject === 'CHINESE') {
            arr.push('icebreaker');
            arr.push('classCommunication');
          }
          if (this.showCommunication && this.taskObj.subject === 'CHINESE') {
            arr.push('classCommunicationReason');
          }
        }

        // w3回访
        if (this.taskType === 'TASK_W3_VISIT') {
          arr = ['studentPerformance', 'upgradePlan', 'methodType'];
        }

        if (['NEW_STUDENT_FOLLOW_UP', 'TRANSFER_STUDENT_FOLLOW_UP'].includes(this.taskType)) {
          arr = ['introduceAndInvite', 'methodType'];
        }

        // 新生补升
        if (this.taskType === 'TASK_PROMOTE') {
          arr = ['isMakeUp', 'methodType'];
          if (this.isMakeUp === '放弃补升权益') {
            arr.push('oneMakeUpReason');
          }
          if (this.isMakeUp === '放弃补升权益' && this.oneMakeUpReason) {
            arr.push('twoMakeUpReason');
          }
          if (this.isMakeUp === '放弃补升权益' && this.twoMakeUpReason) {
            arr.push('threeMakeUpReason');
          }
        }
        // 周回访
        if (['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(this.taskType)) {
          arr = ['methodType', 'followupResultsWeek'];
        }
        // 阶段回访
        if (this.taskType === 'TASK_TERM_VISIT') {
          arr = ['methodType', 'followupResultsTerm', 'studyTerm'];
        }
        // 学习回访
        if (this.taskType === 'TASK_MOTH_STUDY_VISIT') {
          arr = ['methodType', 'followUpResults'];
        }
        // 老师迟到
        if (this.taskType === 'TEACHER_LATE') {
          arr = ['methodType', 'reverseClassHours'];
          if (this.taskObj.subject === 'CHINESE') {
            arr.push('makeUpClasses');
          } else if (this.taskObj.subject === 'MATH' || this.taskObj.subject === 'ENGLISH') {
            arr.push('viewPlayback');
          }
        }
        // 日常跟进，班主任角色
        if (this.type === 'COMMON' && !this.roleFLag) {
          arr = ['methodType'];
        }
        // 日常跟进，非班主任角色
        if (this.type === 'COMMON' && this.roleFLag) {
          arr = ['methodType', 'subject', 'followTypeSub'];
          if (this.followTypeSub === 'QA') {
            const arr1 = ['qaType', 'qaOrigin', 'qaDetermine'];
            arr = arr.concat(arr1);
          }
        }
        // 学习报告
        if (this.taskType === 'STAGE_LEARNING_REPORT') {
          arr = ['methodType', 'followupResultsReport', 'studyReport'];
        }
        // 学员缺勤
        if (this.taskType === 'TASK_STUDENT_ABSENT') {
          arr = ['methodType', 'absenteeismReasons'];
          if (this.taskObj.subject === 'CHINESE') {
            arr.push('lessonArrangement');
          } else if (this.taskObj.subject === 'ENGLISH' || this.taskObj.subject === 'MATH') {
            arr.push('courseReplayAbsent');
          }
        }
        // 时令变化
        if (this.taskType === 'DAYLIGHT_TIME_CHANGED') {
          arr = ['methodType', 'seasonalChanges'];
        }
        // 原班主任交接
        if (this.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN') {
          arr = [
            'methodType',
            // 'studentPortrait',
            // 'parentPortrait',
            // 'academicSituation',
            'sharingEngagement',
            'solveProblems',
          ];
          arr.forEach((item) => {
            this.otherValidateFields(item);
          });
          await this.validateHandoverStudentOldAdmin();
        }
        // 新班主任交接
        if (['TASK_CLASS_ADMIN_FIRST_PASS_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2'].includes(this.taskType)) {
          arr = ['methodType'];
        }
        // 班主任交接学员首通
        if (this.taskType === 'TASK_CLASS_ADMIN_FIRST_PASS') {
          arr = [
            'methodType',
            'checkAcademicSituation',
            'confirmCourseScheduling',
            'confirmClassTime',
            'confirmIssues',
          ];
        }
        // 老师交接后首课回访
        if (this.taskType === 'TASK_CHANGE_TEACHER_VISIT') {
          arr = ['methodType', 'underTeacherVisit', 'feedbackVisit'];
          if (this.taskObj.subject === 'CHINESE') {
            arr.push('icebreakerClass');
            arr.push('classCommunicationVisit');
          }
          if (this.classCommunicationVisit === '未完成' && this.taskObj.subject === 'CHINESE') {
            arr.push('classCommunicationVisitReason');
          }
        }
        // 多娃任务
        if (this.taskType === 'TASK_STUDENT_MULTI_CHILD') {
          arr = ['methodType', 'existChild'];
          if (this.existChild === '未回复') {
            arr.push('followDay');
          }
          if (this.existChild === '是') {
            arr.push('agreeRegister');
          }
          if (this.agreeRegister === '同意' && this.existChild === '是') {
            arr.push('registerSubject');
          }
          if (this.agreeRegister === '不同意' && this.existChild === '是') {
            arr.push('chineseReason', 'mathReason', 'englishReason');
          }
        }
        // 结业证书
        if (this.taskType === 'TASK_CERTIFICATE') {
          arr = ['methodType', 'followResultsCertificate'];
        }
        // 学员激活
        if (this.taskType === 'TASK_STUDENT_ACTIVE') {
          arr = ['methodType', 'isResumeClasses'];
        }
        // 不续费质检
        if (this.taskType === 'TASK_NOT_RENEW' && this.taskObj.userRole === 'QA') {
          arr = ['methodType', 'isExamination'];
        }
        // 流失学员首通
        if (this.taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS') {
          arr = ['methodType', 'oneLossReason', 'parentsCare', 'resumeClassesLoss'];
          if (this.oneLossReason) {
            arr.push('twoLossReason');
          }
          if (this.twoLossReason) {
            arr.push('threeLossReason');
          }
        }
        // 学员跟进
        if (this.taskType === 'TASK_NEXT_FOLLOW_RECORD') {
          arr = ['methodType'];
        }
        // 线索创建
        if (this.taskType === 'TASK_TRACK_CREATE') {
          arr = ['methodType', 'isAudition'];
          if (this.isAudition === '未回复') {
            arr.push('followDayClue');
          }
          if (this.isAudition === '不同意') {
            arr.push('isAuditionReason');
          }
        }
        // 约课任务
        if (this.taskType === 'TASK_SUBSCRIBE_COURSE') {
          arr = ['methodType', 'isAuditionCourse'];
          if (this.isAuditionCourse === '未回复') {
            arr.push('followDayCourse');
          }
          if (this.isAuditionCourse === '同意') {
            arr.push('isAppointmentClass');
          }
          if (this.isAuditionCourse === '同意' && this.isAppointmentClass === '未约') {
            arr.push('isAppointmentReason');
          }
        }
        // 长期交接后首课回访
        if (this.taskType === 'TASK_LONG_TASK_OVER_VISIT') {
          arr = ['methodType'];
          if (this.taskObj.subject === 'MATH') {
            arr.push('feedbackOver');
            arr.push('underNewTeacher');
          }
        }
        // 需要校验跟进方式的任务类型
        const checkMethodTypeTasks = ['ATTEMPT_RETENTION_ON_CANCELLATION', 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP'];
        if (checkMethodTypeTasks.includes(this.taskType)) {
          arr.push('methodType');
        }

        const hasErr = arr.reduce((prev, item) => {
          const v = this.otherValidateFields(item);
          return prev ? true : v;
        }, false);

        if (hasErr) return;

        if (!err) {
          // 新生首通
          if (['SUBSCRIPTION_DEDUCTION_FAILED_TASK'].includes(this.taskType)) {
            typeObj = {
              needCancelCourse: this.needCancelCourse,
              paymentCommunicationResult: this.paymentCommunicationResult,
              paymentFailReason: this.paymentFailReason,
              nextCourse: this.taskObj?.nextCourse?.courseScheduleRespDTO?.startChinaDateTime,
            };
          } else if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.taskType)) {
            typeObj = {
              nicknameAndBirth: this.nicknameAndBirth?.join(','),
              courseInfo: this.courseInfo?.join(','),
              classArrangement: this.classArrangement?.join(','),
              appDownload: this.appDownload?.join(','),
              appIntroduction: this.appIntroduction?.join(','),
              selfBookingConfirm: this.selfBookingConfirm,
              communicateResult: values.communicateResult,
              faildReason: values.faildReason,
            };
          } else if (['NEW_STUDENT_FOLLOW_UP', 'TRANSFER_STUDENT_FOLLOW_UP'].includes(this.taskType)) {
            typeObj = {
              introduceAndInvite: this.introduceAndInvite,
            };
          } else if (this.taskType === 'TEACHER_LATE') {
            // 老师迟到
            typeObj = {
              reverseClassHours: this.reverseClassHours?.join(','),
              makeUpClasses: this.makeUpClasses,
              viewPlayback: this.viewPlayback?.join(','),
            };
          } else if (this.taskType === 'TASK_FIRST_VISIT') {
            typeObj = {
              icebreaker: this.icebreaker,
              classCommunication: this.classCommunication,
              understandingProgress: this.understandingProgress,
              understandingTeacher: this.understandingTeacher,
              reviewHomework: this.reviewHomework.join(','),
              parentExpectationManagement: this.parentExpectationManagement.join(','),
              communicationPreferences: this.communicationPreferences.join(','),
              sharingRules: this.sharingRules.join(','),
              classCommunicationReason: this.classCommunicationReason,
              feedback: this.feedback.join(','),
              adjustLevel: values.adjustLevel,
              parentFeedback: values.parentFeedback,
              firstClassLevel: this.taskObj.firstClassLevel,
              instructor: this.taskObj.teacherName,
            };
          } else if (this.taskType === 'TASK_W3_VISIT') {
            typeObj = {
              studentPerformance: this.studentPerformance?.join(','),
              upgradePlan: this.upgradePlan?.join(','),
              communicatingStudent: values.communicatingStudent,
            };
          } else if (this.taskType === 'TASK_STUDENT_ACTIVE') {
            typeObj = {
              communicatingActive: values.communicatingActive,
              isResumeClasses: this.isResumeClasses,
              resumeClassesTime: this.$moment(values.resumeClassesTime).format('YYYY-MM-DD'),
            };
          } else if (this.taskType === 'TASK_MOTH_VISIT') {
            typeObj = {
              parentsRecovery: values.parentsRecovery,
              policySynchronization: values.policySynchronization,
              isResumeClasses: values.isResumeClasses,
              resumeClassesTime: this.$moment(values.resumeClassesTime).format('YYYY-MM-DD'),
            };
          } else if (this.taskType === 'TASK_CLASS_ADMIN_FIRST_PASS') {
            typeObj = {
              checkAcademicSituation: this.checkAcademicSituation,
              confirmCourseScheduling: this.confirmCourseScheduling?.join(','),
              confirmClassTime: this.confirmClassTime?.join(','),
              confirmIssues: this.confirmIssues,
              confirmIssuesReason: values.confirmIssuesReason,
            };
          } else if (this.taskType === 'PERIOD_CHANGE') {
            typeObj = {
              classTransferReason: values.classTransferReason,
              classTransferRecord: values.classTransferRecord,
              discountSituation: values.discountSituation,
              receiveStudents: values.receiveStudents,
              transferStudents: values.transferStudents,
              transferType: values.transferType,
            };
          } else if (this.taskType === 'TASK_STUDENT_ABSENT') {
            typeObj = {
              absenteeismReasons: this.absenteeismReasons,
              lessonArrangement: this.lessonArrangement,
            };
          } else if (this.followTypeSub === 'QA') {
            typeObj = {
              qaType: this.qaType,
              qaOrigin: this.qaOrigin,
              qaDetermine: this.qaDetermine,
              personLiable: values.personLiable,
              qaPerson: values.qaPerson,
              qualityDescription: values.qualityDescription,
              rewardMoney: values.rewardMoney,
              rewardScore: values.rewardScore,
              qaJudgeTime: this.$moment(values.qaJudgeTime).format('YYYY-MM-DD'),
            };
          } else if (this.taskType === 'TASK_CHANGE_TEACHER_VISIT') {
            typeObj = {
              instructor: this.taskObj.teacherName,
              icebreakerClass: this.icebreakerClass,
              classCommunicationVisit: this.icebreakerClass,
              underTeacherVisit: this.underTeacherVisit,
              parentFeedbackVisit: values.parentFeedbackVisit,
              feedbackVisit: this.feedbackVisit?.join(','),
              classCommunicationVisitReason: this.classCommunicationVisitReason,
            };
          } else if (this.taskType === 'TASK_LONG_TASK_OVER_VISIT') {
            typeObj = {
              feedbackOver: this.feedbackOver?.join(','),
              underNewTeacher: this.underNewTeacher,
              parentFeedbackOver: values.parentFeedbackOver,
              instructor: this.taskObj.teacherName,
            };
          } else if (this.taskType === 'STAGE_LEARNING_REPORT') {
            typeObj = {
              followupResultsReport: this.followupResultsReport,
              processingRecordsReport: values.processingRecordsReport,
              communicatingReport: values.communicatingReport,
              studyReport: this.studyReport?.join(','),
            };
          } else if (this.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN') {
            typeObj = {
              solveProblems: this.solveProblems,
              // studentPortrait: this.studentPortrait.join(','),
              // parentPortrait: this.parentPortrait.join(','),
              sharingEngagement: this.sharingEngagement,
              // academicSituation: this.academicSituation?.join(','),
              solveProblemReason: values.solveProblemReason,
              sharingEngagementReason: values.sharingEngagementReason,
            };
          } else if (['TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_V2'].includes(this.taskType)) {
            // const {} = values
            typeObj = {
              ...values,
            };

            if (values.communicationResult === '沟通失败') {
              typeObj.nextClassAdminFirstPassRecord = true;
              typeObj.nextFollowDate = this.$moment(values.nextFollowDate).format('YYYY-MM-DD HH:mm:ss');
            }
          } else if (this.taskType === 'TASK_TEACHER_ABSENT') {
            typeObj = {
              absenteeism: values.absenteeism,
              absenteeismReason: values.absenteeismReason,
              makeUpLessons: values.makeUpLessons,
            };
          } else if (this.taskType === 'TASK_TRACK_CREATE') {
            typeObj = {
              isAudition: this.isAudition,
              isAuditionReason: this.isAuditionReason,
              followDayClue: this.followDayClue?.join(','),
            };
          } else if (this.taskType === 'TASK_SUBSCRIBE_COURSE') {
            typeObj = {
              isAuditionCourse: this.isAuditionCourse,
              followDayCourse: this.followDayCourse?.join(','),
              isAppointmentClass: this.isAppointmentClass,
              isAppointmentReason: this.isAppointmentReason,
            };
          } else if (this.taskType === 'TASK_CERTIFICATE') {
            typeObj = {
              followResultsCertificate: this.followResultsCertificate,
              communicateCertificate: values.communicateCertificate,
              recordsCertificate: values.recordsCertificate,
            };
          } else if (this.taskType === 'TASK_NOT_RENEW') {
            typeObj = {};
            if (this.taskObj.userRole === 'QA') {
              typeObj.isExamination = this.isExamination;
            }
          } else if (this.taskType === 'TASK_PROMOTE') {
            typeObj = {
              isMakeUp: this.isMakeUp,
              oneMakeUpReason: this.oneMakeUpReason,
              twoMakeUpReason: this.twoMakeUpReason,
              threeMakeUpReason: this.threeMakeUpReason,
            };
          } else if (this.taskType === 'TASK_TERM_VISIT') {
            typeObj = {
              followupResultsTerm: this.followupResultsTerm,
              processingRecordsTerm: values.processingRecordsTerm,
              communicatingTerm: values.communicatingTerm,
              studyTerm: this.studyTerm?.join(','),
            };
          } else if (['TASK_WEEK_VISIT', 'LOW_SCORE_TEST'].includes(this.taskType)) {
            typeObj = {
              followupResultsWeek: this.followupResultsWeek,
              processingRecordsWeek: values.processingRecordsWeek,
              communicatingWeek: values.communicatingWeek,
            };
          } else if (this.taskType === 'TASK_MOTH_STUDY_VISIT') {
            typeObj = {
              processingRecords: values.processingRecords,
              communicatingMothStudy: values.communicatingMothStudy,
              followUpResults: this.followUpResults,
            };
          } else if (this.taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS') {
            typeObj = {
              parentsCare: this.parentsCare,
              oneLossReason: this.oneLossReason,
              twoLossReason: this.twoLossReason,
              threeLossReason: this.threeLossReason,
              resumeClassesLoss: this.resumeClassesLoss,
            };
          } else if (this.taskType === 'TASK_STUDENT_MULTI_CHILD') {
            typeObj = {
              existChild: this.existChild,
              followDay: this.followDay?.join(','),
              agreeRegister: this.agreeRegister,
              registerSubject: this.registerSubject.join(','),
              chineseReason: this.chineseReason,
              mathReason: this.mathReason,
              englishReason: this.englishReason,
            };
          } else if (this.taskType === 'DAYLIGHT_TIME_CHANGED') {
            typeObj = {
              seasonalChanges: this.seasonalChanges,
              studentScheduleId: this.taskObj.studentScheduleId,
            };
          } else if (this.taskType === 'ATTEMPT_RETENTION_ON_CANCELLATION') {
            typeObj = {
              subscriptionId: this.taskObj.subscriptionId,
              communicateResult: values.communicateResult,
              description: values.description,
            };
          } else if (this.taskType === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP') {
            console.log('this.taskObj', this.taskObj);
            typeObj = {
              bookClassResult: values.bookClassResult,
              description: values.description,
              nonAttendanceEndReason: values.nonAttendanceEndReason,
              nonAttendanceReason: values.nonAttendanceReason,
              courseId: this.taskObj.courseId,
            };
          }
          // 判断科目
          this.subjectEnd = '';
          if (this.type === 'task') {
            this.subjectEnd = this.taskObj.subject;
          } else if (this.roleFLag) {
            // 代表非班主任角色
            this.subjectEnd = this.subject;
          } else {
            this.subjectEnd = this.userInfo.subject;
          }
          if (!this.subjectEnd) {
            this.$message.warning('请设置班主任科目');
            return false;
          }
          this.submitLoading = true;
          const params = {
            studentId: this.taskObj.studentId,
            type: this.taskObj.type,
            studentName: this.taskObj.studentName,
            preClassAdmin: this.taskObj?.processVariables?.preClassAdmin,
            preClassAdminId: this.taskObj?.processVariables?.preClassAdmin?.preClassAdminId,
            preClassAdminName: this.taskObj?.processVariables?.preClassAdmin?.preClassAdminName,
            classAdminId: this.userInfo.userID,
            classAdminName: this.userInfo.fullName,
            headImageUrl: this.currentUserProfile.avatar ? this.currentUserProfile.avatar : this.currentUserAvatar,
            // taskId: this.checked ? this.taskObj.taskId : '',
            taskId: this.taskObj.taskId,
            taskName: this.taskObj.taskName,
            firstCourseDate: this.taskObj.firstCourseDate,
            processInstanceId: this.taskObj.processInstanceId,
            subject: this.subjectEnd,
            recordData: {
              taskType: this.type === 'task' ? this.taskObj.type : this.followTypeSub ? this.followTypeSub : 'COMMON',
              followType: this.type === 'task' ? this.taskObj.type : this.followTypeSub ? this.followTypeSub : 'COMMON', // 班主任角色默认日常跟进类型
              subject: this.subjectEnd,
              method: typeof this.methodType === 'string' ? this.methodType : this.methodType?.join(','),
              describe: values.description,
              version: 'version2', // 代表新提交的跟进记录
              ...typeObj,
            },
          };
          console.log('params-before', params);
          params.recordData.nextFollowRecord = this.showFollow;

          // 如果有下次跟进时间
          if (this.showFollow && !this.nextStuFirstFollowRecord) {
            params.recordData.nextFollowDate = this.$moment(values.nextFollowDate).format('YYYY-MM-DD HH:mm:ss');
            params.recordData.nextFollowDesc = values.nextFollowDesc;
          }

          params.recordData.nextStuFirstFollowRecord = this.nextStuFirstFollowRecord;
          if (this.nextStuFirstFollowRecord) {
            params.recordData.nextFollowDate = this.$moment(values.nextStuFirstFollowDate).format(
              'YYYY-MM-DD HH:mm:ss',
            );
          }

          if (this.type !== 'task') {
            params.uuid = this.taskObj.uuid;
          }
          if (this.fileList?.length > 0) {
            params.recordData.imageList = this.fileList.map((item) => {
              return item.url;
            });
          }

          // 不续费且勾选了完全，需要判断有没有流失记录
          if (this.taskType === 'TASK_NOT_RENEW' && this.checked && this.taskObj.userRole !== 'QA') {
            await this.queryLossRefundCount();

            // 不续费没有流失记录，不需要提交跟进记录
            if (!this.showLossRefundFlag) {
              this.submitRecord(params);
            }
          }
          // else if (
          //   ['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.taskType) &&
          //   this.taskObj?.selfBookingInfo?.firstCourseSchedule
          // ) {
          //   // 手动确认首课
          //   const firstCourseParams = {
          //     classAdminId: this.userInfo.userID,
          //     studentId: this.taskObj.studentId,
          //     type: this.taskObj.type,
          //     subject: this.taskObj.subject,
          //     studentCode: this.taskObj.studentCode,
          //     firstCourseDate: this.taskObj.selfBookingInfo.firstCourseSchedule.startDateTime,
          //     processInstanceId: this.taskObj.processInstanceId,
          //     firstCourseScheduleId: this.taskObj.selfBookingInfo.firstCourseSchedule.uuid,
          //     firstFromClassId: this.taskObj.selfBookingInfo.firstCourseSchedule.formClassId,
          //     remark: '',
          //     firstCourseScheduleName: this.taskObj.selfBookingInfo.firstCourseSchedule.scheduleName,
          //     firstFromClassName: this.taskObj.selfBookingInfo.firstCourseSchedule.formClassName,
          //     firstCourseEndDate: this.taskObj.selfBookingInfo.firstCourseSchedule.endDateTime,
          //   };

          //   await confirmFirstLesson(firstCourseParams);
          //   this.submitRecord(params);
          // }
          else if (this.taskType === 'TASK_STUDENT_ACTIVE') {
            // 学员激活，近七天没有科目已上正式课课节
            this.queryStudentReminderActive(params);
          } else {
            this.submitRecord(params);
          }
        }
      });
    },
    submitRecord(params) {
      if (this.taskType === 'ATTEMPT_RETENTION_ON_CANCELLATION' && params.recordData.communicateResult === 'FAILD') {
        Modal.confirm({
          title: '挽单失败系统自动取消订阅',
          content: '取消订阅后系统会取消学员扣费计划，并邮件通知家长',
          okText: '确认',
          cancelText: '返回',
          centered: true,
          class: 'logout',
          onOk: async () => {
            return submitRecord(params)
              .then(async (res) => {
                this.submitLoading = false;
                if (res.status === 200) {
                  if (this.taskType === 'TASK_STUDENT_ACTIVE') {
                    this.cancelActive();
                  }
                  this.$message.success('创建跟进记录成功');
                  this.$emit('refleshRecord', this.subjectEnd);
                  this.onClose();
                }
                // await unSubscribeOnWinback(params.recordData.subscriptionId);
                return true;
              })
              .catch(() => {
                this.submitLoading = false;
              });
          },
          onCancel: async () => {
            this.submitLoading = false;
            return true;
          },
        });
      } else {
        submitRecord(params)
          .then((res) => {
            this.submitLoading = false;
            if (res.status === 200) {
              if (this.taskType === 'TASK_STUDENT_ACTIVE') {
                this.cancelActive();
              }
              this.$message.success('创建跟进记录成功');
              this.$emit('refleshRecord', this.subjectEnd);
              this.onClose();
            }
          })
          .catch(() => {
            this.submitLoading = false;
          });
      }
    },
    onClose(value) {
      const that = this;
      if (value === 0 && this.changeFlag) {
        // 有更改跟进记录才出现弹窗
        const h = this.$createElement;
        this.$confirmAnt({
          title: '操作提示',
          content: h(
            'div',
            {
              style: {
                textAlign: 'left',
              },
            },
            [
              h(
                'div',
                {
                  style: {
                    marginBottom: '10px',
                  },
                },
                '当前记录还未提交，是否要存为草稿',
              ),
              h(
                'div',
                {
                  style: {
                    fontSize: '14px',
                    color: '#999',
                  },
                },
                '注意：存为草稿后下次打开可见此记录',
              ),
            ],
          ),
          okText: '暂存草稿',
          cancelText: '仍要关闭',
          class: 'cancel-clue',
          onOk() {
            that.cacheFollowRecord();
          },
          onCancel() {
            that.form.resetFields();
            that.showflag = false;
            that.$emit('closeModal');
          },
        });
      } else {
        that.form.resetFields();
        that.showflag = false;
        that.$emit('closeModal');
      }
    },
  },

  created() {
    this.taskType = this.taskObj.type;

    if (
      this.taskObj.type === 'TASK_FIRST_VISIT' ||
      this.taskObj.type === 'TASK_MOTH_STUDY_VISIT' ||
      this.taskObj.type === 'TASK_STUDENT_ABSENT' ||
      this.taskObj.type === 'TASK_PROMOTE' ||
      this.taskObj.type === 'TASK_WEEK_VISIT' ||
      this.taskObj.type === 'LOW_SCORE_TEST' ||
      this.taskObj.type === 'TASK_TERM_VISIT' ||
      this.taskObj.type === 'TASK_CERTIFICATE' ||
      this.taskObj.type === 'STAGE_LEARNING_REPORT' ||
      this.taskObj.type === 'TASK_STUDENT_ACTIVE' ||
      this.taskObj.type === 'DAYLIGHT_TIME_CHANGED'
    ) {
      this.requireDesc = false;
    }

    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
    this.queryScreenOption();
  },
  mounted() {
    const obj = storage.get('userInfo');
    this.userInfo = obj;
    // 判断角色，非班主任角色
    this.roleFLag = this.userInfo.roleArr.find(
      (role) => role === 'TEACHING_STAFF' || role === 'TEACHING_STAFF_ADMIN' || role === 'HEAD_TEACHER_SUPER',
    );
    // 质检人员默认当前登录班主任
    if (this.type === 'COMMON' && this.roleFLag) {
      this.form.getFieldDecorator('qaPerson', { initialValue: this.userInfo.fullName });
    }
    (async () => {
      // 任务那里点进来，学情详情那里，选了科目才出现模版
      if (this.type === 'task') {
        this.followLabel = '任务类型';
        await this._queryFormType();
      } else {
        this.followLabel = '跟进类型';
      }

      if (this.taskObj.type === 'TASK_FIRST_VISIT') {
        this.form.setFieldsValue({
          instructor: this.taskObj.teacherName,
          firstClassLevel: this.taskObj.firstClassLevel,
        });
      }

      if (this.taskObj.type === 'DAYLIGHT_TIME_CHANGED') {
        if (this.taskObj.daylightReadInfo?.read) {
          this.methodType = ['学员中心'];
          this.seasonalChanges = '家长已确认';
        }
      }

      this.showFinishFlag = this.type === 'task';
      // 判断是否有保存的草稿
      this.getCacheFollowRecord();
    })();
  },
};
</script>
<style lang="less" scoped>
.list-itemWrap {
  display: flex;
}
.item-right {
  justify-content: space-between;
}
.list-content {
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
}
.list-item {
  display: flex;
  padding: 6px 8px;
  margin: 0 24px 4px 24px;

  gap: 8px;
  flex: 1;
  &:hover {
    background-color: #e6fff4;
  }
}
.requireStyle {
  .list-item {
    background-color: #fbebeb;
    margin: 0 24px 4px 0;
  }
  &::before {
    margin: 5px 8px 0 0;
    width: 16px;
    height: 16px;
    content: '';
    display: block;
    background: url(~@/assets/icons/warn2.png) no-repeat;
    background-size: 100% 100%;
  }
}

/deep/.followModal .ant-form-item-label {
  padding-left: 30px;
  width: auto;
}
/deep/.followModal .ant-form-item-control-wrapper {
  padding: 0 30px;
  width: auto;
}
/deep/.ant-form-item {
  margin-bottom: 16px;
}
/deep/ .followModal .ant-modal-body {
  max-height: 600px;
  overflow-y: auto;
  padding: 24px 0;
}

/deep/ .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.finish-task {
  position: absolute;
  left: 10px;
  top: 17px;
}
.operation-btn {
  color: #04cb94;
  cursor: pointer;
  padding-left: 20px;
}
</style>
